let env = "production"
let apiDomain = "https://jrdm-ocular-api-production.herokuapp.com"
const { href, hostname } = location

if (hostname == "localhost" || hostname.includes("192.168.")) {
  env = "development"
  apiDomain = `http://${hostname}:3000`
} else if (
  href.includes("netlify.app") &&
  (href.includes("develop") ||
    href.includes("preview") ||
    href.includes("staging"))
) {
  env = "staging"
  apiDomain = "https://jrdm-ocular-api-staging.herokuapp.com"
}

export { env, apiDomain }

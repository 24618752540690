import Vue from "vue"
import Vuex from "vuex"

// import profile from '~/store/profile'
// import notifications from '~/store/notifications'
import authenticate from "~/store/authenticate"
import Profile from "~/models/Profile"
import Notifications from "~/collections/Notifications"
import Deals from "~/collections/Deals"
import HouseSearches from "~/collections/HouseSearches"
import Translation from "~/models/Translation"

import tags from "~/store/tags"
import router from "@/router"
import Deal from "~/models/Deal"
import Partners from "~/collections/Partners"
import Salespeople from "~/collections/Salespeople"

Vue.use(Vuex)

const state = {
  ...authenticate,
  tags,
  sidebar: "closed" as "closed" | "mini" | "full",
  profile: new Profile(),
  notifications: new Notifications(),
  salespeople: new Salespeople(),
  partners: new Partners(),
  deals: new Deals(),
  dealCustomersNumber: 0,
  readOnly: null as null | boolean,
  houseSearches: new HouseSearches(),
  translation: new Translation(),
  currentMessage: "",
  currentDeal: new Deal(),

  currentDealId() {
    const { dealId } = router.currentRoute.params
    return dealId ? +dealId : null
  },

  isReadOnlyMode(): boolean {
    const currentDealId = state.currentDealId()
    return !state.deals.items.some((deal) => deal.id == currentDealId)
  },
}

const store = new Vuex.Store({ state })

export default store
export { state }
export const enums = (key: string) => state.translation.enums[key]
export const translate = (key: string, value: string) =>
  state.translation.translate(key, value)
export const getEnumValues = (key: string) =>
  state.translation.getEnumValues(key)




































































































































import { Component, Vue } from "vue-property-decorator"
import Deals from "~/collections/Deals"
import { state } from "@/store"
import groupBy from "lodash/groupBy"
import Notification from "~/models/Notification"
import ModalCustomer from "../components/modals/ModalCustomer.vue"
import Deal from "~/models/Deal"

interface DealHeader extends Deal {
  notifications_new_alert: Array<Notification>
  notifications_new_message: Array<Notification>
  hubspot_owner_name: string
  customer_success_name: string
  sent_selected_houses_count_per_week: number
  messages_sent_count_per_week: number
  alerts_count_per_week: number
  house_search_results_count_per_week: number
  first_visit_at_sorter: string
  mandate_signature_at_sorter: string
  deal: Deal
}

@Component({
  components: {
    ModalCustomer,
  },
  filters: {
    replaceSemicolonByColon: function (val: string) {
      return val ? val.replace(/;/gi, ", ") : val
    },
  },
})
export default class DealSelect extends Vue {
  salesperson = state.profile
  search = ""
  deals: Deals = new Deals()
  loaded = true
  cashIn = false
  footerProps = { "items-per-page-options": [10, 25, 50, 100] }

  async mounted() {
    this.deals = state.deals
  }

  async changeSalesperson() {
    this.loaded = false
    this.deals = new Deals()
    await this.deals.fetch({
      url: `/salespeople/${this.salesperson.id}/deals`,
    })
    this.loaded = true
  }
  customSearch(
    value: any,
    search: string,
    item: { [s: string]: unknown } | ArrayLike<unknown>
  ) {
    return Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(this.search.toLowerCase())
  }
  numberOfAlertOfDeal(notifications: Array<Notification>) {
    return notifications.reduce((acc: number, el) => {
      return acc + parseInt(el.activity_value, 10)
    }, 0)
  }
  sortDealsbyStage(a: string, b: string) {
    let custom_order = [
      "appointment_fixed",
      "appointment_performed",
      "warrant_signed",
      "visits_in_progress",
      "offer_made",
      "offer_accepted",
      "compromise_signed",
      "validated_invoice",
      "authentic_act_signed",
      "cash_in_done",
    ]

    return custom_order.indexOf(a) < custom_order.indexOf(b) ? 1 : -1
  }
  sortAlerts(a: Array<Notification>, b: Array<Notification>) {
    return this.numberOfAlertOfDeal(a) < this.numberOfAlertOfDeal(b) ? 1 : -1
  }
  sortMessages(a: Array<Notification>, b: Array<Notification>) {
    return a.length < b.length ? 1 : -1
  }
  salespersonName(id: number | null): string {
    return (
      this.salespeopleFiltered?.find((salesperson) => salesperson.id === id)
        ?.first_name || ""
    )
  }
  getWeeksSinceSignature(
    mandateSignatureAt: Date | null,
    offerAcceptedAt: Date | null
  ): number {
    if (!mandateSignatureAt) return 0
    let from = offerAcceptedAt ? new Date(offerAcceptedAt) : new Date()
    let to = new Date(mandateSignatureAt)
    return +from - +to > 0
      ? Math.round((+from - +to) / (60 * 60 * 24 * 7 * 1000))
      : 0
  }
  elSendPerWeek(
    mandateSignatureAt: Date | null,
    offerAcceptedAt: Date | null,
    elSendTotal: number | null
  ): number {
    if (!mandateSignatureAt) return 0
    if (!elSendTotal) elSendTotal = 0
    let weeksSinceSignature = this.getWeeksSinceSignature(
      mandateSignatureAt,
      offerAcceptedAt
    )

    if (weeksSinceSignature == 0) return elSendTotal
    else if (elSendTotal) return Math.floor(elSendTotal / weeksSinceSignature)
    return 0
  }

  get homeElement() {
    return this.$refs.home as HTMLElement
  }
  get alertNotificationsHouse() {
    return groupBy(
      state.notifications.filterByActivity("new_search_alert_created"),
      "deal_id"
    )
  }
  get alertNotificationsMessage() {
    return groupBy(
      state.notifications.filterByActivity("new_conversation_message", false),
      "deal_id"
    )
  }
  get dealsItems() {
    let deals: Array<DealHeader> = []
    for (let deal of this.deals.items) {
      if (deal.hubspot_stage == "Cash in effectué" && !this.cashIn) continue
      let dealHeader = { ...deal } as DealHeader
      dealHeader.notifications_new_alert =
        this.alertNotificationsHouse[deal.id] || []
      dealHeader.notifications_new_message =
        this.alertNotificationsMessage[deal.id] || []
      dealHeader.hubspot_owner_name = this.salespersonName(
        dealHeader.salesperson_id
      )
      dealHeader.customer_success_name = this.salespersonName(
        dealHeader.customer_success_id
      )
      dealHeader.sent_selected_houses_count_per_week = this.elSendPerWeek(
        dealHeader.mandate_signature_at,
        dealHeader.offer_accepted_at,
        dealHeader.sent_selected_houses_count
      )
      dealHeader.messages_sent_count_per_week = this.elSendPerWeek(
        dealHeader.mandate_signature_at,
        dealHeader.offer_accepted_at,
        dealHeader.messages_sent_count
      )
      dealHeader.alerts_count_per_week = this.elSendPerWeek(
        dealHeader.mandate_signature_at,
        dealHeader.offer_accepted_at,
        dealHeader.house_search_results_count
      )
      dealHeader.house_search_results_count_per_week = this.elSendPerWeek(
        dealHeader.mandate_signature_at,
        dealHeader.offer_accepted_at,
        dealHeader.house_search_results_count
      )
      dealHeader.first_visit_at_sorter = deal.first_visit_at
        ? new Intl.DateTimeFormat("en-ZA").format(new Date(deal.first_visit_at))
        : ""
      dealHeader.mandate_signature_at_sorter = deal.mandate_signature_at
        ? new Intl.DateTimeFormat("en-ZA").format(
            new Date(deal.mandate_signature_at)
          )
        : ""
      dealHeader.deal = deal
      deals.push(dealHeader)
    }

    return deals
  }
  get salespeopleFiltered() {
    return state.salespeople?.items.filter(
      (salesperson) =>
        salesperson.discarded_at == null &&
        (salesperson.level == "homecatcher" ||
          salesperson.level == "customer_success" ||
          salesperson.id === state.profile.id)
    )
  }
  get computedHeaders() {
    let headers = [
      {
        text: "Client",
        align: "start",
        value: "title",
        divider: true,
        breakpoint: "xs",
        width: "148px",
      },
      {
        text: "Stade",
        value: "hubspot_stage_id",
        width: "140px",
        sort: this.sortDealsbyStage,
        divider: true,
        breakpoint: "md",
      },
      {
        text: "Owner",
        value: "hubspot_owner_name",
        divider: true,
        breakpoint: "md",
      },
      {
        text: "CCM",
        value: "customer_success_name",
        divider: true,
        breakpoint: "md",
      },
      { text: "Budget", value: "budget", divider: true, breakpoint: "lg" },
      {
        text: "Geographie",
        value: "geography",
        divider: true,
        width: "100px",
        breakpoint: "lg",
      },
      {
        text: "Mess.",
        value: "notifications_new_message",
        align: "center",
        width: "95px",
        divider: true,
        sort: this.sortMessages,
        breakpoint: "xs",
      },
      {
        text: "Alertes",
        value: "notifications_new_alert",
        divider: true,
        sort: this.sortAlerts,
        width: "95px",
        align: "center",
        breakpoint: "xs",
      },
      {
        text: "Hubspot",
        value: "hubspotDealId",
        sortable: false,
        align: "center",
        divider: true,
        breakpoint: "lg",
      },
      {
        text: "Signature",
        value: "mandate_signature_at_sorter",
        divider: true,
        breakpoint: "lg",
      },
      {
        text: "1er visite",
        value: "first_visit_at_sorter",
        divider: true,
        breakpoint: "lg",
      },
      {
        text: "Envoyés",
        value: "sent_selected_houses_count",
        divider: true,
        align: "center",
        breakpoint: "lg",
      },
      {
        text: "Envoyés/sem",
        value: "sent_selected_houses_count_per_week",
        divider: true,
        align: "center",
        breakpoint: "lg",
      },
      {
        text: "Alertes/sem",
        value: "alerts_count_per_week",
        divider: true,
        align: "center",
        breakpoint: "lg",
      },
      {
        text: "Msg/sem",
        value: "messages_sent_count_per_week",
        divider: true,
        align: "center",
        breakpoint: "lg",
      },
      { text: "Client", value: "client", sortable: false, breakpoint: "lg" },
    ]
    const breakpoints = ["xs", "sm", "md", "lg", "xl"]
    return headers.filter(
      (header) =>
        breakpoints.indexOf(header.breakpoint) <=
        breakpoints.indexOf(this.$vuetify.breakpoint.name)
    )
  }
}

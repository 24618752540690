



































































































import { Component, Vue, Prop } from "vue-property-decorator"
import House, { DealStatus } from "~/models/House"
import snackbar from "~/plugins/snackbar"
import EventBus from "~/utils/EventBus"
import { state } from "@/store"
import HousePhotoManager from "@/components/modals/HousePhotoManager/HousePhotoManager.vue"
import ShareHouse from "@/components/modals/ShareHouse.vue"
import SendHouse from "@/components/modals/SendHouse.vue"
import PromptDeactivateForCustomer from "@/components/modals/PromptDeactivateForCustomer.vue"
import Deals from "~/collections/Deals"

type HouseAction = {
  name: string
  icon: string
  iconSize?: number
  readOnly?: boolean
}

export type HouseActionKey =
  | "share"
  | "putAside"
  | "removeFromSelection"
  | "deactivateForCustomer"
  | "sendMessage"
  | "putWaitingFromHat"
  | "showVirtualVisit"
  | "rescrapPhotos"
  | "handlePhotos"
  | "print"

@Component({
  components: {
    HousePhotoManager,
    SendHouse,
    ShareHouse,
    PromptDeactivateForCustomer,
  },
})
export default class HouseActions extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: true }) display!: "list" | "grid" | "full" | "infos"

  actions: Record<HouseActionKey, HouseAction> = {
    share: {
      name: "Séléctionner pour un autre client",
      icon: "mdi-share-outline",
      iconSize: 20,
      readOnly: true,
    },
    putAside: {
      name: "Mettre sous le chapeau",
      icon: "Hat",
    },
    removeFromSelection: {
      name: "Supprimer de la sélection",
      icon: "Trash",
    },
    deactivateForCustomer: {
      name: "Désactiver pour le client",
      icon: "UserMinus",
    },
    putWaitingFromHat: {
      name: "Remettre en sélection",
      icon: "mdi-checkbox-marked-circle-outline",
    },
    rescrapPhotos: {
      name: "Rescrap photos",
      icon: "RefreshCcw",
    },
    showVirtualVisit: {
      name: "Visite virtuelle",
      iconSize: 20,
      icon: "mdi-panorama-variant-outline",
    },
    handlePhotos: {
      name: "Gérer les photos",
      icon: "mdi-camera-outline",
    },
    sendMessage: {
      name: "Messages",
      icon: "mdi-message-reply-text-outline",
    },
    print: {
      name: "Imprimer la fiche du bien",
      icon: "mdi-printer-outline",
    },
  }

  actionsByStatus: Record<DealStatus, HouseActionKey[]> = {
    waiting: [
      "sendMessage",
      "share",
      "putAside",
      "removeFromSelection",
      "handlePhotos",
      "rescrapPhotos",
      "showVirtualVisit",
    ],
    processing: [
      "sendMessage",
      "share",
      "removeFromSelection",
      "deactivateForCustomer",
      "handlePhotos",
      "rescrapPhotos",
      "showVirtualVisit",
    ],
    heartstroked: [
      "sendMessage",
      "share",
      "removeFromSelection",
      "deactivateForCustomer",
      "handlePhotos",
      "rescrapPhotos",
      "showVirtualVisit",
    ],
    selected: [
      "sendMessage",
      "share",
      "removeFromSelection",
      "deactivateForCustomer",
      "handlePhotos",
      "rescrapPhotos",
      "showVirtualVisit",
    ],
    excluded: [
      "sendMessage",
      "share",
      "removeFromSelection",
      "deactivateForCustomer",
      "handlePhotos",
      "rescrapPhotos",
      "showVirtualVisit",
    ],
    aside: [
      "putWaitingFromHat",
      "share",
      "removeFromSelection",
      "handlePhotos",
      "rescrapPhotos",
      "showVirtualVisit",
    ],
    visit_planned: [],
    visit_done: [],
    visit_canceled: [],
    offer_sent: [],
    offer_accepted: [],
    offer_refused: [],
  }

  actionIndex = ["waiting"]

  // Dialogs getters
  get housePhotoManagerModale() {
    return this.$refs.housePhotoManagerModale as HousePhotoManager
  }
  get shareHouseModale() {
    return this.$refs.shareHouseModale as ShareHouse
  }
  get sendHouseModale() {
    return this.$refs.sendHouseModale as SendHouse
  }
  get deactivateForCustomerModale() {
    return this.$refs
      .promptDeactivateForCustomerModale as PromptDeactivateForCustomer
  }

  // generate secondaryActions attr
  get secondaryActions() {
    const { deal_status } = this.house
    let secondaryActions = deal_status
      ? this.actionsByStatus[deal_status]
      : ([
          "share",
          "putAside",
          "handlePhotos",
          "rescrapPhotos",
          "showVirtualVisit",
        ] as HouseActionKey[])
    if (this.display === "full") secondaryActions.push("print")

    if (state.readOnly) {
      secondaryActions = secondaryActions.filter(
        (action) => this.actions[action].readOnly === true
      )
    }
    if (!this.house.classified_ads) {
      secondaryActions = secondaryActions.filter(
        (action) => action != "handlePhotos"
      )
    }
    if (this.house.pictures.length > 0) {
      secondaryActions = secondaryActions.filter(
        (action) => action != "rescrapPhotos"
      )
    }
    if (!this.house.virtual_visit_url) {
      secondaryActions = secondaryActions.filter(
        (action) => action !== "showVirtualVisit"
      )
    }
    if (this.display != "grid") {
      secondaryActions = secondaryActions.filter(
        (action) => action !== "sendMessage"
      )
    }
    return secondaryActions
  }

  showVirtualVisit() {
    window.open(this.house.virtual_visit_url || "", "_blank")
  }
  print() {
    setTimeout(() => {
      window.print()
    }, 1000)
  }
  async share() {
    let dealsWithHouse = new Deals()
    await dealsWithHouse.fetch({
      url: `salespeople/${state.profile.id}/deals/with-house/${this.house.id}`,
      method: "GET",
    })
    this.shareHouseModale.openShareHouseModale(dealsWithHouse, this.house)
  }
  handlePhotos() {
    this.housePhotoManagerModale.open(this.house)
    EventBus.emit("handlePhotos", this.house)
  }
  sendMessage() {
    this.openHouseMessages()
  }
  openHouseMessages() {
    this.$emit("click", "messages")
  }

  // Actions
  executeAction(action: keyof HouseActions) {
    this[action]()
  }
  async removeFromSelection() {
    await this.house.removeFromSelection()
    EventBus.emit("reloadCurrentDeal")
    snackbar({
      color: "success",
      content: `Le bien a été supprimé de la sélection avec succès !`,
    })
  }
  async putWaitingFromHat() {
    await this.house.putWaiting()
    EventBus.emit("reloadCurrentDeal")
    snackbar({
      color: "success",
      content: `Le bien a été remis en sélection avec succès !`,
    })
  }
  async putAside() {
    await this.house.putAside()
    EventBus.emit("reloadCurrentDeal")
    snackbar({
      color: "success",
      content: `Le bien a été mis sous le chapeau avec succès !`,
    })
  }
  async rescrapPhotos() {
    await this.house.rescrapPhotos()
    snackbar({
      color: "success",
      content: `Rescrap lancé : merci de patienter une minute avant de rafraîchir la page!`,
    })
  }
  deactivateForCustomer() {
    this.deactivateForCustomerModale.open(this.house)
  }
  sendHouseToCustomer() {
    this.sendHouseModale.open(this.house)
  }
}

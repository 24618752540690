


















import { Component, Vue, Prop } from "vue-property-decorator"
import House, { DealStatus } from "~/models/House"

@Component({})
export default class HouseCardDealStatusEmoji extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: 14 }) size!: number

  get emojiStyle() {
    return `font-size: ${this.size}px;`
  }

  get dealStatusEmoji() {
    const emojiByStatus: Record<DealStatus, string> = {
      waiting: "",
      processing: "⏳",
      heartstroked: "💖",
      selected: "🤔",
      excluded: "👎",
      aside: "",
      visit_planned: "",
      visit_done: "",
      visit_canceled: "",
      offer_sent: "",
      offer_accepted: "",
      offer_refused: "",
    }
    return this.house.deal_status ? emojiByStatus[this.house.deal_status] : ""
  }
}

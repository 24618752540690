































import { Vue, Component } from "vue-property-decorator"
import { state } from "@/store"
import Deals from "~/collections/Deals"
import Deal from "~/models/Deal"
import House from "~/models/House"
import OcularDialog from "~/components/OcularDialog.vue"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    OcularDialog,
  },
})
export default class ShareHouse extends Vue {
  house = new House()
  dealsIdToShare: number[] = []
  newDealsIdToShare: number[] = []

  get dealsAlreadyShared() {
    return state.deals.items.filter(
      (deal: Deal) =>
        this.dealsIdToShare.includes(deal.id) &&
        deal.hubspot_stage_id != "cash_in_done"
    )
  }

  get deals() {
    return state.deals.items.filter(
      (deal: Deal) =>
        !this.dealsIdToShare.includes(deal.id) &&
        deal.hubspot_stage_id != "cash_in_done"
    )
  }
  get ocularDialog() {
    return this.$refs.ocularDialog as OcularDialog
  }

  openShareHouseModale(dealsWithHouse: Deals, house: House) {
    this.dealsIdToShare = dealsWithHouse.items.map((deal: Deal) => deal.id)
    this.newDealsIdToShare = []
    this.ocularDialog.open = true
    this.house = house
  }

  async shareHouseWithDeals() {
    await Promise.all(
      this.newDealsIdToShare.map((dealId) => {
        let house = new House()
        house.id = this.house.id
        house.share(dealId)
      })
    )
    snackbar({
      color: "success",
      content: `Le bien a été partagé avec succès !`,
    })
  }

  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasNoNotifications)?_c('p',{staticClass:"text-center no-notif"},[_vm._v(" Vous avez terminé ! Bravo ! ")]):_c('div',{staticClass:"actions-groups"},[(_vm.callAgentAfterVisitCancelationsSortByDate.length)?_c('div',[_c('v-subheader',[_c('OcularIcon',{staticClass:"mr-1",attrs:{"color":"text","name":"mdi-phone-cancel","size":15}}),_vm._v(" Visites à annuler auprès de l'agent ")],1),_c('v-list-item',{staticClass:"my-0"},[_c('div',{staticClass:"w-100"},_vm._l((_vm.callAgentAfterVisitCancelationsSortByDate),function(callAgentAfterVisitCancelation){return _c('v-card',{key:callAgentAfterVisitCancelation.id,staticClass:"w-100 d-flex align-center pointer py-3",attrs:{"flat":""},on:{"click":function($event){return _vm.goToVisit(
                callAgentAfterVisitCancelation.deal_id,
                callAgentAfterVisitCancelation.house.id,
                callAgentAfterVisitCancelation.notifiable_id
              )}}},[(callAgentAfterVisitCancelation.house.preview_url)?_c('v-img',{staticClass:"ml-3 preview-img",attrs:{"src":callAgentAfterVisitCancelation.house.preview_url}}):_c('ocular-icon',{staticClass:"ml-3 preview-no-img",attrs:{"name":"NoPicture","size":46,"color":"text-light"}}),_c('div',{staticClass:"px-3 w-100"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-card-title',{staticClass:"pt-0 pb-0 pl-0"},[_vm._v(_vm._s(callAgentAfterVisitCancelation.house.formatted_title))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("toCurrency")(callAgentAfterVisitCancelation.house.price)))])],1),_c('v-card-subtitle',{staticClass:"py-0 pl-0"},[_vm._v(" Avez-vous appelé l'agent pour annuler la visite ? ")]),_c('v-btn',{staticClass:"delete-notif-icon",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteNotification', callAgentAfterVisitCancelation)}}},[_c('OcularIcon',{attrs:{"name":"Trash","size":16}})],1)],1)],1)}),1)])],1):_vm._e(),(_vm.getAcceptSignedSortByDate.length)?_c('div',[_c('v-subheader',[_c('OcularIcon',{staticClass:"mr-1",attrs:{"color":"text","name":"mdi-file-sign","size":15}}),_vm._v(" Nouveaux document signés reçus ")],1),_c('v-list-item',{staticClass:"my-0"},[_c('div',{staticClass:"w-100"},_vm._l((_vm.getAcceptSignedSortByDate),function(notification){return _c('v-card',{key:notification.id,staticClass:"w-100 d-flex align-center pointer py-3",attrs:{"flat":""},on:{"click":function($event){return _vm.goToOffer(
                notification.deal_id,
                notification.house.id,
                notification.notifiable_id
              )}}},[(notification.house.preview_url)?_c('v-img',{staticClass:"ml-3 preview-img",attrs:{"src":notification.house.preview_url}}):_c('ocular-icon',{staticClass:"ml-3 preview-no-img",attrs:{"name":"NoPicture","size":46,"color":"text-light"}}),_c('div',{staticClass:"px-3 w-100"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-card-title',{staticClass:"pt-0 pb-0 pl-0"},[_vm._v(" "+_vm._s(notification.house.formatted_title)+" ")]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("toCurrency")(notification.house.price)))])],1),_c('v-card-subtitle',{staticClass:"py-0 pl-0"},[_vm._v(" Le client vient de signer l'offre ")]),_c('v-btn',{staticClass:"delete-notif-icon",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteNotification', notification)}}},[_c('OcularIcon',{attrs:{"name":"Trash","size":16}})],1)],1)],1)}),1)])],1):_vm._e(),(_vm.visitDoneRemindersSortByDate.length)?_c('div',[_c('v-subheader',[_c('OcularIcon',{staticClass:"mr-1",attrs:{"color":"text","name":"mdi-progress-pencil","size":15}}),_vm._v(" Commentaire de visite à rédiger ")],1),_c('v-list-item',{staticClass:"my-0"},[_c('div',{staticClass:"w-100"},_vm._l((_vm.visitDoneRemindersSortByDate),function(visitDoneReminder){return _c('v-card',{key:visitDoneReminder.id,staticClass:"w-100 d-flex align-center pointer py-3",attrs:{"flat":""},on:{"click":function($event){return _vm.goToVisit(
                visitDoneReminder.deal_id,
                visitDoneReminder.house.id,
                visitDoneReminder.notifiable_id
              )}}},[(visitDoneReminder.house.preview_url)?_c('v-img',{staticClass:"ml-3 preview-img",attrs:{"src":visitDoneReminder.house.preview_url}}):_c('ocular-icon',{staticClass:"ml-3 preview-no-img",attrs:{"name":"NoPicture","size":46,"color":"text-light"}}),_c('div',{staticClass:"px-3 w-100"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-card-title',{staticClass:"pt-0 pb-0 pl-0"},[_vm._v(_vm._s(visitDoneReminder.house.formatted_title))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("toCurrency")(visitDoneReminder.house.price)))])],1),_c('v-card-subtitle',{staticClass:"py-0 pl-0"},[_vm._v(" Commentaire de visite à rédiger ")])],1)],1)}),1)])],1):_vm._e(),(_vm.verificationsSortByDate.length)?_c('div',[_c('v-subheader',[_c('OcularIcon',{staticClass:"mr-1",attrs:{"color":"text","name":"Verification","size":15}}),_vm._v(" Vérifications ")],1),_c('v-list-item',{staticClass:"my-0"},[_c('div',{staticClass:"w-100"},_vm._l((_vm.verificationsSortByDate),function(verification){return _c('v-card',{key:verification.id,staticClass:"w-100 d-flex align-center pointer py-3",attrs:{"flat":""},on:{"click":function($event){return _vm.goToVerification(verification.deal_id, verification.house.id)}}},[(verification.house.preview_url)?_c('v-img',{staticClass:"ml-3 preview-img",attrs:{"src":verification.house.preview_url}}):_c('ocular-icon',{staticClass:"ml-3 preview-no-img",attrs:{"name":"NoPicture","size":46,"color":"text-light"}}),_c('div',{staticClass:"px-3 w-100"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-card-title',{staticClass:"pt-0 pb-0 pl-0"},[_vm._v(_vm._s(verification.house.formatted_title))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("toCurrency")(verification.house.price)))])],1),_c('v-card-subtitle',{staticClass:"py-0 pl-0"},[_vm._v(" en cours de verification ")])],1)],1)}),1)])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
import Collection from "../jrdm-mc/Collection"
import Partner from "../models/Partner"

export default class Partners extends Collection<Partner> {
  static responseField = "partners"
  static route = "partners"

  model() {
    return Partner
  }
}

import { render, staticRenderFns } from "./PanelAlerts.vue?vue&type=template&id=29de9a35&scoped=true&"
import script from "./PanelAlerts.vue?vue&type=script&lang=ts&"
export * from "./PanelAlerts.vue?vue&type=script&lang=ts&"
import style0 from "@/styles/inner-panel-list.scss?vue&type=style&index=0&id=29de9a35&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29de9a35",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VChip,VListItem,VListItemTitle})

import axios, { getUserToken, setBearerToken } from "../config/axios"
import { apiDomain } from "~/utils/environment"

export default {
  authenticated: false,

  async authenticate() {
    try {
      const token = getUserToken()
      const response = await axios.post(apiDomain + "/authenticate", { token })
      setBearerToken(response.data.auth_token)
      return (this.authenticated = true)
    } catch (error) {
      setBearerToken(null)
      return (this.authenticated = false)
    }
  },
}

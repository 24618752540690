import Collection from "../jrdm-mc/Collection"
import Notification from "../models/Notification"
import groupBy from "lodash/groupBy"

export default class Notifications extends Collection<Notification> {
  static route = "profile/notifications"
  static responseField = "notifications"

  deal_id: null | number = null
  house_search_results_per_page = 50

  filterByActivity(activity: string, unpushed = true) {
    return this.items.filter((el) => {
      return (
        el.activity === activity &&
        (unpushed ? true : el.push_enabled) &&
        el.seen === false
      )
    })
  }
  groupByActivity() {
    return groupBy(this.items, "activity") as { [key: string]: Notification[] }
  }

  model() {
    return Notification
  }

  mutations() {
    return {
      deal_id: parseInt,
    }
  }
}































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularIcon from "~/components/OcularIcon.vue"
import House from "~/models/House"

@Component({
  components: {
    OcularIcon,
  },
})
export default class HouseCardAgencyCounters extends Vue {
  @Prop({ required: true }) house!: House

  goToAgencyTab() {
    this.$emit("click", "agencies")
  }
}

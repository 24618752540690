
















import Vue from "vue"
import Topbar from "./default/Topbar.vue"
import Sidebar from "./default/Sidebar.vue"
import { Component, Watch } from "vue-property-decorator"
import { state } from "@/store"
import EventBus from "~/utils/EventBus"
import OcularLightbox, { Media } from "~/components/OcularLightbox.vue"
import OcularSnackbar from "~/components/OcularSnackbar.vue"
import Deal from "~/models/Deal"
import HouseSearches from "~/collections/HouseSearches"

@Component({
  components: {
    OcularSnackbar,
    Topbar,
    Sidebar,
    OcularLightbox,
  },
})
export default class Default extends Vue {
  mounted() {
    EventBus.on("openLightbox", this.openLightbox)
    this.onDealIdChange()
  }
  destroyed() {
    EventBus.off("openLightbox", this.openLightbox)
    this.onDealIdChange()
  }

  get lightbox() {
    return this.$refs.lightbox as OcularLightbox
  }
  get state() {
    return state
  }

  openLightbox(payload: { items: Media[]; index: number }) {
    const { items, index } = payload
    this.lightbox.openLightbox(items, index)
  }

  isReadOnlyMode() {
    const currentDealId = state.currentDealId()
    return !state.deals.items.some((deal) => deal.id == currentDealId)
  }

  @Watch("$route.params.dealId")
  async onDealIdChange() {
    const currentDealId = state.currentDealId()
    if (!currentDealId) {
      state.houseSearches = new HouseSearches()
      state.currentDeal = new Deal()
      return
    }
    state.houseSearches.deal_id = currentDealId
    state.deals.once("loaded", () => {
      state.readOnly = this.isReadOnlyMode()
      state.dealCustomersNumber =
        state.deals.items.find((deal) => deal.id == currentDealId)?.customers
          ?.length || 0
      state.currentDeal = new Deal()
      const foundDeal = state.deals.items.find(
        (deal) => deal.id === currentDealId
      )
      state.currentDeal = foundDeal || state.currentDeal
      if (!foundDeal) {
        state.currentDeal.id = state.currentDealId() || state.currentDeal.id
        if (state.currentDeal.id) state.currentDeal.fetch()
      }
    })
    await state.houseSearches.fetch()
  }
}

import Base from "./Base"
import axios, { AxiosRequestConfig, Method } from "axios"

type AnyMap = { [key: string]: any } | null

export default abstract class Model extends Base {
  static primaryKey = "id"

  // Saving
  async save(options: AxiosRequestConfig = {}) {
    options.method ||= this.getSaveMethod()
    options.url ||= this.renderRoute(this.getSaveRoute())
    options.data ||= this.getSaveData()
    const response = await axios(options)
    return response.data
  }

  // Save and assign
  async saveAndAssign(options: AxiosRequestConfig = {}) {
    const response = await this.save(options)
    return this.assignReceivedData(response)
  }

  getSaveMethod(): Method {
    return this.hasId() ? "PUT" : "POST"
  }
  getSaveRoute(): string {
    return this.getStatic("saveRoute") || this.getStatic("route")
  }
  getSaveData(): AnyMap {
    const attributes = this.pick(this.saveAttributes())
    this.formatAttributes.forEach(([key, filter]) => {
      attributes[key] = filter(attributes[key])
    })
    return {
      [this.getRequestField()]: attributes,
    }
  }

  assignReceivedData(data: any) {
    const responseField = this.getResponseField()
    return this.assign(responseField ? data[responseField] : data)
  }

  // filter to apply on model attributes before save
  get formatAttributes(): [string, (v: string) => string][] {
    return []
  }

  // array of attributes that are saved
  // if return undefined -> all attributes are saved
  saveAttributes(): string[] | undefined {
    return undefined
  }

  // Deleting
  async delete(options: AxiosRequestConfig = {}) {
    options.method ||= this.getDeleteMethod()
    options.url ||= this.renderRoute(this.getDeleteRoute())
    return await axios(options)
  }

  getDeleteMethod(): Method {
    return "DELETE"
  }
  getDeleteRoute(): string {
    return this.getStatic("deleteRoute") || this.getStatic("route")
  }

  // Miscellanous
  hasId() {
    return (this as any)[this.getStatic("primaryKey")] != undefined
  }
}

import Vue from "vue"
import App from "./App.vue"
import axios from "axios"
import router from "./router"
import store, { state, translate, enums } from "./store"
import vuetify from "~/plugins/vuetify"
import "../../common/styles/index.scss"
import OcularIcon from "~/components/OcularIcon.vue"
import "~/utils/filters"
import { apiDomain } from "~/utils/environment"
import VueLazyLoad from "vue-lazyload"
import "~/utils/clipboards"
import snackbar from "~/plugins/snackbar"
import * as VueGoogleMaps from "vue2-google-maps"
import { loadGmapApi } from "vue2-google-maps/dist/manager.js"
import "vue-image-lightbox/dist/vue-image-lightbox.min.css"
import { Component } from "vue-property-decorator"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

Component.registerHooks(["beforeRouteUpdate", "beforeRouteLeave"])

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
})
loadGmapApi({
  key: process.env.VUE_APP_GOOGLE_MAPS_API_TOKEN,
  libraries: ["places"],
})
Vue.use(VueGoogleMaps)
Vue.use(VueLazyLoad)
Vue.use(vuetify)
Vue.component("OcularIcon", OcularIcon)
axios.defaults.baseURL = apiDomain + "/ocular/api/v1"

// we make the store accessible to every component
Vue.prototype.$store = store
Vue.prototype.$state = state
Vue.prototype.$translate = translate
Vue.prototype.$enums = enums

const onProfileLoaded = () => {
  const isGoogleConnected = state.profile.isGoogleConnected
  if (
    !isGoogleConnected &&
    router.currentRoute.name !== "link-google-account-callback" &&
    router.currentRoute.name !== "link-google-account"
  ) {
    router.push({ name: "link-google-account" })
  }
}

Vue.config.errorHandler = (error, component) => {
  snackbar({
    content: `Erreur dans le component ${component.constructor.name} : "${error.message}"`,
    timeout: -1,
  })
  // eslint-disable-next-line
  console.error(error)
}

// we load the tags
state.tags.load()

// we authenticate
state.authenticate().then((authenticated) => {
  // we mount the app once authentication is done
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app")

  if (authenticated) {
    state.translation.fetch()
    state.profile.fetch().then(() => {
      onProfileLoaded()
    })
    state.notifications.fetch()
    state.deals.fetch()
    state.salespeople.fetch()
  } else if (router.currentRoute.path !== "/authentication-failed") {
    router.push("/authentication-failed")
  }
})

// we refetch notifications every minute
setInterval(() => state.notifications.fetch(), 60_000)

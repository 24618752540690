import axios, { AxiosRequestConfig, Method } from "axios"
import snackbar from "../plugins/snackbar"

type AnyMap = { [key: string]: any } | null
type Callback = (value: Base) => void
type Event = "loaded"

export default abstract class Base {
  static route = ""
  static fetchRoute = ""
  static saveRoute = ""
  static deleteRoute = ""
  static requestField = ""
  static responseField = ""

  protected _loaded = false
  private _events: { [key: string]: Array<Callback> } = {
    loaded: [],
  }
  private _onceEvents: { [key: string]: Array<Callback> } = {
    loaded: [],
  }

  get loaded() {
    return this._loaded
  }

  assign(object: AnyMap) {
    const mutations = this.mutations()
    for (let prop in object) {
      if (!(prop in this)) {
        console.warn(
          // eslint-disable-next-line
          `Property '${prop}' not in model or collection '${this.constructor.name}', you should add it. Property value :`,
          typeof object[prop] == "string" ? `"${object[prop]}"` : object[prop]
        )
      }

      let value = object[prop]
      if (value !== null && mutations && prop in mutations)
        value = mutations[prop](value)
      ;(this as any)[prop] = value
    }
    return this
  }

  abstract assignReceivedData(data: any): this

  getRequestField(): string {
    return this.getStatic("requestField")
  }
  getResponseField(): string {
    return this.getStatic("responseField")
  }

  mutations(): AnyMap {
    return this.getStatic("mutations")
  }

  /**
   * Api calls methods
   */
  async fetch(options: AxiosRequestConfig = {}) {
    this._loaded = false
    options.method ||= this.getFetchMethod()
    options.url ||= this.renderRoute(this.getFetchRoute())
    try {
      const response = await axios(options)
      this.assignReceivedData(response.data)
      this._loaded = true
      this.dispatchEvent("loaded")
      return response.data
    } catch (error) {
      snackbar({ content: "Une erreur est survenue, contactez les devs" })
    }
  }

  // fetch
  getFetchMethod(): Method {
    return "GET"
  }
  getFetchRoute(): string {
    return this.getStatic("fetchRoute") || this.getStatic("route")
  }

  /**
   * Event methods
   */
  on(event: Event, callback: Callback) {
    this._events[event].push(callback)
    if (event == "loaded" && this.loaded) callback(this) // if already loaded, we dispatch it immediately
    return this
  }

  off(event: Event, callback?: Callback) {
    if (callback) {
      let index = this._events[event].indexOf(callback)
      if (index == -1) return this
      this._events[event].splice(index, 1)
    } else {
      this._events[event].length = 0
    }
    return this
  }

  once(event: Event, callback: Callback) {
    if (event == "loaded" && this.loaded) callback(this)
    // if already loaded, we dispatch it immediately
    else this._onceEvents[event].push(callback)
    return this
  }

  // execute all the callbacks associated with an event
  protected dispatchEvent(event: Event) {
    for (let callback of this._events[event]) callback(this)
    while (this._onceEvents[event].length)
      (this._onceEvents[event].pop() as Callback)(this)
  }

  /**
   * Miscellanous methods
   */
  // render a route template (ex: 'alerts/{id}' -> 'alerts/125')
  renderRoute(route = "") {
    for (let key in this)
      route = route.replace(RegExp(`\\{${key}\\}`, "g"), this[key] as any)
    return route
  }

  // return a new object containing the selected keys only
  pick(keys: string[] = Object.keys(this)) {
    return Object.fromEntries(
      Object.entries(this).filter(
        ([key]) => !key.startsWith("_") && keys.includes(key)
      )
    )
  }

  // return a static property dynamically
  // (ie if the static proerpty is defined in child class, the child vale will be returned)
  protected getStatic(prop: string): any {
    return (this.constructor as any)[prop]
  }
}

import Model from "../jrdm-mc/Model"

export default class DelayedEmail extends Model {
  static route = "deals/{deal_id}/delayed"

  deal_id: number | null = null
  new_messages_count: number | null = null
  new_houses_count: number | null = null
  scheduled_at: Date | null = null
  time_left: number | null = null
}

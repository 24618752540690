// transform a string value into its real value
export default function trueValue(value: string | null) {
  switch (value) {
    case null:
      return null
    case "null":
      return null
    case "undefined":
      return undefined
    case "true":
      return true
    case "false":
      return false
    case "Infinity":
      return Infinity
    case "-Infinity":
      return -Infinity
  }

  if (value[0] == "{" || value[0] == "[") {
    try {
      return JSON.parse(value)
    } finally {
    }
  }

  return isNaN(value as any) ? value : +value
}

import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

import Page from "@/pages/Page.vue"

import Home from "@/pages/Home.vue"
import Dashboard from "@/pages/Dashboard.vue"
import Alerts from "@/pages/Alerts.vue"
import House from "@/pages/House.vue"
import Search from "@/pages/Search.vue"
import SearchResult from "@/pages/SearchResult.vue"
import Selected from "@/pages/deals/Selected.vue"
import AuthenticationFailed from "@/pages/AuthenticationFailed.vue"
import GoogleAuthCallback from "@/pages/GoogleAuthCallback.vue"
import GoogleAuth from "@/pages/GoogleAuth.vue"
import Visits from "@/pages/visits/Visits.vue"
import Offers from "@/pages/offers/Offers.vue"
Vue.use(VueRouter)

export let houseContextRoutes: Array<string> = []

const houseRoute = (parentName: string) => {
  const name = `${parentName}/house`
  houseContextRoutes.push(name)

  return [
    {
      name: name,
      path: `house/:houseId`,
      component: () => import("@/components/house/HouseSheet.vue"),
      children: [
        {
          path: "description",
          name: `${name}/description`,
          component: () => import("@/components/house/HouseDescription.vue"),
        },
        {
          path: "agencies",
          alias: "",
          name: `${name}/agencies`,
          component: () => import("@/components/agencies/HouseAgencies.vue"),
        },
        {
          path: "agencies/:agencyId",
          alias: "",
          name: `${name}/agency`,
          component: () => import("@/components/agencies/AgencySheet.vue"),
        },
        {
          path: "verification",
          alias: "",
          name: `${name}/verification`,
          component: () => import("@/components/house/HouseVerification.vue"),
        },
        {
          path: "messages",
          alias: "",
          name: `${name}/messages`,
          component: () => import("@/components/house/HouseMessages.vue"),
        },
        {
          path: "visits",
          alias: "",
          name: `${name}/visits`,
          component: () => import("@/components/house/HouseVisits.vue"),
        },
        {
          path: "visits/:visitId",
          alias: "",
          name: `${name}/visit`,
          component: () => import("@/pages/visits/VisitDetails.vue"),
        },
        {
          path: "offers",
          alias: "",
          name: `${name}/offers`,
          component: () => import("@/components/house/HouseOffers.vue"),
        },
        {
          path: "offers/:offerId",
          alias: "",
          name: `${name}/offer`,
          component: () => import("@/pages/offers/OfferDetails.vue"),
        },
      ],
    },
  ]
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "NoWrap",
    },
  },
  {
    path: "/link-google-account",
    name: "link-google-account",
    component: GoogleAuth,
    meta: {
      layout: "GoogleAuth",
    },
  },
  {
    path: "/oauth2callback",
    name: "link-google-account-callback",
    component: GoogleAuthCallback,
    meta: {
      layout: "GoogleAuth",
    },
  },
  {
    path: "/authentication-failed",
    name: "authentication-failed",
    component: AuthenticationFailed,
    meta: { layout: "" },
  },
  {
    path: "/deals/:dealId",
    component: Page,
    children: [
      ...houseRoute(""),
      {
        path: "dashboard",
        name: "dashboard",
        alias: "",
        component: Dashboard,
      },
      {
        path: "search",
        name: "search",
        component: Search,
      },
      {
        path: "search/result",
        name: "search/result",
        component: SearchResult,
        children: houseRoute("search/result"),
      },
      {
        path: "alerts/:houseSearchId?",
        name: "alerts",
        component: Alerts,
        children: houseRoute("alerts"),
      },
      {
        path: "selected",
        name: "waiting",
        component: Selected,
        children: houseRoute("waiting"),
      },
      {
        path: "sent",
        name: "sent",
        component: Selected,
        children: houseRoute("sent"),
      },
      {
        path: "visited",
        name: "visited",
        component: Visits,
        children: houseRoute("visited"),
      },
      {
        path: "under-offer",
        name: "offered",
        component: Offers,
        children: houseRoute("offered"),
      },
      {
        path: "hat",
        name: "aside",
        component: Selected,
        children: houseRoute("aside"),
      },
      {
        path: "conversations",
        name: "conversations",
        component: () => import("@/pages/Messages.vue"),
        props: true,
      },
      {
        path: "conversations/:conversationId",
        component: () => import("@/pages/Messages.vue"),
        name: "conversations/id",
        props: true,
        children: houseRoute("conversation/id"),
      },
    ],
  },
  {
    path: "/houses/:houseId",
    name: "house",
    component: House,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router

























import { Vue, Component } from "vue-property-decorator"
import DelayedEmail from "~/models/DelayedEmail"
import { state } from "@/store"
import snackbar from "~/plugins/snackbar"
import EventBus from "~/utils/EventBus"

@Component({})
export default class DelayedEmailButton extends Vue {
  delayedEmail = new DelayedEmail()
  interval = 0

  async mounted() {
    EventBus.on("fetchDelayed", this.retrieveCounts)
    await this.retrieveCounts()
    this.startInterval()
  }

  destroyed() {
    EventBus.off("fetchDelayed", this.retrieveCounts)
    this.stopInterval()
  }

  get sentenceDelayedEmail() {
    if (!this.delayedEmail) return ""
    let sentence = ""
    if (
      this.delayedEmail.new_houses_count &&
      this.delayedEmail.new_houses_count >= 1
    ) {
      sentence =
        this.delayedEmail.new_houses_count == 1
          ? "1 bien"
          : `${this.delayedEmail.new_houses_count} biens`
      if (
        this.delayedEmail.new_messages_count &&
        this.delayedEmail.new_messages_count > 0
      ) {
        sentence = `${sentence} et `
      }
    }
    if (
      this.delayedEmail.new_messages_count &&
      this.delayedEmail.new_messages_count >= 1
    ) {
      sentence =
        this.delayedEmail.new_messages_count == 1
          ? `${sentence}1 message`
          : `${sentence}${this.delayedEmail.new_messages_count} messages`
    }
    if (sentence != "") {
      if (
        (this.delayedEmail.new_houses_count &&
          this.delayedEmail.new_houses_count > 1) ||
        (this.delayedEmail.new_messages_count &&
          this.delayedEmail.new_messages_count > 1)
      )
        sentence = `${sentence} non vus par le client`
      else sentence = `${sentence} non vu par le client`
      sentence = `${sentence} | Envoi d'un email dans ${this.delayedEmail.time_left} minutes`
    }
    return sentence
  }

  async sendEmailToCustomer() {
    this.delayedEmail.deal_id = state.currentDealId()
    await this.delayedEmail.save()
    snackbar({
      color: "success",
      content: `Un email a été envoyé au(x) client(s) !`,
    })
  }

  async retrieveCounts() {
    this.delayedEmail.deal_id = state.currentDealId()
    await this.delayedEmail.fetch()
  }

  startInterval() {
    this.stopInterval()
    this.interval = setInterval(this.retrieveCounts, 20000)
  }

  stopInterval() {
    clearInterval(this.interval)
    this.interval = 0
  }
}

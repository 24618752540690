







































import { Component, Vue, Prop } from "vue-property-decorator"

import House from "~/models/House"
import HouseSearch from "~/models/HouseSearch"

import HouseActions, { HouseActionKey } from "./HouseActions.vue"
import HouseCardAgencyCounters from "./HouseCardAgencyCounters.vue"
import HouseCardDealStatusEmoji from "./HouseCardDealStatusEmoji.vue"
import HouseCardEditableTitle from "./HouseCardEditableTitle.vue"
import HouseCardImageCarousel from "./HouseCardImageCarousel.vue"
import HouseCardLocation from "./HouseCardLocation.vue"
import HouseCardPersonnalNote from "./HouseCardPersonnalNote.vue"
import HousePrices from "./HousePrices.vue"
import HouseTimeline from "./HouseTimeline.vue"

@Component({
  components: {
    HouseCardImageCarousel,
    HouseCardEditableTitle,
    HouseTimeline,
    HousePrices,
    HouseCardLocation,
    HouseActions,
    HouseCardAgencyCounters,
    HouseCardDealStatusEmoji,
    HouseCardPersonnalNote,
  },
})
export default class HouseCardGrid extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: null }) houseSearch!: HouseSearch

  get actions() {
    return this.$refs.actions as HouseActions
  }

  onActionRequest(action: HouseActionKey) {
    this.actions.executeAction(action)
  }

  openHouseFull(tab = "description") {
    this.$emit("open-house-full", tab)
  }
  openHouseFullDescription() {
    this.openHouseFull("description")
  }
}

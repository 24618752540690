var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.houseSearch && _vm.houseSearch.id)?_c('div',{staticClass:"results"},[_c('HouseCardContainer',{key:_vm.houseSearch.id,attrs:{"toolbar":"large","houses":_vm.houses,"display":_vm.display,"loading":_vm.loading,"house-search":_vm.houseSearch,"onHouseCardFullyVisible":_vm.onHouseCardFullyVisible,"pagination":{
        currentPage: _vm.currentPage,
        pageCount: this.results.pageCount,
        onPageChange: _vm.onPageChange,
      }},on:{"refetch":function($event){return _vm.houses.fetch()},"remove":_vm.houseRemoved}},[_c('div',{staticClass:"alerts-toolbar"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"gap":"25px"}},[_c('div',[_c('div',{staticClass:"tools"},[_c('label',[_vm._v("Séléction d'une alerte")]),_c('v-select',{staticClass:"alert-selector",attrs:{"items":_vm.$state.houseSearches.items,"item-text":"id","return-object":"","menu-props":_vm.menuProps,"solo":"","attach":"#attach-alert-menu","label":"Alerte"},on:{"change":_vm.updateAlert},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.title.substr(0, 45))+" "+_vm._s(item.title.length > 45 ? "..." : "")+" "),(item.unseen_results_count)?_c('span',{staticClass:"pl-2"},[_vm._v("("+_vm._s(item.unseen_results_count)+")")]):_vm._e()]}},(!_vm.$state.readOnly)?{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"title-item"},[_vm._v(_vm._s(item.title)+" "),(item.unseen_results_count)?_c('span',{staticClass:"pl-1"},[_vm._v("("+_vm._s(item.unseen_results_count)+")")]):_vm._e()]),_c('div',{staticClass:"item-icons"},[_c('NewAlert',{attrs:{"house-search":item,"title":"Dupliquer alerte","text-cancel":"Annuler"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"DuplicatePlus","color":"gray-dark","size":20}})],1)]}}],null,true)},[_c('span',[_vm._v("Dupliquer")])])],1),_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.updateAlertSearch(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"edit","color":"gray-dark","size":18}})],1)]}}],null,true)},[_c('span',[_vm._v("Editer")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('OcularPrompt',{on:{"ok":function($event){return _vm.deleteHouseSearch(item)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Êtes-vous certain de vouloir supprimer cette alerte ? ")]},proxy:true}],null,true)},[_c('OcularIcon',{attrs:{"name":"trash","color":"gray-dark","size":18}})],1)],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}:null,(!_vm.$state.readOnly)?{key:"append-item",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_c('OcularIcon',{staticClass:"mr-2",attrs:{"name":"bell","color":"white"}}),_c('router-link',{staticClass:"d-flex white--text",attrs:{"to":"../search"}},[_vm._v("Créer une alerte")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.houseSearch),callback:function ($$v) {_vm.houseSearch=$$v},expression:"houseSearch"}}),_c('div',{attrs:{"id":"attach-alert-menu"}})],1),(!_vm.loading)?_c('div',{staticClass:"config mt-2 hide-xs d-flex",staticStyle:{"gap":"20px"}},[_c('v-switch',{attrs:{"color":"secondary","inset":"","dense":"","label":"Ocular"},on:{"change":_vm.saveAlert},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Ocular ")])]}}],null,false,1227441273)},[_c('span',[_vm._v(" "+_vm._s(_vm.alert.enable_notifications ? "Désactiver l'alerte" : "Activer l'alerte")+" ")])])]},proxy:true}],null,false,1234132637),model:{value:(_vm.alert.enable_notifications),callback:function ($$v) {_vm.$set(_vm.alert, "enable_notifications", $$v)},expression:"alert.enable_notifications"}}),_c('v-switch',{attrs:{"disabled":_vm.areNotificationsDisabled,"inset":"","dense":"","color":"secondary","label":"Notifications"},on:{"change":_vm.saveAlert},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Notifications ")])]}}],null,false,389389537)},[_c('span',[_vm._v(" "+_vm._s(_vm.alert.enable_push ? "Ne plus recevoir les notifications" : "Recevoir des notifications")+" ")])])]},proxy:true}],null,false,2167284754),model:{value:(_vm.alert.enable_push),callback:function ($$v) {_vm.$set(_vm.alert, "enable_push", $$v)},expression:"alert.enable_push"}})],1):_vm._e()]),_c('div',{staticClass:"d-none d-lg-block max-width-70"},[_c('label',[_vm._v("Critères de l'alerte")]),_c('SearchActiveTags',{attrs:{"house-search":_vm.houseSearch,"expandable":true}})],1)]),(_vm.houseSearch.id)?_c('div',{staticClass:"hide-xs count"},[_vm._v(" "+_vm._s(_vm.houseSearch.results_count)+" "+_vm._s(_vm.houseSearch.results_count > 1 ? "alertes" : "alerte")+" "),(_vm.houseSearch.unseen_results_count)?_c('div',{staticClass:"unseen-alerts"},[_vm._v(" ("+_vm._s(_vm.houseSearch.unseen_results_count)+" non vue"+_vm._s(_vm.houseSearch.unseen_results_count > 1 ? "s" : "")+") ")]):_vm._e()]):_vm._e()])])],1):_c('div',{staticClass:"pt-10 text-center"},[_vm._v(" Vous n'avez actuellement aucune alerte pour ce client ")])])}
var staticRenderFns = []

export { render, staticRenderFns }
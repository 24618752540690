import Model from "../jrdm-mc/Model"
import History from "./History"
import ClassifiedAd from "./ClassifiedAd"
import {
  trimmed,
  asArray,
  arrayOf,
  toDate,
  model,
} from "../utils/functionsFilter"
import Agency from "./Agency"
import ActionTeam from "./ActionTeam"
import Verification from "./Verification"
import PriceHistory from "./PriceHistory"
import LatLgn from "~/types/LatLng"
import axios from "axios"
import snackbar from "~/plugins/snackbar"
import { translate } from "../../ocular/src/store"
import Visit from "~/models/Visit"
import Offer from "~/models/Offer"

export type AdsHistory = {
  agency_name: string | null
  agency_phone: string | null
  agent_name: string | null
  agent_phone: string | null
  scraps: ClassifiedAd[]
}

export type DpeType = "A" | "B" | "C" | "D" | "E" | "F" | "G"

export enum PropertyType {
  Unknown,
  house,
  flat,
}

export enum HouseState {
  waiting,
  processing,
  heartstroked,
  selected,
  excluded,
  aside,
}

export type HouseLocation = {
  id: number
  label: string
  center: LatLgn
  type: string
  polygon?: LatLgn[][]
}

export type HousePicture = {
  big: string
  thumb: string
  position: number
}

export type HouseTag = {
  id: number
  name: string
  weight: number
}

export type DealStatus =
  | "waiting"
  | "processing"
  | "heartstroked"
  | "selected"
  | "excluded"
  | "aside"
  | "visit_canceled"
  | "visit_done"
  | "visit_planned"
  | "offer_sent"
  | "offer_accepted"
  | "offer_refused"

export default class House extends Model {
  static responseField = "house"
  static requestField = "selected_house"
  static route = "deals/{deal_id}/houses/{id}"
  static primaryKey = "selected_house_id"

  getSaveRoute() {
    return this.hasId() ? House.route : "deals/{deal_id}/houses"
  }

  id: null | number = null
  title: null | string = null
  preview_url: null | string = null
  description: null | string = null
  classified_ad_id: null | number = null
  floor_count: null | number = null
  off_market = false
  deal_status: null | DealStatus = null
  visit_count: number | null = null
  offer_count: number | null = null
  agency_count = 0
  individual_count = 0
  opposite: null | string = null
  brightness: null | string = null
  calm: null | string = null
  condition: null | string = null
  copro_condition: null | string = null
  nugget = false
  action_team: null | string = null
  action_team_user_name: null | string = null
  action_team_user_id: null | number = null
  action_team_type: null | string = null
  action_team_status: null | string = null
  action_team_label: null | string = null
  action_team_deal_id: null | number = null
  action_team_date: null | Date = null
  address: null | string = null
  property_type = PropertyType.flat
  price: null | number = null
  pricem2: null | number = null
  last_price: null | number = null
  bedroom_count: null | number = null
  room_count: null | number = null
  living_area: null | number = null
  estate_area: null | number = null
  dpe: null | number = null
  dpe_class: null | DpeType = null
  ges: null | number = null
  floor: null | number = null
  last_floor: null | boolean = null
  ground_floor: null | boolean = null
  fireplace: null | boolean = null
  elevator: null | boolean = null
  balcony: null | boolean = null
  terrace: null | boolean = null
  parking: null | boolean = null
  cellar: null | boolean = null
  garden: null | boolean = null
  swimmingpool: null | boolean = null
  exposition: null | Array<number> = null
  garden_exposition: null | Array<number> = null
  latitude: null | number = null
  longitude: null | number = null
  score: null | number = null
  score_city: null | number = null
  score_tag: null | number = null
  elastic_score: null | number = null
  insee: null | string = null
  city_id: null | number = null
  city_name: null | string = null
  formatted_city_name: null | string = null
  department_code: null | string = null
  region_code: null | string = null
  region_name: null | string = null
  natural_region_id: null | number = null
  construction_year: null | Date = null
  active = true
  photos_count = 0
  price_changed_at: null | Date = null
  republished_at: null | Date = null
  created_at: null | Date = null
  updated_at: null | Date = null
  verification_date: null | Date = null
  verification_type: null | string = null
  verification_username: null | string = null
  verification_user_id: null | number = null
  ocular_uri: null | string = null
  pictures: Array<HousePicture> = []
  tags: Array<HouseTag> = []
  history: Array<History> = []
  additional_information_text: null | string = null
  has_note: null | boolean = null
  virtual_visit_url: null | string = null
  unseen_messages: boolean | null = null

  // -- selected houses properties
  selected_house_id: number | null = null
  selected_house_title: string | null = null
  deal_id: number | null = null
  house_id: number | null = null
  creator_id: number | null = null
  position: number | null = null
  selected_house_preview_url: string | null = null
  body: string | null = null
  state: HouseState | null = null
  state_changed_by_id: number | null = null
  state_changed_at: string | null = null
  selected_house_active: boolean | null = null
  sent_at: string | null = null
  verification_status: string | null = null
  selected_house_created_at: string | null = null
  selected_house_updated_at: string | null = null
  conversation_id: number | null = null
  unseen_messages_count: number | null = null
  state_changed_by: string | null = null
  unseen_by = []
  seen: null | boolean = null

  // -- full house properties
  classified_ads: Array<ClassifiedAd> | null = null
  agencies: Array<Agency> | null = null
  no_agency_count: number | null = null
  actions_team: Array<ActionTeam> | null = null
  localisation: Array<HouseLocation> | null = null
  price_history: Array<PriceHistory> | null = null
  copro_work_price: number | null = null
  construction_type: string | null = null
  monthly_charges: number | null = null
  guard: boolean | null = null
  living_room_area: number | null = null
  bedroom_areas: number[] | null = null
  kitchen_area: number | null = null
  kitchen_type: string | null = null
  orientation: string | null = null
  parking_type: string | null = null
  house_in_copro: boolean | null = null
  adjoining: boolean | null = null
  level_count: number | null = null
  work_price: number | null = null
  verification: Verification | null = null
  visits: Visit[] | null = null
  offers: Offer[] | null = null

  underground_parking: boolean | null = null
  sell_status: string | null = null
  exclusivity: boolean | null = null
  additional_note: string | null = null
  bike_room: boolean | null = null
  fiber_connection: string | null = null
  estimated_work_share: number | null = null
  transport_quality: number | null = null
  time_to_public_transport: number | null = null
  shop_quality: number | null = null
  school_quality: number | null = null
  security_quality: number | null = null
  green_spaces_quality: number | null = null
  property_tax: number | null = null
  heating_sharing: string | null = null
  heating_type: string | null = null
  balcony_area: number | null = null
  terrace_area: number | null = null
  garden_area: number | null = null
  bathroom_count: number | null = null
  shower_room_count: number | null = null
  wc_count: number | null = null
  separate_wc: boolean | null = null
  sanibrinder: boolean | null = null
  crossing: boolean | null = null
  sunny: boolean | null = null
  double_glazing: boolean | null = null
  attic: boolean | null = null
  ceiling_height: number | null = null
  individual_comment: string | null = null
  address_text: string | null = null
  views: Array<string> = []
  styles: Array<string> = []

  customer_interested_count: number | null = null
  hc_interested_count: number | null = null

  agency_name: string | null = null
  agency_phone: string | null = null
  agent_name: string | null = null
  agent_phone: string | null = null

  static mutations = {
    title: trimmed,
    description: trimmed,
    address: trimmed,
    insee: trimmed,
    city_name: trimmed,
    formatted_city_name: trimmed,
    department_code: trimmed,
    region_code: trimmed,
    verification_type: trimmed,
    verification_username: trimmed,
    ocular_uri: trimmed,
    virtual_visit_url: trimmed,
    additional_information_text: trimmed,
    pictures: asArray,
    tags: asArray,
    history: asArray,
    action_team_date: toDate,
    price_changed_at: toDate,
    republished_at: toDate,
    created_at: toDate,
    updated_at: toDate,
    verification_date: toDate,
    verification: model(Verification),

    // -- full house properties
    classified_ads: arrayOf(ClassifiedAd),
    agencies: arrayOf(Agency),
    actions_team: arrayOf(ActionTeam),
    price_history: arrayOf(PriceHistory),
    visits: arrayOf(Visit),
    offers: arrayOf(Offer),
  }

  getSaveData() {
    return {
      [this.getRequestField()]: {
        id: this.selected_house_id,
        title: this.selected_house_title,
        active: this.selected_house_active,
        deal_id: this.deal_id,
        body: this.body,
        house_id: this.id,
        state: this.state,
      },
    }
  }

  constructor(object?: Record<string, any>) {
    super()
    object && this.assign(object)
  }

  /**
   * Save the whole house object
   */
  async update(attributes = Object.keys(this)) {
    if (!this.id) {
      snackbar({
        content: `Impossible de mettre à jour un bien sans ID, Contacter les dèvs`,
      })
      return null
    }
    return await this.save({
      url: `/houses/${this.id}`,
      method: "PUT",
      data: this.pick(attributes), // the whole house object
    })
  }

  /**
   * Return the list of ordonned characteristics
   */
  characteristics() {
    const entries: [keyof this, any][] = []
    const addEntry = (property: keyof this) => {
      if (this[property] === null) return false
      entries.push([property, this[property]])
      return true
    }
    const addEntries = (...args: (keyof this)[]) => {
      for (const arg of args) addEntry(arg)
    }
    addEntries("living_area", "room_count", "bedroom_count", "estate_area")
    if (this.ground_floor || this.floor === 0) {
      // @ts-ignore (compiler does not understand 'ground-floor' is a property of this)
      entries.push(["ground_floor", true])
    } else {
      addEntries("floor", "last_floor")
    }
    addEntries(
      "elevator",
      "dpe_class",
      "opposite",
      "formatted_exposition",
      "brightness",
      "calm",
      "condition"
    )
    return entries
  }

  photoBadges(numberOfCustomer: number) {
    type entry = {
      text: string
      detailed_text: string
      color: string
      icon: string | null
    }
    const entries: entry[] = []
    if (this.action_team_user_id && this.action_team_label) {
      let color = "secondary"
      if (!this.creator_id || this.action_team_user_id != this.creator_id)
        color = "info"

      const text = this.action_team_label
      let detailedText = text
      if (this.action_team_user_name && this.action_team_date) {
        const formattedActionTeamDate = Intl.DateTimeFormat("fr-FR").format(
          this.action_team_date
        )
        detailedText = `${this.action_team_label} par ${this.action_team_user_name} le ${formattedActionTeamDate}`
      }
      entries.push({
        text,
        detailed_text: detailedText,
        color: color,
        icon: null,
      })
    }
    if (this.isSent()) {
      if (this.unseen_by.length == 0)
        entries.push({
          text: "Vu par tous",
          detailed_text: "Vu par tous",
          color: "text",
          icon: null,
        })
      else if (this.unseen_by.length == numberOfCustomer)
        entries.push({
          text: "Vu par personne",
          detailed_text: "Vu par personne",
          color: "text",
          icon: null,
        })
      else
        entries.push({
          text: `Vu par ${this.unseen_by.join(", ")}`,
          detailed_text: `Vu par ${this.unseen_by.join(", ")}`,
          color: "text",
          icon: null,
        })
    }
    if (this.seen === false)
      entries.push({
        text: "Non vu",
        detailed_text: "Non vu",
        color: "primary",
        icon: null,
      })
    if (this.nugget === true)
      entries.push({
        text: "Pépite",
        detailed_text: "Pépite",
        color: "warning",
        icon: "Diamond",
      })
    if (this.off_market === true)
      entries.push({
        text: "OFF",
        detailed_text: "OFF",
        color: "primary",
        icon: null,
      })
    if (this.deal_status === "aside")
      entries.push({
        text: "Sous le chapeau",
        detailed_text: "Sous le chapeau",
        color: "tertiary",
        icon: "Hat",
      })
    return entries
  }

  statusBadgeText() {
    if (this.selected_house_active === false) return "Désactivé pour ce client"
    else if (this.sell_status && this.sell_status !== "on_market")
      return translate("sell_status", this.sell_status)
    else if (!this.active) return "Désactivé sur le site source"
    return null
  }

  isSelected() {
    return this.deal_status == "waiting"
  }

  isSent() {
    return !!this.deal_status && !this.isSelected() && !this.isUnderHat()
  }

  get wasVisited(): boolean {
    return !!(this.visit_count && this.visit_count > 0)
  }

  get gotOffer(): boolean {
    return !!(this.offer_count && this.offer_count > 0)
  }

  get agencyIds(): number[] {
    return this.agencies ? this.agencies.map((agency) => agency.id || 0) : []
  }

  isUnderHat() {
    return this.deal_status == "aside"
  }

  isInClientFolder() {
    return this.isSent() || this.isSelected() || this.isUnderHat()
  }

  // return the properties specific to selected houses only
  selectedHouseProperties() {
    return this.pick(this.saveAttributes())
  }

  get formatted_title() {
    let title_compiled = this.property_type == 1 ? "Maison" : "Appartement"
    if (this.room_count)
      title_compiled = `${title_compiled} - ${this.room_count}p`
    if (this.bedroom_count)
      title_compiled = `${title_compiled} - ${this.bedroom_count}ch`
    if (this.living_area)
      title_compiled = `${title_compiled} - ${this.living_area}m²`
    return title_compiled
  }

  get formatted_exposition() {
    const expositions = ["N", "NE", "E", "SE", "S", "SO", "O", "NO"]
    if (!this.exposition) return null
    const translated_expositions = this.exposition.map((expo) => {
      return expositions[expo]
    })
    return translated_expositions.join(" / ")
  }

  async removeFromSelection() {
    await this.save({ method: "delete" })
    this.state = null
    this.deal_status = null
    this.selected_house_id = null
  }

  async putWaiting() {
    let position = this.position
    this.state = HouseState.waiting
    await this.saveAndAssign()
    this.position = position
  }

  async send() {
    let position = this.position
    this.state = HouseState.processing
    await this.saveAndAssign()
    this.position = position
  }

  // mettre sous le chapeau
  async putAside() {
    let position = this.position
    this.state = HouseState.aside
    await this.saveAndAssign()
    this.position = position
  }
  // share
  async share(dealId: number) {
    this.state = HouseState.waiting
    this.selected_house_id = null
    this.deal_id = dealId
    await this.save()
  }

  async adAssociate(
    agency_id: number | null,
    agent_id: number | null,
    classified_ad_ids: number[],
    previous_agency_id: number | null,
    deal_id: number | null,
    individual: boolean | null,
    individual_comment: string | null
  ) {
    const data = {
      house: {
        agency_id,
        agent_id,
        classified_ad_ids,
        previous_agency_id,
        deal_id,
        individual,
        individual_comment,
      },
    }
    return await this.fetch({
      method: "post",
      url: `houses/${this.id}/ad_associations`,
      data: data,
    })
  }

  async rescrapPhotos() {
    await this.fetch({
      method: "put",
      url: `houses/${this.id}/rescrap_photos`,
    })
  }

  async uploadPhoto(formData: FormData) {
    const response = await axios.post(
      `houses/${this.id}/upload_photo`,
      formData
    )
    this.assign(response.data.house)
    return response
  }

  async associateAgent(data: {
    agency_id: number
    agent_id: number
    deal_id: number | null
    previous_agency_id: number | null
  }) {
    return await axios.post(`houses/${this.id}/agent_association`, {
      house: data,
    })
  }

  async removeAgencyAssociation(data: { agency_id: number; deal_id: number }) {
    const response = await axios.post(
      `houses/${this.id}/remove_agency_associations`,
      { house: data }
    )
    return this.assign(response.data.house)
  }

  async removeAgentAssociation(data: { agent_id: number; deal_id: number }) {
    const response = await axios.post(
      `houses/${this.id}/remove_agent_associations`,
      { house: data }
    )
    return this.assign(response.data.house)
  }

  async merge(childHouseIds: number[], deal_id: number | null) {
    try {
      const response = await axios.post(`houses/${this.id}/deduplicate`, {
        deduplicated_ids: childHouseIds,
        deal_id: deal_id,
      })
      snackbar({
        color: "success",
        content: "Les biens ont été correctement dédoublonnés",
      })
      this.assign(response.data.house)
      return true
    } catch (e) {
      if (
        e.response.status === 422 &&
        e.response.data.message === "UNPROCESSABLE_ENTITY"
      ) {
        snackbar({
          content:
            "Les biens ne peuvent être dédoublonnés car l'un d'eux à déjà été envoyé à un client",
        })
      }
      return false
    }
  }
}

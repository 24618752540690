













import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import Offer from "~/models/Offer"

@Component({
  components: {},
})
export default class OfferAmount extends Vue {
  @Prop({ required: true }) offer!: Offer
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: 22 }) size!: number

  get priceStyle() {
    return {
      fontSize: `${this.size}px`,
    }
  }
}









































































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularIcon from "~/components/OcularIcon.vue"
import House from "~/models/House"

@Component({
  components: {
    OcularIcon,
  },
})
export default class HouseCardEditableTitle extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: 14 }) size!: number
  @Prop({ required: false, default: false }) editable!: boolean
  titleEditorLoading = false
  houseTitleEdit = false
  isHover = false

  onEditPress() {
    this.houseTitleEdit = true
  }

  get plainTitleStyle() {
    return `font-size: ${this.size}px`
  }

  async updateCustomTitle() {
    this.titleEditorLoading = true
    await this.house.save()
    this.houseTitleEdit = false
    this.titleEditorLoading = false
  }
}










































































































































































































import { Vue, Component, Watch } from "vue-property-decorator"
import HouseSearch from "~/models/HouseSearch"
import HouseSearchResults from "~/collections/HouseSearchResults"
import { toQueryUrl } from "~/utils/queryUrl"
import House from "~/models/House"
import Alert from "~/models/Alert"
import HouseSearchResult from "~/models/HouseSearchResult"
import { state } from "@/store"
import router from "@/router"
import HouseCardContainer from "@/components/house/HouseCardContainer.vue"
import axios from "axios"
import SearchActiveTags from "@/components/search/SearchActiveTags.vue"

@Component({
  components: {
    SearchActiveTags,
    HouseCardContainer,
    NewAlert: () => import("@/components/modals/NewAlert.vue"),
    OcularPrompt: () => import("~/components/OcularPrompt.vue"),
  },
})
export default class Alerts extends Vue {
  loading = false
  display: "grid" | "list" = "grid"
  houses: House[] = []
  houseSearch = new HouseSearch()
  results = new HouseSearchResults()
  alert = new Alert() // the configuration of the current alert
  currentPage = 1
  colors = [
    "indigo",
    "warning",
    "pink darken-2",
    "red lighten-1",
    "deep-purple accent-4",
  ]
  slides = ["First", "Second", "Third", "Fourth", "Fifth"]
  menuProps = {
    maxHeight: 540,
  }

  mounted() {
    // once the houses searches are loaded, we find the current one
    state.houseSearches.on("loaded", this.onHouseSearchesLoaded)
  }

  destroyed() {
    state.houseSearches.off("loaded", this.onHouseSearchesLoaded)
  }

  get areNotificationsDisabled() {
    return !this.alert.enable_notifications
  }

  @Watch("$route.params.houseSearchId")
  async onHouseSearchesLoaded() {
    if (this.$route.params.houseSearchId) {
      const id = parseInt(this.$route.params.houseSearchId)
      this.houseSearch =
        state.houseSearches.items.find((item) => item.id == id) ||
        new HouseSearch()
    } else {
      this.houseSearch = state.houseSearches.items[0]
    }
    if (this.houseSearch?.id) {
      await this.loadHouseSearchResults()
      this.updateAlert()
    }
  }

  async loadHouseSearchResults() {
    if (
      this.results.page != this.currentPage ||
      (this.houseSearch.id &&
        this.results.house_search_id != this.houseSearch.id)
    ) {
      this.loading = true
      this.results.house_search_id = this.houseSearch.id
      this.results.deal_id = this.houseSearch.deal_id
      this.results.page = this.currentPage
      this.results.total_count = 0
      await this.results.fetch()
      this.houses = this.results.items.map((result: HouseSearchResult) => {
        let house = result.house as House
        house.seen = result.seen
        return house
      })
      this.loading = false
    }
  }

  async updateAlert() {
    if (!this.houseSearch.id) return
    this.currentPage = 1
    const dealId = this.$route.params.dealId
    const houseSearchId = this.houseSearch.id
    const alert = new Alert().assign({ dealId, houseSearchId })
    await alert.fetch()
    this.alert = alert
    if (parseInt(this.$route.params.houseSearchId) != houseSearchId) {
      router.push({ path: `/deals/${dealId}/alerts/${houseSearchId}` })
    }
  }

  updateAlertSearch(houseSearch: HouseSearch) {
    const filteredEntries = Object.entries(houseSearch).filter(
      ([key, value]) => !key.startsWith("_") && value !== null
    )
    const filteredHouseSearch = Object.fromEntries(filteredEntries)
    this.$router.push(`../search?${toQueryUrl(filteredHouseSearch)}`)
  }

  async deleteHouseSearch(houseSearch: HouseSearch) {
    // const copy = new HouseSearch().assign(houseSearch)
    await houseSearch.delete()
    await state.houseSearches.fetch()
  }

  async saveAlert() {
    if (this.alert.enable_notifications == true) {
      this.alert.frequency = "instantly"
    } else {
      this.alert.frequency = "never"
      this.alert.enable_push = false
    }
    await this.alert.save()
  }

  onPageChange(page: number) {
    this.currentPage = page
    this.loadHouseSearchResults()
  }

  houseRemoved(house: House) {
    this.houses = this.houses.filter((item) => item.id !== house.id)
  }

  async onHouseCardFullyVisible(house: House) {
    if (
      house.seen ||
      state.readOnly ||
      this.$route.params.houseId ||
      !this.houseSearch.id
    )
      return
    house.seen = true
    this.houseSearch.unseen_results_count--

    // we decrement the notifications
    if (this.alert.enable_notifications) {
      const notification = state.notifications.find(
        // @ts-ignore
        (notif) =>
          notif.notifiable_id == this.houseSearch.id &&
          notif.notifiable_type == "HouseSearch"
      )
      if (notification && parseInt(notification.activity_value) > 0)
        notification.activity_value = String(
          parseInt(notification.activity_value) - 1
        )
    }

    await axios.put(
      `deals/${this.houseSearch.deal_id}/house-searches/${this.houseSearch.id}/results/${house.id}`
    )
  }
}

import Model from "../jrdm-mc/Model"
import Profile from "./Profile"
import { arrayOf, toDate } from "~/utils/functionsFilter"

export default class Deal extends Model {
  static route = "deals/{id}"
  static responseField = "deal"
  static requestField = "deal"

  id = 0
  title: null | string = null
  hubspot_stage: null | string = null
  salesperson_id: null | number = null
  customer_success_id: null | number = null
  salesperson_ids: null | number[] = null
  budget: null | number = null
  hubspot_stage_id: null | string = null
  hubspot_owner_id: null | number = null
  hubspot_deal_id: null | number = null
  sent_selected_houses_count: null | number = null
  selected_houses_count: null | number = null
  aside_selected_houses_count: null | number = null
  visit_count: null | number = null
  offer_count: null | number = null
  excluded_selected_houses_count: null | number = null
  waiting_selected_houses_count: null | number = null
  selected_selected_houses_count: null | number = null
  processing_selected_houses_count: null | number = null
  heartstroked_selected_houses_count: null | number = null
  house_search_results_count: null | number = null
  messages_sent_count: null | number = null
  uri: null | string = null
  geography: null | string = null
  customers: null | Profile[] = null
  created_at = new Date()
  updated_at = new Date()
  mandate_signature_at: null | Date = null
  first_visit_at: null | Date = null
  offer_accepted_at: null | Date = null

  signatories: Profile[] = []
  signatory_ids: number[] = []
  buyer_street: string | null = null
  buyer_zipcode: string | null = null
  buyer_city: string | null = null

  mutations() {
    return {
      id: parseInt,
      title: (value: string) => value.trim(),
      hubspot_stage: (value: string) => value.trim(),
      salesperson_id: parseInt,
      customer_success_id: parseInt,
      budget: parseInt,
      hubspot_stage_id: (value: string) => value.trim(),
      hubspot_owner_id: parseInt,
      hubspot_deal_id: parseInt,
      sent_selected_houses_count: parseInt,
      house_search_results_count: parseInt,
      messages_sent_count: parseInt,
      uri: (value: string) => value.trim(),
      geography: (value: string) => value.trim(),
      created_at: toDate,
      updated_at: toDate,
      mandate_signature_at: toDate,
      first_visit_at: toDate,
      offer_accepted_at: toDate,
      signatories: arrayOf(Profile),
    }
  }

  saveAttributes() {
    return [
      "id",
      "title",
      "hubspot_stage",
      "salesperson_id",
      "customer_success_id",
      "budget",
      "hubspot_stage_id",
      "hubspot_owner_id",
      "hubspot_deal_id",
      "sent_selected_houses_count",
      "house_search_results_count",
      "messages_sent_count",
      "uri",
      "geography",
      "created_at",
      "updated_at",
      "mandate_signature_at",
      "first_visit_at",
      "offer_accepted_at",
      "signatory_ids",
      "salesperson_ids",
    ]
  }

  hubspotUrl() {
    return `https://app.hubspot.com/contacts/3800700/deal/${this.hubspot_deal_id}`
  }

  get signatoriesNames() {
    const firstnames = this.signatories.map((signatory) => signatory.first_name)
    return firstnames.join(", ")
  }
}

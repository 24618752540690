import Collection from "../jrdm-mc/Collection"
import House from "../models/House"

export default class Houses extends Collection<House> {
  static route = "/deals/{deal_id}/houses/by-state/{state}"
  static responseField = "houses"
  model() {
    return House
  }

  deal_id: null | number = null
  state: null | string = null

  constructor(object?: Record<string, any>) {
    super()
    if (object) this.assign(object)
  }
}

import { render, staticRenderFns } from "./HousePrices.vue?vue&type=template&id=68474b0c&scoped=true&"
import script from "./HousePrices.vue?vue&type=script&lang=ts&"
export * from "./HousePrices.vue?vue&type=script&lang=ts&"
import style0 from "./HousePrices.vue?vue&type=style&index=0&id=68474b0c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68474b0c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})

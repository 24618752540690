


























import PanelAlerts from "@/components/panels/PanelAlerts.vue"
import PanelMessages from "@/components/panels/PanelMessages.vue"
import PanelTeam from "@/components/panels/PanelTeam.vue"
import PanelActions from "@/components/panels/PanelActions.vue"
import PanelCustomers from "@/components/panels/PanelCustomers.vue"
import { state } from "@/store"
import Notification from "~/models/Notification"
import { Vue, Component, Prop } from "vue-property-decorator"

@Component({
  components: {
    PanelAlerts,
    PanelMessages,
    PanelTeam,
    PanelActions,
    PanelCustomers,
  },
})
export default class Panel extends Vue {
  @Prop({ required: true }) title = ""
  @Prop() expanded = false
  @Prop() content = ""

  toggleExpand() {
    this.$emit("clearPanel")
  }
  onClickOutside() {
    this.$emit("clickOutside")
  }
  async deleteNotification(notification: Notification) {
    const notificationIndexInStore = state.notifications.items.findIndex(
      (notif) => notif.id == notification.id
    )
    state.notifications.items.splice(notificationIndexInStore, 1)
    await notification.delete()
    notification.seen = true
  }
  get currentPanel() {
    return this.content ? `panel-${this.content}` : false
  }
}

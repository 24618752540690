









import { Component, Vue, Prop } from "vue-property-decorator"
import OcularRating from "~/components/OcularRating/OcularRating.vue"

@Component({
  components: {
    OcularRating,
  },
})
export default class OcularRatingAdvise extends Vue {
  @Prop({ required: true }) value!: number
  @Prop({ default: false }) readonly!: boolean | ""

  get ratingTooltips() {
    return [
      "1/5 Mauvaise relation",
      "2/5 Relation moyenne",
      "3/5 Bonne relation",
      "4/5 Très bonne relation",
      "5/5 Excellente relation",
    ]
  }

  input(value: number) {
    this.$emit("input", value)
  }
}

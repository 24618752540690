









import { Component, Vue } from "vue-property-decorator"
import House from "~/models/House"
import OcularPrompt from "~/components/OcularPrompt.vue"
import snackbar from "~/plugins/snackbar"
import OcularDialog from "~/components/OcularDialog.vue"

@Component({
  components: {
    OcularPrompt,
  },
})
export default class PromptDeactivateForCustomer extends Vue {
  house: House | null = null

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  open(house: House) {
    this.house = house
    this.dialog.open = true
  }

  async confirm() {
    if (this.house) {
      this.house.selected_house_active = false
      await this.house.save()
      snackbar({
        content: `Le bien a été désactivé pour le client`,
        color: "success",
      })
    }
  }
}

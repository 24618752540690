





















































































































































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Deal from "~/models/Deal"
import { state } from "@/store"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    OcularDialog,
  },
})
export default class ModalCustomer extends Vue {
  @Prop({ required: true }) deal!: Deal
  @Prop({ default: "Information client" }) title!: string

  get salespeople() {
    return state.salespeople.items.filter(
      (salesperson) =>
        (salesperson.level == "homecatcher" ||
          salesperson.level == "customer_success") &&
        salesperson.discarded_at == null &&
        salesperson.id != this.dealOwner?.id &&
        this.couldUpdate(salesperson.id)
    )
  }
  get currentProfile() {
    return state.profile
  }
  get dealOwner() {
    return state.salespeople.items.find(
      (salesperson) => salesperson.id == this.deal.salesperson_id
    )
  }

  disabled(salespersonId: number) {
    return this.deal?.salesperson_ids?.includes(salespersonId)
      ? "mr-1 mt-1"
      : "mr-1 mt-1 disabled"
  }
  couldUpdate(salespersonId: string | number | null) {
    return (
      this.currentProfile.id === salespersonId ||
      this.deal.salesperson_id === this.currentProfile.id ||
      this.currentProfile.level === "admin"
    )
  }
  async addHcToDeal(salespersonId: number, salespersonName: string) {
    if (this.deal && this.deal.salesperson_ids) {
      if (this.deal.salesperson_ids.includes(salespersonId)) {
        var index = this.deal.salesperson_ids.indexOf(salespersonId)
        if (index !== -1) this.deal.salesperson_ids.splice(index, 1)
        await this.deal.save()
        snackbar({
          color: "success",
          content: `${salespersonName} a été retiré du deal ${this.deal.title}!`,
        })
      } else {
        this.deal.salesperson_ids.push(salespersonId)
        await this.deal.save()
        snackbar({
          color: "success",
          content: `${salespersonName} a été ajouté au deal ${this.deal.title}!`,
        })
      }
    }
  }
}

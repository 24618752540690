









import Vue from "vue"

import DefaultLayout from "@/layouts/Default.vue"
import NoWrapLayout from "@/layouts/NoWrapLayout.vue"
import GoogleAuthLayout from "@/layouts/GoogleAuthLayout.vue"

export default Vue.extend({
  name: "App",
  components: {
    DefaultLayout,
    NoWrapLayout,
    GoogleAuthLayout,
  },
  computed: {
    layout() {
      const { layout } = this.$route.meta
      if (layout || layout === undefined)
        return (layout || "Default") + "Layout"
      return ""
    },
  },
})

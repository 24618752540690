import EventBus from "~/utils/EventBus"

export interface SnackbarOptions {
  color?: string
  icon?: string
  component?: string
  content?: string
  closable?: boolean
  timeout?: number
}

export class DefaultSnackbarOptions implements SnackbarOptions {
  color = "primary"
  icon = ""
  closable = true
  timeout = 4000
}

export default function snackbar(options: SnackbarOptions) {
  EventBus.emit("showSnackbar", options)
}

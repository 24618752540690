



























import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import HouseMap from "../HouseMap.vue"
import HouseCardDealStatusEmoji from "./HouseCardDealStatusEmoji.vue"
import HouseCardEditableTitle from "./HouseCardEditableTitle.vue"
import HouseCardExpandableDescription from "./HouseCardExpandableDescription.vue"
import HouseCardImageCarousel from "./HouseCardImageCarousel.vue"
import HouseCardLocation from "./HouseCardLocation.vue"
import HouseCharacteristics from "./HouseCharacteristics.vue"
import HousePrices from "./HousePrices.vue"

@Component({
  components: {
    HouseCardImageCarousel,
    HouseCardEditableTitle,
    HouseCardLocation,
    HouseCardDealStatusEmoji,
    HousePrices,
    HouseCharacteristics,
    HouseCardExpandableDescription,
    HouseMap,
  },
})
export default class HouseCardSummary extends Vue {
  @Prop({ required: false }) house!: House
  descriptionOpened = false

  descriptionOpenedChanged(opened: boolean) {
    this.descriptionOpened = opened
  }
}

























import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import HouseSearch from "~/models/HouseSearch"
import HouseCardFull from "./HouseCardFull.vue"
import HouseCardGrid from "./HouseCardGrid.vue"
import HouseCardInfos from "./HouseCardInfos.vue"
import HouseCardList from "./HouseCardList.vue"
import HouseCardSummary from "./HouseCardSummary.vue"

const LAYOUTS = {
  grid: "HouseCardGrid",
  list: "HouseCardList",
  full: "HouseCardFull",
  infos: "HouseCardInfos",
  summary: "HouseCardSummary",
}

@Component({
  components: {
    HouseCardGrid,
    HouseCardList,
    HouseCardFull,
    HouseCardInfos,
    HouseCardSummary,
  },
})
export default class HouseCard extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: null }) houseSearch!: HouseSearch
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: true }) isVisible!: boolean
  @Prop({ default: "list" }) display!:
    | "list"
    | "grid"
    | "full"
    | "infos"
    | "summary"
  isHover = false

  get component() {
    return LAYOUTS[this.display]
  }

  openFullHouseCard(tab = "description") {
    this.$emit("open-full-house", this.house, tab)
  }
}

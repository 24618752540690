




























































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import HouseSearch from "~/models/HouseSearch"
import { departments, cities, Hit, Tag, TagItem, tags } from "~/config/algolia"
import { state } from "@/store"

interface Tags {
  selected: Hit<Tag>[]
  algorithm: Hit<Tag>[]
  rejected: Hit<Tag>[]
}

@Component({})
export default class SearchActiveTags extends Vue {
  @Prop() houseSearch!: HouseSearch
  @Prop({ default: false }) expandable!: boolean
  hasOverflow = false
  expanded = false
  districts: Hit<Tag>[] = []
  cities: TagItem[] = []
  departments: TagItem[] = []
  tags: Tags = {
    selected: [],
    algorithm: [],
    rejected: [],
  }

  async mounted() {
    await this.fetchTags()
  }

  get allTags() {
    return [
      ...this.tags.selected,
      ...this.tags.algorithm,
      ...this.tags.rejected,
    ]
  }

  get storedTags() {
    return [...state.tags.main, ...state.tags.secondary]
  }

  get infosInSearch() {
    return (
      this.houseSearch.scrap_names.length > 0 ||
      this.houseSearch.photos_count ||
      this.houseSearch.show_unactive_houses ||
      this.houseSearch.show_only_unactive_houses ||
      this.houseSearch.search_in_deals
    )
  }
  // Fetchers
  async fetchTags() {
    await this.fetchDistricts()
    await this.fetchSelectedTags()
    await this.fetchAlgorithmTags()
    await this.fetchRejectedTags()
    await this.fetchCities()
    await this.fetchDepartments()
  }
  @Watch("houseSearch.quartier_ids")
  async fetchDistricts() {
    this.districts = await this.extractTagListFromIds(
      this.houseSearch.quartier_ids,
      state.tags.districts
    )
  }
  @Watch("houseSearch.city_ids")
  async fetchCities() {
    this.cities = []
    const cityIds = this.houseSearch.city_ids.map((id) => id.toString())
    if (cityIds.length > 0) {
      cities.getObjects(cityIds).then((response) => {
        const hits = response.results as Hit<Tag>[]
        this.cities = hits.map((hit) => {
          return {
            id: hit.id,
            name: hit.name,
          } as TagItem
        })
      })
    }
  }
  @Watch("houseSearch.department_ids")
  async fetchDepartments() {
    this.departments = []
    const departmentIds = this.houseSearch.department_ids.map((id) =>
      id.toString()
    )
    if (departmentIds.length > 0) {
      departments.getObjects(departmentIds).then((response) => {
        const hits = response.results as Hit<Tag>[]
        this.departments = hits.map((hit) => {
          return {
            id: hit.id,
            name: hit.name,
          } as TagItem
        })
      })
    }
  }
  @Watch("houseSearch.selected_tag_ids")
  async fetchSelectedTags() {
    this.tags.selected = await this.extractTagListFromIds(
      this.houseSearch.selected_tag_ids,
      this.storedTags
    )
  }
  @Watch("houseSearch.algorithm_tag_ids")
  async fetchAlgorithmTags() {
    this.tags.algorithm = await this.extractTagListFromIds(
      this.houseSearch.algorithm_tag_ids,
      this.storedTags
    )
  }
  @Watch("houseSearch.rejected_tag_ids")
  async fetchRejectedTags() {
    this.tags.rejected = await this.extractTagListFromIds(
      this.houseSearch.rejected_tag_ids,
      this.storedTags
    )
  }

  @Watch("houseSearch")
  @Watch("districts")
  @Watch("cities")
  @Watch("departments")
  checkOverflow() {
    if (this.$refs.tagContainer) {
      const tagContainer = this.$refs.tagContainer as HTMLElement
      this.hasOverflow = tagContainer.scrollHeight > 85
    }
  }

  async extractTagListFromIds(ids: number[], list: Hit<Tag>[]) {
    const tagList: Hit<Tag>[] = []
    const missingTagIds: number[] = []

    ids.forEach((id) => {
      const tag = list.find((district) => district.id === id)
      if (tag) tagList.push(tag)
      else missingTagIds.push(id)
    })
    if (missingTagIds.length > 0) {
      const response = await tags.getObjects(
        missingTagIds.map((id) => id.toString())
      )
      const hits = response.results as Hit<Tag>[]
      hits.forEach((hit) => tagList.push(hit))
    }
    return tagList
  }

  toggleExpand() {
    if (!this.expandable) return
    this.expanded = !this.expanded
  }
}

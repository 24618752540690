





























import { Vue, Component, Prop } from "vue-property-decorator"
import OcularIcon from "~/components/OcularIcon.vue"

@Component({
  components: {
    OcularIcon,
  },
})
export default class Column extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: false }) icon!: string
  @Prop({ required: false, default: true }) collapsable!: boolean
  @Prop({ required: false, default: false }) initialCollapsed!: boolean
  @Prop({ required: false, default: false }) autoFit!: boolean
  @Prop({ required: false, default: null }) maxWidth!: number
  @Prop({ required: false, default: null }) minWidth!: number

  get cardStyle() {
    let style = ""
    if (this.maxWidth) {
      style = `${style}max-width: ${this.maxWidth}px;`
    }
    if (this.minWidth && !this.collapsed) {
      style = `${style}min-width: ${this.minWidth}px;`
    }
    return style
  }

  collapsed = this.initialCollapsed

  collapsedIcon = ["mdi-arrow-expand-left", "mdi-arrow-expand-right"]

  toggleCollapse() {
    this.collapsed = !this.collapsed
  }
}

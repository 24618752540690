


















import { Component, Vue, Prop } from "vue-property-decorator"
import OcularIcon from "~/components/OcularIcon.vue"
import { AdPicture } from "~/models/ClassifiedAd"

@Component({
  components: {
    OcularIcon,
  },
})
export default class Photo extends Vue {
  @Prop({ required: true }) picture!: AdPicture
  @Prop({ required: true }) selection!: AdPicture[]

  isHover = false

  // ComputedAttributes
  get selected() {
    return this.selection.includes(this.picture)
  }

  get overlayClass() {
    return {
      overlay: true,
      remove: !!this.selected,
      add: !this.selected,
    }
  }

  get style() {
    return {
      backgroundImage: `url('${this.picture.big}')`,
    }
  }

  // Methods
  remove() {
    this.$emit("remove")
  }

  add() {
    this.$emit("add")
  }
}



























































































import { Vue, Component, Prop } from "vue-property-decorator"
import Visit from "~/models/Visit"
import { translate } from "@/store"
import Agent from "~/models/Agent"
import OcularIcon from "~/components/OcularIcon.vue"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import HouseCard from "@/components/house/HouseCard/HouseCard.vue"
import HouseCardImageCarousel from "@/components/house/HouseCard/HouseCardImageCarousel.vue"
import House from "~/models/House"

@Component({
  components: {
    HouseCard,
    OcularIcon,
    AgentItem,
    HouseCardImageCarousel,
  },
})
export default class VisitBoardCard extends Vue {
  @Prop({ required: true }) visit!: Visit

  get houseVisited() {
    return new House(this.visit.house)
  }
  get startAt() {
    if (this.visit.start_at) return new Date(this.visit.start_at)
    return null
  }
  get endAt() {
    if (this.visit.end_at) return new Date(this.visit.end_at)
    return null
  }
  get visitType() {
    if (this.visit.visit_type)
      return translate("visit_type", this.visit.visit_type)
    return null
  }
  get visitAgent() {
    const agent = new Agent()
    return agent.assign(this.visit.agent)
  }
  get footerColor() {
    const colors = {
      planned: "--background-medium",
      done: "--secondary",
      canceled: "--primary",
    }
    return colors[this.visit.status] || "--white"
  }

  onVisitCardClick() {
    if (this.visit.house) {
      const { dealId } = this.$route.params
      const houseId = this.visit.house.id
      const visitId = this.visit.id
      this.$router.push({
        path: `/deals/${dealId}/visited/house/${houseId}/visits/${visitId}`,
      })
    }
  }
}

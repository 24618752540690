






















































































import ColumnOfferList from "@/components/visits/Board/Columns/ColumnOfferList.vue"
import { Component, Vue } from "vue-property-decorator"
import Offer, { OfferStatus, readonlyOfferStatus } from "~/models/Offer"
import OffersCol from "~/collections/Offers"
import EventBus from "~/utils/EventBus"
import OfferRetract from "@/components/offers/OfferRetract/OfferRetract.vue"
import OfferRefuse from "@/components/offers/OfferRefuse/OfferRefuse.vue"
import OfferAccept from "@/components/offers/OfferAccept/OfferAccept.vue"
import { state } from "@/store"

const statusGrouping = {
  draft: "pending",
  pending_get_accept: "pending",
  signing: "pending",
  pending_send: "pending",
  sent: "pending",
  retracted: "retracted",
  accepted: "accepted",
  accepted_belled: "accepted",
  refused: "refused",
  archived: "archived",
}

@Component({
  components: {
    ColumnOfferList,
    OfferAccept,
    OfferRefuse,
    OfferRetract,
  },
})
export default class Offers extends Vue {
  loading = true
  columnWidth = 355
  offers = new OffersCol()
  changingOffer: Offer | null = null

  actionByOfferStatus = {
    accepted: this.actions.accept,
    refused: this.actions.refuse,
    retracted: this.actions.retract,
    archived: this.actions.archive,
    draft: null,
    pending_get_accept: null,
    signing: null,
    pending_send: null,
    sent: null,
    accepted_belled: null,
  }

  /**
   * COMPUTED PROPS
   */
  get houseId() {
    if (this.$route.params.houseId) return parseInt(this.$route.params.houseId)
    return null
  }

  get offersByColumns() {
    const defaultValue = {
      pending: [],
      accepted: [],
      refused: [],
      retracted: [],
      archived: [],
    }
    if (this.offers) {
      return this.offers.items.reduce((prev: any, value: Offer) => {
        prev[statusGrouping[value.status || "draft"]].push(value)
        return prev
      }, defaultValue)
    }
    return defaultValue
  }

  get actions() {
    return {
      accept: () => {
        const modale = this.$refs.offerAccept as OfferAccept
        modale.open()
      },
      refuse: () => {
        const modale = this.$refs.offerRefuse as OfferRefuse
        modale.open()
      },
      retract: () => {
        const modale = this.$refs.offerRetract as OfferRetract
        modale.open()
      },
      archive: () => {
        // TODO : Archive
      },
    }
  }

  /**
   * HOOK
   */
  mounted() {
    this.fetchOffers()
    EventBus.on("dealOffersUpdated", this.fetchOffers)
  }

  destroyed() {
    EventBus.off("dealOffersUpdated")
  }

  offerUpdated(offer: Offer) {
    const idx = this.offers.items.findIndex(
      (currentOffer) => currentOffer.id === offer.id
    )
    this.$set(this.offers.items, idx, offer)
  }

  /**
   * METHODS
   */
  async fetchOffers() {
    this.loading = true
    const dealId = parseInt(this.$route.params.dealId)
    if (dealId > 0) {
      this.offers.deal_id = dealId
      await this.offers.fetch()
      this.loading = false
    }
  }

  cardPositionChanged(e: any, nextState: OfferStatus) {
    // Which new status is always allowed
    const whiteListNewStatus: OfferStatus[] = [] // TODO: Handle retracted + archived

    if (e.added) {
      // Retrieve offer card
      const element = e.added.element
      const elementId = element.id
      const offerIdx = this.offers.items.findIndex(
        (offer) => offer.id === elementId
      )
      if (offerIdx >= 0) {
        // Successfully retrieve the offer
        const offer = this.offers.items[offerIdx]
        const isReadOnlyColumn = !!readonlyOfferStatus.find(
          (s) => s === offer.status
        )
        const whiteListed = !!whiteListNewStatus.find((s) => s === nextState)
        const readOnly =
          (isReadOnlyColumn && !whiteListed) || state.isReadOnlyMode()
        if (!readOnly) {
          this.changingOffer = offer
          const action = this.actionByOfferStatus[nextState]
          if (action) {
            action()
          }
        }
      }
      // Error while retrieving the offer
      else console.warn(`Cannot find offer (id:${elementId}) in `, this.offers)
    }
  }
}

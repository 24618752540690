































































































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HouseDescriptionsViewer extends Vue {
  @Prop({ required: true }) house!: House
  currentDescription = 0

  get descriptions() {
    return this.house.classified_ads || []
  }

  async updateDescription() {
    await this.house.save({
      method: "PUT",
      url: `houses/${this.house.id}`,
      data: {
        house: { description: this.house.description },
      },
    })
  }
}

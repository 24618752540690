











import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class Toolbar extends Vue {
  shadowed = false
  container!: HTMLElement
  @Prop({ default: "auto" }) border!: "auto" | "always" | "never"
  @Prop({ default: false }) dense!: boolean
  @Prop({ default: false }) large!: boolean

  get shadow() {
    return this.border == "always" || (this.border == "auto" && this.shadowed)
  }

  onWheel() {
    this.shadowed = !!document.documentElement.scrollTop
  }

  mounted() {
    addEventListener("wheel", this.onWheel, { passive: true })
    this.onWheel()
  }

  destroyed() {
    removeEventListener("wheel", this.onWheel)
  }
}

import Model from "~/jrdm-mc/Model"
import { toDate, arrayOf } from "~/utils/functionsFilter"
import Agent from "./Agent"

export type AgencyRating = {
  user: {
    id: number
    name: string
    image: string | null
  }
  average: number
}

export default class Agency extends Model {
  static route = "agencies/{id}"
  static requestField = "agency"
  static responseField = "agency"

  id: number | null = null
  brand_id: number | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  name: string | null = null
  category: string | null = null
  assigned_areas: string[] | null = null
  logo: null | string = null
  hubspot_id: number | null = null
  street_address: string | null = null
  city_id: number | null = null
  city_name: string | null = null
  zipcode: string | null = null
  landline_number: string | null = null
  email: string | null = null
  registration_number: number | null = null
  rcs_number: number | null = null
  vat_number: number | null = null
  agents_count: number | null = null
  relation_quality: number | null = null
  agents: Array<Agent> | null = null
  rating: null | number = null
  ratings: AgencyRating[] = []
  verification_count: null | number = null
  individual: null | boolean = null
  visit_count: number | null = null
  offer_count: number | null = null

  static mutations = {
    created_at: toDate,
    updated_at: toDate,
    agents: arrayOf(Agent),
  }

  getSaveRoute() {
    return this.hasId() ? Agency.route : "agencies"
  }
}

import Model from "~/jrdm-mc/Model"
import House from "~/models/House"
import Agency from "~/models/Agency"
import Agent from "~/models/Agent"
import { arrayOf, model, toDate } from "~/utils/functionsFilter"
import Profile from "./Profile"
import Deal from "./Deal"
import axios, { AxiosRequestConfig } from "axios"
import DealDocument, { DealDocumentNature } from "./DealDocument"
import Company from "./Company"
import { format } from "date-fns"

export type OfferEmailContact = {
  name: string
  email: string
}

export type OfferEmail = {
  from: OfferEmailContact
  to: OfferEmailContact[]
  cc: OfferEmailContact[]
  subject: string
  message: string
  documents: DealDocument[]
}

export type OfferStatus =
  | "draft"
  | "pending_get_accept"
  | "signing"
  | "pending_send"
  | "sent"
  | "retracted"
  | "archived"
  | "accepted"
  | "accepted_belled"
  | "refused"

export const readonlyOfferStatus: OfferStatus[] = [
  "retracted",
  "archived",
  "draft",
  "pending_get_accept",
  "pending_send",
  "signing",
]

export type OfferRefusedReason =
  | "competitor_better_funding"
  | "bad_feeling"
  | "bad_negotiation"
  | "agent_relationship"
  | "too_late"
  | "no_response"
  | null

export const STATUS_WITHOUT_RETRACTATION = [
  "draft",
  "pending_get_accept",
  "pending_send",
  "signing",
] as OfferStatus[]

const OFFER_STATUS_CONFIG = {
  draft: {
    icon: "mdi-file-document-edit-outline",
    text: "En cours de rédaction",
    color: "gray-light-3",
    theme: "dark",
  },
  pending_get_accept: {
    icon: "mdi-file-document-alert-outline",
    text: "Prêt sur GetAccept",
    color: "orange",
    theme: "dark",
  },
  signing: {
    icon: "mdi-file-sign",
    text: "En attente du client",
    color: "tertiary",
    theme: "dark",
  },
  pending_send: {
    icon: "mdi-timer-sand",
    text: "Signé par le client",
    color: "orange",
    theme: "dark",
  },
  sent: {
    icon: "Send",
    text: "Envoyée",
    color: "info",
    theme: "dark",
  },
  accepted: {
    icon: "mdi-check",
    text: "Offre acceptée",
    color: "success",
    theme: "dark",
  },
  accepted_belled: {
    icon: "mdi-crown",
    text: "Offre acceptée clochée",
    color: "success",
    theme: "dark",
  },
  refused: {
    icon: "mdi-cancel",
    text: "Offre refusée",
    color: "error",
    theme: "dark",
  },
  retracted: {
    icon: "mdi-delete-outline",
    text: "Offre retractée",
    color: "black",
    theme: "dark",
  },
  archived: {
    icon: "mdi-archive-outline",
    text: "Offre archivée",
    color: "black",
    theme: "dark",
  },
}

export default class Offer extends Model {
  static fetchRoute = "deals/{deal_id}/offers"
  static route = "deals/{deal_id}/houses/{house_id}/offers/{id}"
  static createRoute = "deals/{deal_id}/houses/{house_id}/offers"
  static requestField = "offer"
  static importSignedOfferRoute =
    "deals/{deal_id}/houses/{house_id}/offers/{id}/document_import"
  static getSignedOfferRoute =
    "deals/{deal_id}/houses/{house_id}/offers/{id}/signed"

  id: number | null = null
  title: string | null = null
  deal: Deal | null = null
  deal_id: number | null = null
  house: House = new House()
  house_id: number | null = null
  status: OfferStatus | null = null
  amount: number | null = null
  contribution_amount: number | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  end_validity_at: Date | null = null

  signatory_ids: number[] | null = null
  buyer_street: string | null = null
  buyer_zipcode: string | null = null
  buyer_city: string | null = null

  agent: Agent | null = null
  agency: Agency | null = null
  agent_id: number | null = null
  agency_id: number | null = null
  agent_opinion: string | null = null
  seller_type: "agency" | "individual" | null = null
  seller_full_name: string | null = null
  seller_email: string | null = null
  seller_phone_number: string | null = null

  house_designation: string | null = null

  owner: Profile | null = null

  doc_drive_id: string | null = null
  doc_drive_url: string | null = null
  doc_drive_edit_url: string | null = null
  doc_drive_preview_url: string | null = null
  doc_drive_download_url: string | null = null
  get_accept_document_id: string | null = null

  deal_documents: DealDocument[] = []
  deal_document_ids: number[] = []

  refused_reason: OfferRefusedReason = null
  status_before_retract: OfferStatus | null = null
  status_before_archiving: OfferStatus | null = null

  sale_agreement_signature_at: Date | null = null
  authentic_act_signature_at: Date | null = null
  real_authentic_act_signature_at: Date | null = null
  submarine: boolean | null = null
  fees: number | null = null

  companies: Company[] = []
  company_ids: number[] | null = null

  rating: number | null = null

  saveAttributes() {
    return [
      "status",
      "amount",
      "contribution_amount",
      "created_at",
      "end_validity_at",
      "seller_type",
      "agent_id",
      "agency_id",
      "seller_full_name",
      "seller_email",
      "seller_phone_number",
      "house_designation",
      "doc_drive_id",
      "deal_document_ids",
      "refused_reason",
      "buyer_street",
      "buyer_city",
      "buyer_zipcode",
      "rating",
      "fees",
      "sale_agreement_signature_at",
      "authentic_act_signature_at",
      "real_authentic_act_signature_at",
      "submarine",
      "company_ids",
      "signatories",
    ]
  }

  get formatAttributes(): any {
    return [
      ["end_validity_at", (v: Date) => v.toISOString()],
      [
        "sale_agreement_signature_at",
        (v: Date) => (v ? format(v, "yyyy-MM-dd") : null),
      ],
      [
        "authentic_act_signature_at",
        (v: Date) => (v ? format(v, "yyyy-MM-dd") : null),
      ],
      [
        "real_authentic_act_signature_at",
        (v: Date) => (v ? format(v, "yyyy-MM-dd") : null),
      ],
    ]
  }

  static mutations = {
    agency: model(Agency),
    agent: model(Agent),
    owner: model(Profile),
    deal: model(Deal),
    deal_documents: arrayOf(DealDocument),
    companies: arrayOf(Company),
    created_at: toDate,
    updated_at: toDate,
    end_validity_at: toDate,
    sale_agreement_signature_at: toDate,
    authentic_act_signature_at: toDate,
    real_authentic_act_signature_at: toDate,
  }

  constructor(object?: Record<string, any>) {
    super()
    object && this.assign(object)
  }

  bindDefaultValues(deal: Deal, house: House) {
    this.buyer_street = deal.buyer_street
    this.buyer_city = deal.buyer_city
    this.buyer_zipcode = deal.buyer_zipcode
    this.status = "draft"
    this.house = house
    this.house_id = house.id
    this.deal = deal
    this.deal_id = deal.id
    this.created_at = new Date()
    const endValidity = new Date()
    endValidity.setDate(endValidity.getDate() + 7)
    endValidity.setHours(18, 0, 0, 0)
    this.end_validity_at = endValidity
    this.amount = this.house.price
    this.submarine = false
  }

  async save(options: AxiosRequestConfig = {}) {
    return await super.save({
      ...options,
      url: this.hasId()
        ? `deals/${this.deal_id}/houses/${this.house_id}/offers/${this.id}`
        : `deals/${this.deal_id}/houses/${this.house_id}/offers`,
    })
  }

  get includeTaxesText() {
    return this.seller_type === "agency" ? "F.A.I." : "net vendeur"
  }

  get statusConfig() {
    if (this.status) {
      return OFFER_STATUS_CONFIG[this.status]
    }
    return null
  }

  get statusBeforeRetractText() {
    if (this.status_before_retract)
      return OFFER_STATUS_CONFIG[this.status_before_retract].text
    return null
  }

  get statusBeforeArchivingText() {
    if (this.status_before_archiving)
      return OFFER_STATUS_CONFIG[this.status_before_archiving].text
    return null
  }

  get isAtPrice() {
    return this.amount === this.house.price
  }

  get multipleSignatories() {
    return (this.deal && this.deal.signatories.length > 1) || false
  }

  isOfferNeedRetractation() {
    return (
      STATUS_WITHOUT_RETRACTATION.findIndex(
        (status) => status === this.status
      ) === -1
    )
  }

  buyerFullAddress() {
    return [this.buyer_street, this.buyer_zipcode, this.buyer_city]
      .filter((component) => component?.length)
      .join(" ")
  }

  async createGetAcceptDocument(
    signer_id: number,
    subject: string,
    message: string
  ) {
    const options: AxiosRequestConfig = {}
    options.method ||= "PUT"
    options.url ||= this.renderRoute(this.getStatic("route"))
    options.data ||= {
      status: "pending_get_accept",
      signer_id,
      message,
      subject,
    }
    const response = await axios(options)
    return response.data
  }

  async sendOfferToSignToCustomer(
    subject: string,
    message: string,
    should_send = false
  ) {
    const options: AxiosRequestConfig = {}
    options.method ||= "PUT"
    options.url ||= this.renderRoute(this.getStatic("route"))
    options.data ||= {
      status: "signing",
      message: message,
      subject: subject,
      should_send: should_send,
    }
    const response = await axios(options)
    return response.data
  }

  async sendGetAcceptDocument() {
    const options: AxiosRequestConfig = {}
    options.method ||= "PUT"
    options.url ||= this.renderRoute(this.getStatic("route"))
    options.data ||= {
      status: "signing",
    }
    const response = await axios(options)
    return response.data
  }

  async importOfferFile(file: File, nature: DealDocumentNature) {
    const options: AxiosRequestConfig = {}
    options.method ||= "POST"
    ;(options.headers ||= { "Content-Type": "multipart/form-data" }),
      (options.url ||= this.renderRoute(
        this.getStatic("importSignedOfferRoute")
      ))
    options.data ||= new FormData()
    options.data.append("file", file)
    options.data.append("nature", nature)
    const response = await axios(options)
    return response.data
  }

  async emailToSeller(
    email: string,
    subject: string,
    message: string,
    includeBuyerEmail: boolean,
    should_send = false
  ) {
    const options: AxiosRequestConfig = {}
    options.method ||= "PUT"
    options.url ||= this.renderRoute(this.getStatic("route"))
    options.data ||= {
      seller_email: email,
      status: "sent",
      subject: subject,
      message: message,
      include_buyer_email: includeBuyerEmail,
      should_send,
    }
    const response = await axios(options)
    return response.data
  }

  async createCounterOffer(counterOffer: Offer) {
    const options: AxiosRequestConfig = {}
    options.method ||= "POST"
    options.url ||= this.renderRoute(this.getStatic("createRoute"))
    options.data ||= {
      offer_id: this.id,
      created_at: counterOffer.created_at,
      end_validity_at: counterOffer.end_validity_at,
      amount: counterOffer.amount,
      contribution_amount: counterOffer.contribution_amount,
    }
    const response = await axios(options)
    return response.data
  }

  async downloadSignedOffer() {
    const options: AxiosRequestConfig = {}
    options.method ||= "GET"
    options.url ||= this.renderRoute(this.getStatic("getSignedOfferRoute"))

    const response = await axios(options)
    return response.data
  }
}

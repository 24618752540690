var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OcularDialog',{attrs:{"width":"840","actions":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"content",fn:function(){return [_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Lien vers Hubspot :")]),_c('a',{attrs:{"href":_vm.deal.hubspotUrl(),"target":"_blank"}},[_vm._v(_vm._s(_vm.deal.hubspotUrl()))])]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Utilisateurs :")]),_vm._l((_vm.deal.customers),function(customer,index){return _c('v-row',{key:index,staticClass:"pb-4 align-center"},[_c('div',{staticClass:"col-12 pb-0"},[_vm._v(_vm._s(customer.name))]),_c('v-text-field',{staticClass:"col-md-6 pb-0",attrs:{"value":customer.email,"hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-on-focus":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(customer.email),expression:"customer.email",arg:"copy"}],staticClass:"pointer"},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copié !")])])]},proxy:true}],null,true)}),_c('v-text-field',{staticClass:"col-md-6 pb-0",attrs:{"value":customer.authentication_uri
                ? customer.authentication_uri.replace(
                    'herokuapp',
                    'jerevedunemaison'
                  )
                : '',"hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                      customer.authentication_uri
                        ? customer.authentication_uri.replace(
                            'herokuapp',
                            'jerevedunemaison'
                          )
                        : ''
                    ),expression:"\n                      customer.authentication_uri\n                        ? customer.authentication_uri.replace(\n                            'herokuapp',\n                            'jerevedunemaison'\n                          )\n                        : ''\n                    ",arg:"copy"}],staticClass:"pointer"},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copié !")])])]},proxy:true}],null,true)})],1)})],2),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("HC/CS associé(s) au client :")]),(_vm.dealOwner)?_c('v-tooltip',{key:_vm.dealOwner.id,attrs:{"bottom":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-1 mt-1",attrs:{"size":"35","color":"primary pointer"}},'v-avatar',attrs,false),on),[(!_vm.dealOwner.avatar.url && !_vm.dealOwner.thumb)?_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm._f("initials")(_vm.dealOwner.name)))]):_c('img',{attrs:{"src":_vm.dealOwner.avatar.thumb.url || _vm.dealOwner.avatar.url,"alt":""}})])]}}],null,false,972220630)},[_c('span',[_vm._v(_vm._s(_vm.dealOwner.name))])]):_vm._e(),_vm._l((_vm.salespeople),function(salesperson){return _c('v-tooltip',{key:salesperson.id,attrs:{"bottom":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',{class:_vm.disabled(salesperson.id),attrs:{"icon":""},on:{"click":function($event){return _vm.addHcToDeal(salesperson.id, salesperson.name)}}},[_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"35","color":"primary pointer"}},'v-avatar',attrs,false),on),[(!salesperson.avatar.url && !salesperson.thumb)?_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm._f("initials")(salesperson.name)))]):_c('img',{attrs:{"src":salesperson.avatar.thumb.url || salesperson.avatar.url,"alt":""}})])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(salesperson.name))])])})],2)],1)]},proxy:true}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
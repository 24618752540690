import { render, staticRenderFns } from "./OcularIcon.vue?vue&type=template&id=afd49a3a&"
import script from "./OcularIcon.vue?vue&type=script&lang=ts&"
export * from "./OcularIcon.vue?vue&type=script&lang=ts&"
import style0 from "./OcularIcon.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VIcon})

import Collection from "../jrdm-mc/Collection"
import HouseSearchResult from "../models/HouseSearchResult"

export default class HouseSearchResults extends Collection<HouseSearchResult> {
  static route =
    "deals/{deal_id}/house-searches/{house_search_id}/results?page={page}"
  static responseField = "results"

  deal_id: number | null = null
  house_search_id: number | null = null
  limit = 50
  total_count = 0
  unseen_count = 0
  page = 1

  model() {
    return HouseSearchResult
  }

  constructor(object?: Record<string, any>) {
    super()
    if (object) this.assign(object)
  }

  get pageCount() {
    return ~~(this.total_count / this.limit) + 1
  }
}

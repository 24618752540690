import Model from "~/jrdm-mc/Model"
import Company from "./Company"

export default class Partner extends Model {
  id = 0
  created_at: string | null = null
  updated_at: string | null = null
  first_name: string | null = null
  last_name: string | null = null
  email: string | null = null
  company: Company | null = null
  phone_number: string | null = null
  company_id: number | null = null

  get fullname() {
    return [this.first_name, this.last_name?.toUpperCase()]
      .filter((i) => i)
      .join(" ")
  }
}




































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import LatLng from "~/types/LatLng"

@Component({
  components: {},
})
export default class HouseMap extends Vue {
  @Prop({ required: true }) house!: House

  infoWindowPos: LatLng | null = null
  infoWinOpen = false
  infoOptions = { content: "" }

  get latitude() {
    return this.house.latitude || 0
  }
  get longitude() {
    return this.house.longitude || 0
  }
  get houseTitle() {
    return this.house.address ? this.house.address : this.house.city_name
  }
  get markerInfoPosition() {
    return { lat: this.latitude + 0.004, lng: this.longitude }
  }
  get housePosition() {
    return { lat: this.latitude, lng: this.longitude }
  }
  get address() {
    return this.house.address
  }
  get polygons() {
    return this.house.localisation
  }
  get polygoneOptions() {
    return { fillColor: "red", fillOpacity: 0.6, strokeOpacity: 0 }
  }
  get mapOptions() {
    return {
      mapTypeControl: false,
      controlSize: 25,
      zoomControlOptions: { position: 1 },
    }
  }
  get isMarker() {
    return this.house.localisation == null || this.house.address
  }

  displayLabel(label: string, position: LatLng | null) {
    this.infoWindowPos = position
    this.infoOptions.content = label
    this.infoWinOpen = true
  }
}


































import { translate } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HouseCharacteristics extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: false }) light!: boolean

  get characteristicColor() {
    return {
      living_area: () => null,
      room_count: () => null,
      bedroom_count: () => null,
      estate_area: () => null,
      ground_floor: () => null,
      floor: () => null,
      last_floor: () => null,
      elevator: () => null,
      opposite: () => null,
      dpe_class: () => {
        const dpeColors = {
          A: "rgb(76, 141, 79)",
          B: "rgb(104, 165, 77)",
          C: "rgb(130, 178, 109)",
          D: "rgb(235, 231, 45)",
          E: "rgb(220, 179, 38)",
          F: "rgb(206, 130, 54)",
          G: "rgb(179, 61, 34)",
        }
        if (this.house.dpe_class && this.house.dpe_class in dpeColors) {
          return dpeColors[this.house.dpe_class]
        }
      },
      formatted_exposition: () => null,
      brightness: () => null,
      calm: () => null,
      condition: () => null,
    }
  }

  get characteristicTextColor() {
    return {
      living_area: () => null,
      room_count: () => null,
      bedroom_count: () => null,
      estate_area: () => null,
      ground_floor: () => null,
      floor: () => null,
      last_floor: () => null,
      elevator: () => null,
      opposite: () => null,
      dpe_class: () => "white",
      formatted_exposition: () => null,
      brightness: () => null,
      calm: () => null,
      condition: () => null,
    }
  }

  get characteristicIcon() {
    return {
      living_area: () => "Surface",
      room_count: () => "Room",
      bedroom_count: () => "Bedroom",
      estate_area: () => "mdi-terrain",
      ground_floor: () => "Floor",
      floor: () => "Floor",
      last_floor: () => "Floor",
      elevator: () => "Elevator",
      opposite: () => "mdi-window-closed-variant",
      dpe_class: () => "mdi-chart-box-outline",
      formatted_exposition: () => "Luminous",
      brightness: () => "Luminous",
      calm: () => "Calm",
      condition: () => "Refreshment",
    }
  }
  get characteristicText() {
    return {
      living_area: (value: number) => value + " m²",
      room_count: (value: number) => value,
      bedroom_count: (value: number) => value,
      estate_area: (value: number) => value + " m²",
      ground_floor: () => "RDC",
      floor: (value: number) => value + (value == 1 ? "er" : "e"),
      last_floor: () => "Dernier étage",
      elevator: () => "",
      opposite: (value: string) => translate("opposite", value),
      dpe_class: (value: string) => translate("dpe_class", value),
      formatted_exposition: (value: string) => value,
      brightness: (value: string) => translate("brightness", value),
      calm: (value: string) => translate("calm", value),
      condition: (value: string) => translate("condition", value),
    }
  }
  get characteristicTip() {
    return {
      living_area: () => "Surface",
      room_count: () => "Pièces",
      bedroom_count: () => "Chambres",
      estate_area: () => "Surface terrain",
      ground_floor: () => "Rez-de-chaussée",
      floor: (value: number) => value + (value == 1 ? "er" : "e") + " étage",
      last_floor: () => "Dernier étage",
      elevator: () => "Avec ascenseur",
      opposite: () => "Vis-à-vis",
      dpe_class: () => "DPE",
      formatted_exposition: () => "Exposition",
      brightness: () => "Luminosité",
      calm: () => "Calme",
      condition: () => "État du bien",
    }
  }
  get characteristics() {
    return this.house
      .characteristics()
      .filter((characteristic) => characteristic[1] != false)
  }
}

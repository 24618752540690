










import { Component, Vue } from "vue-property-decorator"
// @ts-ignore (no declaration files)
import vLightbox from "vue-image-lightbox"
import EventBus from "~/utils/EventBus"

export type Media = {
  big?: string
  src?: string
  thumb: string
}

@Component({
  components: {
    vLightbox,
  },
})
export default class OcularLightbox extends Vue {
  medias: Array<Media> = [{ thumb: "", src: "" }]
  index = 0

  openLightbox(items: Array<Media>, index: number) {
    this.medias = items
    this.lightbox.showImage(index)
  }

  get lightbox() {
    return this.$refs.lightbox as any
  }

  get mediasFormatted(): Array<{ src: string; thumb: string }> {
    return this.medias.map((el) => {
      return {
        src: el.big || el.thumb,
        thumb: el.big || el.thumb,
      }
    })
  }

  close() {
    EventBus.emit("closeLightbox")
  }
}






























































import { Vue, Prop, Component } from "vue-property-decorator"
import House from "~/models/House"
import snackbar from "~/plugins/snackbar"
import EventBus from "~/utils/EventBus"

@Component({})
export default class HouseTimeline extends Vue {
  @Prop({ required: true }) house!: House

  get timelineSteps() {
    return [
      {
        icon: "mdi-checkbox-marked-circle-outline",
        active: !!this.house.deal_status,
        tooltipText: this.house.deal_status ? "Sélectionné" : "Non sélectionné",
        tooltipActionText: this.house.deal_status
          ? "Sélectionné"
          : "Sélectionner",
        tooltipDate: this.house.selected_house_created_at
          ? `le ${this.$options.filters?.date(
              this.house.selected_house_created_at
            )}`
          : "",
        action: async () => {
          await this.house.putWaiting()
          EventBus.emit("reloadCurrentDeal")
          snackbar({
            color: "success",
            content: `Le bien a été sélectionné avec succès !`,
          })
        },
      },
      {
        icon: "Send",
        active: this.house.isSent(),
        tooltipText: this.house.isSent() ? "Envoyé" : "Non envoyé",
        tooltipActionText: this.house.isSent() ? "Envoyé" : "Envoyer",
        tooltipDate: this.house.sent_at
          ? `le ${this.$options.filters?.date(this.house.sent_at)}`
          : "",
        action: () => {
          this.$emit("executeAction", "sendHouseToCustomer")
        },
      },
      {
        icon: "mdi-account-supervisor-circle-outline",
        active: this.house.wasVisited,
        tooltipText: this.house.wasVisited ? "Visité" : "Non visité",
        tooltipActionText:
          this.house.visit_count && this.house.visit_count > 0
            ? `${this.house.visit_count} visite${
                this.house.visit_count > 1 ? "s" : ""
              } déjà programmée${
                this.house.visit_count > 1 ? "s" : ""
              } sur ce client`
            : "Programmer une première visite",
        tooltipDate: "",
        action: () => {
          EventBus.emit("visitHouse", this.house)
        },
      },
      {
        icon: "mdi-file-document-multiple-outline",
        active: this.house.gotOffer,
        tooltipText: this.house.gotOffer
          ? "Offre déjà rédigée"
          : "Aucune offre",
        tooltipActionText:
          this.house.offer_count && this.house.offer_count > 0
            ? `${this.house.offer_count} offre${
                this.house.offer_count > 1 ? "s" : ""
              } sur ce client`
            : "Faire une première offre",
        action: () => {
          EventBus.emit("offerHouse", this.house)
        },
      },
    ]
  }

  get timeLine() {
    if (this.house.isInClientFolder()) {
      if (!this.house.isSent()) {
        return [this.timelineSteps[1]]
      }
      return [this.timelineSteps[2], this.timelineSteps[3]]
    }
    return [this.timelineSteps[0]]
  }

  get houseState() {
    if (this.house.isSent()) {
      return this.timelineSteps[1]
    } else if (this.house.deal_status) {
      return this.timelineSteps[0]
    }
    return null
  }
}

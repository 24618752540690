/**
 * Store the most used tags from Algolia
 * so that we do not have to fetch them
 * repetively.
 */
import { tags, Hit, Tag } from "~/config/algolia"
import { SectorsConfig } from "~/config/sectors"

type Tags = {
  main: Hit<Tag>[]
  secondary: Hit<Tag>[]
  districts: Hit<Tag>[]
}
type Callback = (tags: Tags) => void

const onLoaded: Array<Callback> = []
let loaded = false

export type TagCategory = "main" | "secondary" | "districts"

export default new (class StoreTags implements Tags {
  main: Hit<Tag>[] = []
  secondary: Hit<Tag>[] = []
  districts: Hit<Tag>[] = []
  scraps: Hit<Tag>[] = []
  other: Hit<Tag>[] = []

  async load() {
    const sectorsSubCategoryIds = SectorsConfig.map(
      (s) => s.sub_category_id
    ).filter((s) => s)
    try {
      const [mainTagsResponse, secondaryTagsResponse, districtsResponse] =
        await Promise.all([
          tags.search("*", { filters: `priority = 0`, hitsPerPage: 1000 }),
          tags.search("*", { filters: `priority = 1`, hitsPerPage: 1000 }),
          tags.search("*", {
            filters: `is_visible = 1 AND (${sectorsSubCategoryIds
              .map((id) => `sub_category_id = ${id}`)
              .join(" OR ")})`,
            hitsPerPage: 1000,
          }),
        ])

      this.main = mainTagsResponse.hits as Hit<Tag>[]
      this.secondary = secondaryTagsResponse.hits as Hit<Tag>[]
      this.districts = districtsResponse.hits.reverse() as Hit<Tag>[]

      loaded = true
      onLoaded.forEach((callback) => callback(this))
      onLoaded.length = 0
    } catch (error) {
      // eslint-disable-next-line
      console.warn(`Error loading tags from Algolia :`, error)
      loaded = false
    }
    return loaded
  }

  onceLoaded(callback: Callback) {
    if (loaded) callback(this)
    else onLoaded.push(callback)
  }
})()

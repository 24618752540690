














import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import OcularRatingAdvise from "~/components/OcularRating/OcularRatingAdvise.vue"

@Component({
  components: {
    OcularRatingAdvise,
  },
})
export default class AgentInterationRating extends Vue {
  @Prop({ required: false, default: null }) value!: number
  @Prop({ required: false, default: false }) required!: boolean
  interactionRating: number | null = this.value || null

  // Rules
  notEmptyRating = (value: number | null) => !!value || "Champ requis"

  @Watch("input")
  rateChanged(value: number | null) {
    this.interactionRating = value
    this.$emit("input", this.interactionRating)
  }
}

















































import { Vue, Component, Prop } from "vue-property-decorator"
import Column from "@/components/visits/Board/Columns/Column.vue"
import VisitBoardCard from "@/components/visits/Board/VisitBoardCard.vue"
import draggable from "vuedraggable"

@Component({
  components: { BoardColumn: Column, BoardVisit: VisitBoardCard, draggable },
})
export default class ColumnVisitList extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: false }) icon!: string
  @Prop({ required: false, default: false }) autoFit!: boolean
  @Prop({ required: false, default: null }) dropNextState!:
    | "done"
    | "planned"
    | null
  @Prop({ required: false, default: false }) loading!: boolean
  @Prop({ required: true, default: () => [] }) visits!: []
  @Prop({ required: false, default: false }) initialCollapsed!: boolean
  @Prop({ required: false, default: "Aucun résultat" }) noResultText!: string
  @Prop({ required: false, default: null }) maxWidth!: number

  cardPositionChanged(e: any) {
    if (this.dropNextState)
      this.$emit("card-position-changed", e, this.dropNextState)
  }
}

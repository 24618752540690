import Model from "~/jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"

export default class ActionTeam extends Model {
  id: number | null = null
  what: string | null = null
  who: string | null = null
  when: Date | null = null
  status: string | null = null
  agency_id: number | null = null
  agent_id: number | null = null

  static mutations = {
    when: toDate,
  }
}




















import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HouseCardLocation extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: false }) withIcon!: boolean
}

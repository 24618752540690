
import OcularDialog from "./OcularDialog.vue"
import { Component, Prop } from "vue-property-decorator"

/**
 * An OcularPrompt is similar to an OcularDialog, except for small style changes :
 * - the title is centered
 * - the action buttons are centered
 * OcularPrompt is preferred when asking quick Yes/No questions.
 */
@Component({})
export default class OcularPrompt extends OcularDialog {
  @Prop({ default: "Oui" }) textOk!: string
  @Prop({ default: "Non" }) textCancel!: string
}




















import { Component, Vue } from "vue-property-decorator"
import OcularPrompt from "~/components/OcularPrompt.vue"
import { state } from "@/store"

@Component({
  components: {
    OcularPrompt,
  },
})
export default class PromptIgnorePendingMessage extends Vue {
  nextPage: (() => void) | null = null

  open(nextPage: (() => void) | null) {
    this.nextPage = nextPage
    // @ts-ignore
    const dialog = this.$refs.dialog as OcularPrompt
    dialog.open = true
  }

  confirm() {
    state.currentMessage = ""
    if (this.nextPage) {
      this.nextPage()
    }
  }

  cancel() {
    this.$emit("cancel")
  }
}

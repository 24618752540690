import Model from "~/jrdm-mc/Model"

export type CompanyCategory =
  | "work"
  | "architect_decorator"
  | "broker"
  | "notary"
  | "other"
export default class Company extends Model {
  id = 0
  name: string | null = null
  email: string | null = null
  category: CompanyCategory | null = null
}






























































































































































































































































































import { state } from "@/store"
import { Vue, Component } from "vue-property-decorator"
import Panel from "../default/Panel.vue"
import CircularMenu from "../default/CircularMenu.vue"
import Notification from "~/models/Notification"
import router from "@/router"
import Deal from "~/models/Deal"
import PromptIgnorePendingMessage from "@/components/modals/PromptIgnorePendingMessage.vue"
// @ts-ignore
import markerSDK from "@marker.io/browser"
import Profile from "~/models/Profile"

interface PanelInfo {
  title: string
  content: string
}

@Component({
  components: {
    ModalCustomer: () => import("@/components/modals/ModalCustomer.vue"),
    CircularMenu,
    Panel,
    PromptIgnorePendingMessage,
  },
})
export default class Topbar extends Vue {
  expanded = false
  panel: PanelInfo = {
    title: "",
    content: "",
  }
  randomUpdateCurrentDealKey = Math.random()
  reportWidget = null

  mounted() {
    this.loadMarkerWidget()
  }

  async loadMarkerWidget() {
    if (process.env.VUE_APP_MARKER_DESTINATION_ID) {
      this.reportWidget = await markerSDK.loadWidget({
        destination: process.env.VUE_APP_MARKER_DESTINATION_ID,
      })
    }
  }

  isDefaultLayout() {
    const { layout } = this.$route.meta
    return layout === undefined || layout?.toLowerCase() == "default"
  }

  reportBug() {
    if (!this.reportWidget) return
    const userFullName =
      state?.profile?.last_name && state?.profile?.first_name
        ? `${state.profile.last_name} ${state.profile.first_name}`
        : null
    const userEmail = state?.profile?.email ? state.profile.email : null
    setTimeout(() => {
      if (userFullName && userEmail) {
        // @ts-ignore
        this.reportWidget.setReporter({
          fullName: userFullName,
          email: userEmail,
        })
      }
      // @ts-ignore
      this.reportWidget.capture()
    }, 500)
  }

  logout() {
    localStorage.removeItem("user_token")
    state.profile = new Profile()
    this.$router.push(this.$route.path)
    window.location.reload()
  }

  get models() {
    let sorted_deals = state.deals.items.sort(function (a: Deal, b: Deal) {
      let custom_order = [
        "appointment_fixed",
        "appointment_performed",
        "warrant_signed",
        "visits_in_progress",
        "offer_made",
        "offer_accepted",
        "compromise_signed",
        "validated_invoice",
        "authentic_act_signed",
        "cash_in_done",
      ]
      return custom_order.indexOf(a.hubspot_stage_id || "") >
        custom_order.indexOf(b?.hubspot_stage_id || "")
        ? 1
        : -1
    })
    return sorted_deals.filter(
      (deal) => deal.hubspot_stage_id != "cash_in_done"
    )
  }
  get notificationCount() {
    let activity = state.notifications.groupByActivity()
    let actionsCount = 0
    actionsCount += activity["inprogress_verification"]
      ? activity["inprogress_verification"].length
      : 0
    actionsCount += activity["reminder_visit_done"]
      ? activity["reminder_visit_done"].length
      : 0
    actionsCount += activity["call_agent_after_visit_cancelation"]
      ? activity["call_agent_after_visit_cancelation"].length
      : 0
    actionsCount += activity["get_accept_signed"]
      ? activity["get_accept_signed"].length
      : 0
    return {
      bell: this.getAlertNotificationsCount(
        activity["new_search_alert_created"]
      ),
      mail: activity["new_conversation_message"]?.length,
      team: activity["new_action_team"]?.length,
      actions: actionsCount,
      customers: activity["house_state_changed"]?.length,
    }
  }
  get topbar() {
    return this.$refs.topbar as HTMLElement
  }
  get profile() {
    return state.profile.name ? state.profile : "..."
  }

  getAlertNotificationsCount(notifications: Notification[]) {
    if (!notifications) return 0
    let count = 0
    notifications.forEach((notification) => {
      if (notification.alert_enabled && notification.push_enabled) {
        count += parseInt(notification.activity_value)
      }
    })
    return count
  }
  setExpand({ title, content }: PanelInfo) {
    if (title && content && this.panel.content !== content) {
      setTimeout(() => this.setPanel({ title, content }), 0)
    } else {
      this.clearPanel()
    }
  }
  setPanel({ title, content }: PanelInfo) {
    this.panel.title = title
    this.panel.content = content
    this.expanded = true
  }
  clearPanel() {
    this.panel.content = ""
    this.expanded = false
  }
  clickOutside() {
    if (this.expanded) this.clearPanel()
  }
  async changeAutocompleteValue(deal: Deal) {
    await this.handlePendingMessage(() => {
      router.push({ path: "/deals/" + deal.id + "/selected" })
      this.topbar.click()
    })
  }

  async handlePendingMessage(callback: () => void) {
    const prompt = this.$refs
      .promptIgnorePendingMessage as PromptIgnorePendingMessage
    if (state.currentMessage !== "") {
      prompt.open(() => {
        callback()
      })
    } else callback()
  }
  cancelChangeDeal() {
    this.randomUpdateCurrentDealKey = Math.random()
  }
}





























import { Vue, Component, Watch } from "vue-property-decorator"
import HousesByState from "~/collections/HousesByState"
import House from "~/models/House"
import DelayedEmailButton from "@/components/house/DelayedEmailButton.vue"
import HouseCardContainer from "@/components/house/HouseCardContainer.vue"
import { state } from "@/store"

type NoHouse = {
  text: string
  emoji: string
}

type Tab = {
  name: string
  filter: null | ((house: House) => boolean)
  noHouse: NoHouse
}
type Tabs = {
  verification: Tab[]
  sent: Tab[]
}

@Component({
  components: {
    HouseCardContainer,
    DelayedEmailButton,
    Toolbar: () => import("@/layouts/default/Toolbar.vue"),
  },
})
export default class Selected extends Vue {
  loading = true
  houses = new HousesByState()
  activeTab = 0
  page = ""

  get tabs(): Tabs {
    return {
      verification: [
        {
          name:
            this.$route.name === "aside"
              ? "Tous les biens sous le chapeau"
              : "Tous les biens séléctionnés",
          filter: null,
          noHouse: { text: "", emoji: "" },
        },
        {
          name: "Non Vérifié",
          filter: (house) => !house.verification_status,
          noHouse: { text: "Aucun bien non vérifié", emoji: "🕵" },
        },
        {
          name: "Vérification en cours",
          filter: (house) =>
            house.verification_status == "Verification in progress",
          noHouse: { text: "Aucun bien en cours de vérification", emoji: "🕵" },
        },
        {
          name: "Vérifié",
          filter: (house) => house.verification_status == "Verified",
          noHouse: { text: "Aucun bien vérifié", emoji: "🕵" },
        },
      ],
      sent: [
        {
          name: "Tous les biens envoyés",
          filter: null,
          noHouse: { text: "", emoji: "" },
        },
        {
          name: "À traiter",
          filter: (house) => house.deal_status == "processing",
          noHouse: { text: "Aucun bien à traiter par le client", emoji: "⏳" },
        },
        {
          name: "Coup de coeur",
          filter: (house) => house.deal_status == "heartstroked",
          noHouse: {
            text: "Aucun bien mis en coup de coeur par le client",
            emoji: "💖",
          },
        },
        {
          name: "Pourquoi pas",
          filter: (house) => house.deal_status == "selected",
          noHouse: {
            text: "Aucun bien mis dans pourquoi pas par le client",
            emoji: "🤔",
          },
        },
        {
          name: "Écarté",
          filter: (house) => house.deal_status == "excluded",
          noHouse: { text: "Aucun bien écarté par le client", emoji: "👎" },
        },
      ],
    }
  }

  async mounted() {
    this.page = this.currentPage()
    this.loadHouses()
  }

  get currentTabNoHouse() {
    if (this.tabs[this.display].length < this.activeTab + 1) this.activeTab = 0
    return this.tabs[this.display][this.activeTab].noHouse
  }
  get currentTabFilter() {
    if (this.tabs[this.display].length < this.activeTab + 1) this.activeTab = 0
    return this.tabs[this.display][this.activeTab].filter
  }
  get display(): keyof Tabs {
    switch (this.$route.name) {
      case "waiting":
      case "aside":
        return "verification"
      case "sent":
      default:
        return "sent"
    }
  }

  get dealStatusByRoute() {
    switch (this.$route.name) {
      case "waiting":
        return ["waiting"]
      case "aside":
        return ["aside"]
      case "sent":
        return ["processing", "selected", "heartstroked", "excluded"]
      default:
        return []
    }
  }

  get housesSorted() {
    return this.houses.items
      .filter((house) =>
        this.dealStatusByRoute.includes(house.deal_status || "")
      )
      .sort((a, b) => {
        if (a.active != b.active) return a.active ? -1 : 1
        else if (a.selected_house_active != b.selected_house_active)
          return a.selected_house_active ? -1 : 1
        else if (a.sent_at == null) return (a.position || 0) - (b.position || 0)
        else return +new Date(b.sent_at || 0) - +new Date(a.sent_at || 0)
      })
  }
  currentPage() {
    return this.$route.path.split("/")[3]
  }

  @Watch("$route.params.dealId")
  async loadHouses() {
    this.loading = true
    this.houses.deal_id = state.currentDealId()
    this.houses.state = this.$route.name?.split("/")[0] || null
    await this.houses.fetch()
    this.activeTab = 0
    this.loading = false
  }

  countTabElements(tab: Tab) {
    if (!tab.filter)
      return this.houses.items.filter((house) =>
        this.dealStatusByRoute.includes(house.deal_status || "")
      ).length
    return this.houses.items
      .filter((house) =>
        this.dealStatusByRoute.includes(house.deal_status || "")
      )
      .filter(tab.filter).length
  }

  @Watch("$route")
  onRouteChanged() {
    const page = this.currentPage()
    if (this.page != page) {
      this.page = page
      this.loadHouses()
    }
  }

  houseRemoved(house: House) {
    this.houses.items = this.houses.items.filter((item) => item.id !== house.id)
  }
}

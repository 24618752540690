export function toQueryUrl(object: Record<string, any>): string {
  return Object.entries(object)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          JSON.stringify(value)
        )}`
    )
    .join("&")
}

export function fromQueryUrl(
  query: string | Record<string, any>
): Record<string, any> {
  const result: Record<string, any> = {}

  if (typeof query == "string") {
    const records = query.split("&").map((value) => value.split("="))
    for (const [key, value] of records)
      result[decodeURIComponent(key)] = JSON.parse(decodeURIComponent(value))
  } else {
    for (const key in query) result[key] = JSON.parse(query[key])
  }

  return result
}


















































import { Component, Vue } from "vue-property-decorator"
import OcularSnackbar from "~/components/OcularSnackbar.vue"
import { state } from "@/store"

@Component({
  components: {
    OcularSnackbar,
  },
})
export default class GoogleAuthCallback extends Vue {
  loading = true
  success = false
  error: false | string = false
  currentTimeout = 0
  timeout = 5000
  intervalTimeout = 500
  intervalId: number | null = null

  mounted() {
    this.handleGoogleAuthCode()
  }

  async handleGoogleAuthCode() {
    const route = this.$route
    const code = route.query?.code as string
    const profile = state.profile
    if (code) {
      await this.fetchProfile()
      try {
        if (profile.id) {
          const success = await profile.sendGoogleAuthCode(code)
          if (!success)
            throw "Google nous a autorisé à lier votre compte mais nous n'avons pas été en mesure d'enregister cette autorisation"
          state.profile.auth_google_token_url = null
          this.success = true
          this.loading = false
          this.intervalId = setInterval(() => {
            this.currentTimeout += this.intervalTimeout
            if (this.currentTimeout === this.timeout && this.intervalId) {
              clearInterval(this.intervalId)
              this.$router.push({ name: "home" })
            }
          }, this.intervalTimeout)
        } else {
          throw "Google nous a autorisé à lier votre compte, mais nous n'avons pas retrouvé votre compte"
        }
      } catch (e) {
        this.error = e.message
          ? e.message
          : "Une erreur inconnue s'est produite"
        this.loading = false
      }
    } else {
      this.error = "Aucune autorisation reçue de le part de Google"
      this.loading = false
    }
  }

  async fetchProfile() {
    await state.authenticate()
    await state.profile.fetch()
  }
}

import Model from "~/jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"
import { Agent as AlgoliaAgent, Hit } from "~/config/algolia"

export default class Agent extends Model {
  static route = "agents/{id}"
  static requestField = "agent"
  static responseField = "agent"

  id: null | number = null
  name: null | string = null
  surname: null | string = null
  image: null | string = null
  email: null | string = null
  personal_email: null | string = null
  rating: null | number = null
  agency_id: null | number = null
  agency_name: null | string = null
  job: null | string = null
  mobile_number: null | string = null
  assigned_areas: string[] = []
  relation_quality: null | string = null
  created_at: null | Date = null
  updated_at: null | Date = null
  hubspot_id: null | number = null
  hubspot_url: null | string = null
  agencies_count: null | number = null
  agent_id: null | number = null
  started_at: null | number = null
  ended_at: null | number = null
  active: null | boolean = null
  registration_number: null | number = null
  verification_count: null | number = null
  individual: null | boolean = null
  visit_count: number | null = null
  offer_count: number | null = null
  hc_opinions: string | null = null

  getSaveRoute() {
    return this.hasId() ? Agent.route : "agents"
  }

  static fromAlgoliaHit(hit: Hit<AlgoliaAgent>): Agent {
    const agent = new Agent()
    agent.id = hit.id
    agent.name = hit.full_name
    agent.rating = hit.rating
    agent.hubspot_id = hit.hubspot_id
    agent.hubspot_url = hit.hubspot_url
    agent.image = hit.avatar_image_url
    agent.agency_id = hit.current_agency_id
    agent.agency_name = hit.current_agency_name
    agent.mobile_number = hit.mobile_number
    agent.relation_quality = hit.relation_quality
    agent.email = hit.email
    return agent
  }

  get fullname() {
    return [this.name, this.surname].filter((i) => i).join(" ")
  }

  mutations() {
    return {
      created_at: toDate,
      updated_at: toDate,
      started_at: toDate,
      ended_at: toDate,
    }
  }
}

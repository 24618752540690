



















































































import draggable from "vuedraggable"
import { Component, Vue } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import House, { HousePicture } from "~/models/House"
import snackbar from "~/plugins/snackbar"
import Photo from "./Photo.vue"
import { AdPicture } from "~/models/ClassifiedAd"

type Dragged<T> = {
  added?: {
    element: T
    newIndex: number
  }
  moved?: {
    newIndex: number
    oldIndex: number
  }
  removed?: {
    oldIndex: number
  }
}

@Component({
  components: {
    OcularDialog,
    draggable,
    Photo,
  },
})
export default class HousePhotoManager extends Vue {
  house = new House()
  selectedAd = 0

  // Computed props
  get pictures() {
    return this.house.pictures
  }

  get ads() {
    return this.house.classified_ads || []
  }

  get uploader() {
    return this.$refs.uploader as HTMLInputElement
  }

  // Methods
  open(house: House) {
    this.house = house
    // @ts-ignore
    this.$refs.dialog.open = true
  }

  remove(picture: HousePicture) {
    this.pictures.splice(this.pictures.indexOf(picture), 1)
    this.reindexPictures()
    this.house.update(["pictures"])
  }

  pictureInSelection(picture: HousePicture) {
    return this.pictures.some(({ big }) => big == picture.big)
  }

  reindexPictures() {
    this.pictures.forEach((picture, index) => (picture.position = index))
  }

  onPictureChange({ moved }: Dragged<HousePicture>) {
    if (moved) {
      if (moved.newIndex < moved.oldIndex) {
        this.pictures.splice(moved.newIndex, 0, this.pictures[moved.oldIndex])
        this.pictures.splice(moved.oldIndex + 1, 1)
      } else {
        this.pictures.splice(
          moved.newIndex + 1,
          0,
          this.pictures[moved.oldIndex]
        )
        this.pictures.splice(moved.oldIndex, 1)
      }
    }
    this.reindexPictures()
    this.house.update(["pictures"])
  }

  async upload(event: Event) {
    const files: File[] = Array.from((event.target as any).files)
    for (const file of files) {
      const formData = new FormData()
      formData.append("upload", file, file.name)
      const response = await this.house.uploadPhoto(formData)
      this.house.pictures = response.data.house.pictures
      this.reindexPictures()
      this.house.update(["pictures"])
    }
  }

  select(picture: AdPicture) {
    if (this.pictureInSelection(picture)) {
      snackbar({
        content: "La photo est déjà dans la sélection 😉",
        color: "secondary",
      })
      return
    }
    picture.position = 0
    this.pictures.splice(0, 0, picture)
    this.reindexPictures()
    this.house.update(["pictures"])
  }

  unselect(picture: AdPicture) {
    this.pictures.splice(this.pictures.indexOf(picture), 1)
    this.reindexPictures()
    this.house.update(["pictures"])
  }
}








































































import { Component } from "vue-property-decorator"
import Vue from "vue"
import { state } from "@/store"
import groupBy from "lodash/groupBy"
import router from "@/router"
import Notification from "~/models/Notification"

type NotificationsByHouse = { [key: number]: Notification }
type NotificationsByDealByHouse = { [key: number]: NotificationsByHouse }

@Component({})
export default class PanelMessages extends Vue {
  get models() {
    return state.notifications.filterByActivity("new_conversation_message")
  }

  get notificationsByDealByHouse(): NotificationsByDealByHouse {
    let notificationsByDealByHouse = groupBy(this.models, "deal_id") as any

    for (let dealId in notificationsByDealByHouse) {
      notificationsByDealByHouse[dealId] = groupBy(
        notificationsByDealByHouse[dealId],
        "house_id"
      )
      let notificationsByHouse = notificationsByDealByHouse[dealId]

      for (let houseId in notificationsByHouse) {
        notificationsByHouse[houseId] = this.getMostRecentNotification(
          notificationsByHouse[houseId]
        )
      }
    }
    return notificationsByDealByHouse as NotificationsByDealByHouse
  }

  get hasNoNotifications() {
    return this.models.length === 0
  }

  getMostRecentNotification(
    notificationList: Array<Notification>
  ): Notification {
    return notificationList.reduce((prev, current) =>
      prev["created_at"] > current["created_at"] ? prev : current
    )
  }

  getDealTitle(notificationsByHouse: NotificationsByHouse): string {
    return notificationsByHouse[Object.keys(notificationsByHouse)[0] as any]
      .deal_title
  }
  goToMessage(dealId: number, houseId: number) {
    this.$emit("close")
    if (
      (router.currentRoute.params.houseId &&
        parseInt(router.currentRoute.params.houseId) != houseId) ||
      !router.currentRoute.params.houseId
    ) {
      const newRoute = houseId
        ? `/deals/${dealId}/sent/house/${houseId}/messages`
        : `/deals/${dealId}/conversations`
      router.push({ path: newRoute })
    }
  }
}






















import { Vue, Component } from "vue-property-decorator"
import House from "~/models/House"
import Message from "~/models/Message"
import { state } from "@/store"
import OcularDialog from "~/components/OcularDialog.vue"
import snackbar from "~/plugins/snackbar"
import EventBus from "~/utils/EventBus"
import Verification from "~/models/Verification"

@Component({
  components: {
    OcularDialog,
  },
})
export default class ShareHouse extends Vue {
  house = new House()
  newMessage = ""

  get ocularDialog() {
    return this.$refs.ocularDialog as OcularDialog
  }

  open(house: House) {
    this.ocularDialog.open = true
    this.house = house
  }
  appendHouseSummary() {
    this.newMessage += Verification.generateVerificationMessage(this.house)
  }
  async sendHouse() {
    await this.house.send()
    if (this.newMessage != "") {
      await this.sendMessage()
      snackbar({
        color: "success",
        content: `Le bien et le message ont été envoyés avec succès !`,
      })
    } else {
      snackbar({
        color: "success",
        content: `Le bien a été envoyé avec succès !`,
      })
    }
    EventBus.emit("reloadCurrentDeal")
    EventBus.emit("fetchDelayed")
  }
  async sendMessage() {
    const message = new Message({
      deal_id: this.house.deal_id,
      house_id: this.house.id,
      body: this.newMessage,
      created_at: new Date(),
      creator: state.profile,
      creator_id: state.profile.id,
    })
    await message.saveAndAssign()
  }
}

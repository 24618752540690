

























































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import HouseActions, { HouseActionKey } from "./HouseActions.vue"
import HouseCardDealStatusEmoji from "./HouseCardDealStatusEmoji.vue"
import HouseCardEditableTitle from "./HouseCardEditableTitle.vue"
import HouseCardImageCarousel from "./HouseCardImageCarousel.vue"
import HouseCardLocation from "./HouseCardLocation.vue"
import HouseCharacteristics from "./HouseCharacteristics.vue"
import HouseDescriptionsViewer from "./HouseDescriptionsViewer.vue"
import HousePrices from "./HousePrices.vue"
import HouseTimeline from "./HouseTimeline.vue"

@Component({
  components: {
    HouseCardEditableTitle,
    HouseCardImageCarousel,
    HouseCardLocation,
    HousePrices,
    HouseCharacteristics,
    HouseTimeline,
    HouseActions,
    HouseCardDealStatusEmoji,
    HouseDescriptionsViewer,
  },
})
export default class HouseCardFull extends Vue {
  @Prop({ required: true }) house!: House

  get actions() {
    return this.$refs.actions as HouseActions
  }

  onActionRequest(action: HouseActionKey) {
    this.actions.executeAction(action)
  }

  openHouseFull(tab = "description") {
    this.$emit("open-house-full", tab)
  }
  openHouseFullDescription() {
    this.openHouseFull("description")
  }
}

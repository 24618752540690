








































































import { Vue, Component } from "vue-property-decorator"
import Deal from "~/models/Deal"
import { state } from "@/store"
import EventBus from "~/utils/EventBus"

interface Item {
  icon: string
  link: string
  content: string
  badge?: number | undefined
}

@Component
export default class Sidebar extends Vue {
  closed = true
  forceMini = false
  currentDeal: Deal = new Deal()

  mounted() {
    EventBus.on("reloadCurrentDeal", this.reloadCurrentDeal)
    this.checkSidebarSize()
    addEventListener("resize", this.checkSidebarSize, { passive: true })
  }

  async reloadCurrentDeal() {
    await state.currentDeal.fetch()
  }

  destroyed() {
    EventBus.off("reloadCurrentDeal", this.reloadCurrentDeal)
    removeEventListener("resize", this.checkSidebarSize)
    state.readOnly = null
    state.dealCustomersNumber = 0
  }

  checkSidebarSize() {
    if (this.closed && this.closable) state.sidebar = "closed"
    else state.sidebar = this.mini ? "mini" : "full"
  }

  isActive(link: string) {
    // @ts-ignore
    return this.$route.name?.split("/")[0] == link
  }

  isReadOnlyMode() {
    const currentDealId = state.currentDealId()
    return !state.deals.items.some((deal) => deal.id == currentDealId)
  }

  toggleSidebar() {
    this.closed = !this.closed
    this.checkSidebarSize()
    // state.sidebar = state.sidebar == 'closed' ? this.mini ? 'mini' : 'full' : 'closed'
  }

  get closable() {
    return this.$vuetify.breakpoint.name == "xs"
  }

  get mini() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return true
    }
    return this.forceMini
  }

  get items(): Item[][] {
    return [
      [
        {
          icon: "mdi-home-variant-outline",
          link: `home`,
          content: `Accueil`,
        },
        {
          icon: "mdi-magnify",
          link: "search",
          content: `Recherche`,
        },
      ],
      [
        {
          icon: "mdi-bell-outline",
          link: "alerts",
          content: "Alertes",
        },
        {
          icon: "mdi-checkbox-marked-circle-outline",
          link: "waiting",
          content: "Sélectionnés",
          badge: state.currentDeal?.waiting_selected_houses_count
            ? state.currentDeal.waiting_selected_houses_count
            : undefined,
        },
        {
          icon: "Send",
          link: "sent",
          content: "Envoyés",
          badge: state.currentDeal?.sent_selected_houses_count
            ? state.currentDeal.sent_selected_houses_count
            : undefined,
        },
        {
          icon: "Hat",
          link: "aside",
          content: "Sous le chapeau",
          badge: state.currentDeal?.aside_selected_houses_count
            ? state.currentDeal.aside_selected_houses_count
            : undefined,
        },
      ],
      [
        {
          icon: "mdi-account-supervisor-circle-outline",
          link: "visited",
          content: "Visites",
          badge: state.currentDeal?.visit_count
            ? state.currentDeal.visit_count
            : undefined,
        },
        {
          icon: "mdi-file-document-multiple-outline",
          link: "offered",
          content: "Offres",
          badge: state.currentDeal?.offer_count
            ? state.currentDeal.offer_count
            : undefined,
        },
      ],
      [
        {
          icon: "mdi-message-reply-text-outline",
          link: "conversations",
          content: "Messagerie",
        },
      ],
    ]
  }

  get state() {
    return state
  }

  toggleMini() {
    this.forceMini = !this.forceMini
  }

  onClickLink() {
    if (this.$vuetify.breakpoint.xs) {
      this.closed = true
      this.checkSidebarSize()
    }
  }
}

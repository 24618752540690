var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"noprint"},[_c('share-house',{ref:"shareHouseModale"}),_c('send-house',{ref:"sendHouseModale"}),_c('house-photo-manager',{ref:"housePhotoManagerModale"}),_c('PromptDeactivateForCustomer',{ref:"promptDeactivateForCustomerModale"}),_c('div',{staticClass:"actions"},[(_vm.secondaryActions.length)?_c('v-menu',{ref:"secondaryMenu",attrs:{"z-index":"1","nudge-bottom":10,"offset-y":true,"offset-overflow":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":_vm.house.unseen_messages && _vm.display == 'grid'
              ? 'primary'
              : 'transparent',"dot":"","offset-x":5,"offset-y":5}},[_c('v-btn',_vm._g(_vm._b({staticClass:"main-button",attrs:{"color":"text-light","outlined":"","depressed":""}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"more-vertical","color":"text-light","size":24}})],1)],1)]}}],null,false,1833637638)},[_c('v-list',{staticClass:"secondary-actions-menu"},[_c('v-list-item-group',_vm._l((_vm.secondaryActions),function(action,index){return _c('v-list-item',{key:index,staticStyle:{"font-size":"0.8rem"},on:{"click":function($event){return _vm.executeAction(action)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-badge',{attrs:{"color":_vm.house.unseen_messages && action == 'sendMessage'
                    ? 'primary'
                    : 'transparent',"dot":"","offset-x":5,"offset-y":5}},[_c('OcularIcon',{attrs:{"name":_vm.actions[action].icon,"color":"gray-dark","size":_vm.actions[action].iconSize || 18}})],1)],1),_c('v-list-item-content',{staticStyle:{"color":"var(--gray-dark)"}},[_vm._v(" "+_vm._s(_vm.actions[action].name)+" ")])],1)}),1)],1)],1):_vm._e(),(
        !_vm.$state.readOnly &&
        (_vm.house.isSent() || _vm.house.isSelected()) &&
        _vm.display !== 'grid' &&
        _vm.display !== 'full'
      )?_c('v-btn',{staticClass:"main-button",attrs:{"color":"text-light","outlined":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.openHouseMessages()}}},[_c('v-badge',{attrs:{"color":_vm.house.unseen_messages ? 'primary' : 'transparent',"dot":"","offset-x":6,"offset-y":8}},[_c('OcularIcon',{attrs:{"name":"Mail","color":"text-light","size":30}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Model from "~/jrdm-mc/Model"

export const distinct = (
  value: number | string,
  index: number,
  self: Array<number | string>
) => {
  return self.indexOf(value) === index
}
export const noNullNoUndefined = (value: number | string) => {
  return value !== null && value !== 0 && value !== undefined
}
export const toDate = (value: any) => new Date(value)
export const trimmed = (value: null | string) => value && value.trim()
export const asArray = (value: any): Array<any> =>
  Array.isArray(value) ? value : [value]
export const arrayOf = (model: new () => Model) => (values: any[]) =>
  values.map((value) => new model().assign(value))
export const model = (model: new () => Model) => (value: any) =>
  new model().assign(value)




























import { Prop, Vue, Component } from "vue-property-decorator"
// @ts-ignore
import { RadialMenu, RadialMenuItem } from "vue-radial-menu/src/components"

interface Content {
  title: string
  content: string
}

interface Counters {
  bell: number
  mail: number
  team: number
  verification: number
  customers: number
}

@Component({
  components: {
    RadialMenu,
    RadialMenuItem,
  },
})
export default class CircularMenu extends Vue {
  @Prop({ required: true }) notificationCount!: Counters

  get items() {
    return [
      {
        icon: "bell",
        content: { title: "Alertes nouveaux biens", content: "alerts" },
        counter: this.notificationCount.bell,
      },
      {
        icon: "mail",
        content: { title: "Messages", content: "messages" },
        counter: this.notificationCount.mail,
      },
      {
        icon: "users",
        content: { title: "Actions team", content: "team" },
        counter: this.notificationCount.team,
      },
      {
        icon: "FileChecked",
        content: {
          title: "Biens sélectionnés en cours de vérification",
          content: "actions",
        },
        counter: this.notificationCount.verification,
      },
    ]
  }

  handleClick(content: Content) {
    this.$emit("setSidebar", content)
  }
}





















































import { Vue, Component } from "vue-property-decorator"
import VisitSurveyModal from "@/components/modals/VisitSurveyModal.vue"
import ColumnVisitList from "@/components/visits/Board/Columns/ColumnVisitList.vue"
import Visit, { VisitStatus } from "~/models/Visit"
import VisitsColl from "~/collections/Visits"
import Toolbar from "@/layouts/default/Toolbar.vue"
import PromptCancelVisit from "@/components/modals/PromptCancelVisit.vue"
import HouseCard from "@/components/house/HouseCard/HouseCard.vue"
import GoogleAuth from "@/pages/GoogleAuth.vue"

@Component({
  components: {
    Toolbar,
    HouseCard,
    VisitSurveyModal,
    ColumnVisitList,
    PromptCancelVisit,
    GoogleAuth,
  },
})
export default class Visits extends Vue {
  visits = new VisitsColl()
  loading = true

  /**
   * COMPUTED PROPS
   */
  get houseId() {
    if (this.$route.params.houseId) return parseInt(this.$route.params.houseId)
    return null
  }

  get visitsByState() {
    const defaultValue = {
      canceled: [],
      planned: [],
      done: [],
    }
    if (this.visits) {
      return this.visits.items.reduce((prev: any, value) => {
        prev[value["status"]].push(value)
        return prev
      }, defaultValue)
    }
    return defaultValue
  }

  /**
   * HOOKS
   */
  mounted() {
    this.fetchVisits()
  }

  /**
   * METHODS
   */
  async fetchVisits() {
    this.loading = true
    const dealId = parseInt(this.$route.params.dealId)
    if (dealId > 0) {
      this.visits.deal_id = dealId
      await this.visits.fetch()
      this.loading = false
    }
  }

  openVisitSurvey(visit: Visit) {
    const surveyModal = this.$refs["survey-modal"] as VisitSurveyModal
    surveyModal.open(visit)
  }

  updateStatus(visit: Visit, nextState: VisitStatus) {
    const visitIdx = this.visits.items.findIndex((item) => visit.id === item.id)
    visit.status = nextState
    visit.save()
    this.$set(this.visits.items, visitIdx, visit)
  }

  cardPositionChanged(e: any, nextState: VisitStatus) {
    const promptCancelVisit = this.$refs[
      "promptCancelVisit"
    ] as PromptCancelVisit

    if (e.added) {
      const element = e.added.element
      const elementId = element.id
      const visitIdx = this.visits.items.findIndex(
        (visit) => visit.id === elementId
      )
      if (visitIdx >= 0) {
        const newVisit = this.visits.items[visitIdx]
        if (newVisit.status === "canceled") {
          // Ignore canceled visit moved
        } else if (nextState === "canceled") {
          promptCancelVisit.open(this.visits.items[visitIdx])
        } else if (nextState === "done" && newVisit.status !== "done") {
          this.openVisitSurvey(this.visits.items[visitIdx])
        } else {
          this.updateStatus(this.visits.items[visitIdx], nextState)
        }
      }
    }
  }

  cancelVisitConfirm(newVisit: Visit) {
    this.updateStatus(newVisit, "canceled")
  }
}

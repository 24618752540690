import Model from "../jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"

export type ClassifiedAdHistory = {
  date: Date | null
}

export type AdPicture = { big: string; thumb: string; position: number }

export default class ClassifiedAd extends Model {
  static route = "classified_ads"

  id: number | null = null
  title: string | null = null
  description: string | null = null
  scrap_name: string | null = null
  active: boolean | null = null
  url: string | null = null
  created_at: Date | null = null
  pictures: Array<AdPicture> = []
  agency_name: string | null = null
  agency_phone: string | null = null
  agent_name: string | null = null
  agent_phone: string | null = null
  history: ClassifiedAdHistory[] = []

  static mutations = {
    created_at: toDate,
  }
}

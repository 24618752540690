import Model from "../jrdm-mc/Model"
import { distinct, noNullNoUndefined, toDate } from "~/utils/functionsFilter"
import { PropertyType } from "./House"

export { PropertyType }

export default class HouseSearch extends Model {
  static route = "deals/{deal_id}/house-searches/{id}"
  static deleteRoute = "deals/{deal_id}/house-searches/{id}"
  static requestField = "house_search"
  static responseField = "house_search"

  getSaveRoute() {
    return this.hasId() ? HouseSearch.route : "deals/{deal_id}/house-searches"
  }

  id: number | null = null
  title: string | null = null
  uri: string | null = null
  deal_id: number | null = null
  creator_id: number | null = null
  property_type: PropertyType | null = null
  classified_ad_url: string | null = null
  classified_ad_id: number | null = null
  house_id: number | null = null
  price_min = 100_000
  price_max: number | null = null
  pricem2_min: number | null = null
  pricem2_max: number | null = null
  room_count_min: number | null = null
  room_count_max: number | null = null
  bedroom_count_min: number | null = null
  bedroom_count_max: number | null = null
  living_area_min: number | null = null
  living_area_max: number | null = null
  estate_area_min: number | null = null
  estate_area_max: number | null = null
  allow_price_null = false
  allow_pricem2_null = false
  allow_room_count_null = false
  allow_bedroom_count_null = false
  allow_living_area_null = false
  allow_estate_area_null = false
  show_unactive_houses = false
  show_only_unactive_houses = false
  search_in_deals = false
  limit = 50
  offset = 0
  term: string | null = null
  exact_term: string | null = null
  formula_term: string | null = null
  algorithm_tag_ids: Array<number> = []
  selected_tag_ids: Array<number> = []
  rejected_tag_ids: Array<number> = []
  pinned_tag_ids: Array<number> = []
  city_ids: Array<number> = []
  quartier_ids: Array<number> = []
  department_ids: Array<number> = []
  region_ids: Array<number> = []
  natural_region_ids: Array<number> = []
  scrap_names: Array<string> = []
  agency_ids: Array<number> = []
  agency_name: string | null = null
  price_changed_at: string | null = null
  house_created_at: string | null = null
  house_updated_at: string | null = null
  order_column: string | null = null
  order_direction = "desc"
  secondary_order_column: string | null = null
  secondary_order_direction = "asc"
  results_count = 0
  unseen_results_count = 0
  created_at: string | null = null
  updated_at: string | null = null
  photos_count = 0
  southwest_lat: number | null = null
  southwest_lng: number | null = null
  northwest_lat: number | null = null
  northwest_lng: number | null = null
  southeast_lat: number | null = null
  southeast_lng: number | null = null
  northeast_lat: number | null = null
  northeast_lng: number | null = null

  saveAttributes() {
    return [
      "title",
      "deal_id",
      "property_type",
      "classified_ad_url",
      "classified_ad_id",
      "house_id",
      "price_min",
      "price_max",
      "pricem2_min",
      "pricem2_max",
      "room_count_min",
      "room_count_max",
      "bedroom_count_min",
      "bedroom_count_max",
      "living_area_min",
      "living_area_max",
      "estate_area_min",
      "estate_area_max",
      "allow_price_null",
      "allow_pricem2_null",
      "allow_room_count_null",
      "allow_bedroom_count_null",
      "allow_living_area_null",
      "allow_estate_area_null",
      "show_unactive_houses",
      "show_only_unactive_houses",
      "search_in_deals",
      "limit",
      "offset",
      "term",
      "exact_term",
      "formula_term",
      "southwest_lat",
      "southwest_lng",
      "northeast_lat",
      "northeast_lng",
      "algorithm_tag_ids",
      "selected_tag_ids",
      "excluded_tag_ids",
      "pinned_tag_ids",
      "rejected_tag_ids",
      "city_ids",
      "quartier_ids",
      "department_ids",
      "region_ids",
      "natural_region_ids",
      "scrap_names",
      "agency_ids",
      "agency_name",
      "price_changed_at",
      "house_created_at",
      "house_updated_at",
      "photos_count",
      "order_column",
      "order_direction",
      "secondary_order_column",
      "secondary_order_direction",
    ]
  }

  mutations() {
    return {
      id: parseInt,
      deal_id: parseInt,
      creator_id: parseInt,
      property_type: (property_type: string) => property_type,
      house_id: parseInt,
      classified_ad_id: parseInt,
      classified_ad_url: (classified_ad_url: string) =>
        classified_ad_url.trim(),
      title: (title: string) => title.trim(),
      price_min: parseInt,
      price_max: parseInt,
      pricem2_min: parseInt,
      pricem2_max: parseInt,
      room_count_min: parseInt,
      room_count_max: parseInt,
      bedroom_count_min: parseInt,
      bedroom_count_max: parseInt,
      living_area_min: parseInt,
      living_area_max: parseInt,
      estate_area_min: parseInt,
      estate_area_max: parseInt,
      allow_price_null: Boolean,
      allow_pricem2_null: Boolean,
      allow_room_count_null: Boolean,
      allow_bedroom_count_null: Boolean,
      allow_living_area_null: Boolean,
      allow_estate_area_null: Boolean,
      show_unactive_houses: Boolean,
      show_only_unactive_houses: Boolean,
      search_in_deals: Boolean,
      limit: parseInt,
      offset: parseInt,
      term: (term: string) => term.trim(),
      exact_term: (exact_term: string) => exact_term.trim(),
      formula_term: (formula_term: string) => formula_term.trim(),
      algorithm_tag_ids: (algorithm_tag_ids: Array<number>) =>
        algorithm_tag_ids.filter(noNullNoUndefined).filter(distinct),
      selected_tag_ids: (selected_tag_ids: Array<number>) =>
        selected_tag_ids.filter(noNullNoUndefined).filter(distinct),
      rejected_tag_ids: (rejected_tag_ids: Array<number>) =>
        rejected_tag_ids.filter(noNullNoUndefined).filter(distinct),
      pinned_tag_ids: (pinned_tag_ids: Array<number>) =>
        pinned_tag_ids.filter(noNullNoUndefined).filter(distinct),
      city_ids: (city_ids: Array<number>) =>
        city_ids.filter(noNullNoUndefined).filter(distinct),
      quartier_ids: (quartier_ids: Array<number>) =>
        quartier_ids.filter(noNullNoUndefined).filter(distinct),
      department_ids: (department_ids: Array<number>) =>
        department_ids.filter(noNullNoUndefined).filter(distinct),
      region_ids: (region_ids: Array<number>) =>
        region_ids.filter(noNullNoUndefined).filter(distinct),
      natural_region_ids: (natural_region_ids: Array<number>) =>
        natural_region_ids.filter(noNullNoUndefined).filter(distinct),
      agency_ids: (agency_ids: Array<number>) =>
        agency_ids.filter(noNullNoUndefined).filter(distinct),
      scrap_names: (scrap_names: Array<number>) =>
        scrap_names.filter(noNullNoUndefined).filter(distinct),
      agency_name: (agency_name: string) => agency_name.trim() || null,
      photos_count: parseInt,
      results_count: parseInt,
      unseen_results_count: parseInt,
      price_changed_at: toDate,
      house_created_at: toDate,
      house_updated_at: toDate,
      created_at: toDate,
      updated_at: toDate,
      southwest_lat: parseInt,
      southwest_lng: parseInt,
      northwest_lat: parseInt,
      northwest_lng: parseInt,
      southeast_lat: parseInt,
      southeast_lng: parseInt,
      northeast_lat: parseInt,
      northeast_lng: parseInt,
    }
  }
}

















































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HousePrices extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: 20 }) priceSize!: number
  @Prop({ required: false, default: 14 }) squarePriceSize!: number
  @Prop({ required: false, default: "first" }) indicatorPosition!:
    | "first"
    | "last"
  @Prop({ required: false, default: "left" }) align!: "left" | "right"

  get priceStyle() {
    if (this.indicatorPosition === "last") {
      return "flex-direction: row-reverse; justify-content: flex-end;"
    }
    return ""
  }

  get pricesStyle() {
    if (this.align === "right") return "align-items: flex-end"
    return ""
  }

  get trending() {
    const { price, last_price } = this.house
    if (!last_price || !price || price == last_price) return ""
    return last_price < price ? "up" : "down"
  }
}

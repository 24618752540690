
























import { Vue, Component, Prop } from "vue-property-decorator"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import OcularIcon from "~/components/OcularIcon.vue"
import House from "~/models/House"
import Offer from "~/models/Offer"
import HouseCardImageCarousel from "../house/HouseCard/HouseCardImageCarousel.vue"
import OfferAmount from "./OfferAmount.vue"

@Component({
  components: {
    HouseCardImageCarousel,
    OfferAmount,
    AgentItem,
    OcularIcon,
  },
})
export default class OfferBoardCard extends Vue {
  @Prop({ required: true }) offer!: Offer

  get offerCardFooterStyle() {
    return {
      background: `var(--${this.offer.statusConfig?.color})` || "var(--text)",
    }
  }

  get house() {
    return new House(this.offer.house)
  }

  onCardClick() {
    if (this.offer.house) {
      const { dealId } = this.$route.params
      const houseId = this.offer.house.id
      const offerId = this.offer.id
      this.$router.push({
        path: `/deals/${dealId}/under-offer/house/${houseId}/offers/${offerId}`,
      })
    }
  }
}
































































import { state } from "@/store"
import { Component } from "vue-property-decorator"
import Notification from "~/models/Notification"
import Vue from "vue"
import router from "@/router"

@Component({})
export default class PanelTeam extends Vue {
  get models() {
    return state.notifications.filterByActivity("new_action_team")
  }
  get notificationsSortByDate() {
    return this.models.sort((a, b) => +b.updated_at - +a.updated_at)
  }
  get hasNoNotifications() {
    return this.models.length === 0
  }

  actionTeamLabel(type: string, activity_value: string) {
    if (type === "House::Visit") {
      if (activity_value === "planned") return "Visite plannifiée par"
      else if (activity_value === "done") return "Visité par"
      else if (activity_value === "cancel") return "Visite annulée par"
    } else if (type === "House::Offer") {
      if (activity_value === "planned") return "Visite plannifiée par"
      else if (activity_value === "done") return "Visité par"
      else if (activity_value === "cancel") return "Visite annulée par"
    } else {
      return activity_value === "false"
        ? "En cours de vérification par"
        : "Vérifié par"
    }
  }
  async goToHouse(notification: Notification) {
    const dealId = notification.deal_id
    const houseId = notification.house_id
    await this.$emit("deleteNotification", notification)
    this.$emit("close")
    if (
      (router.currentRoute.params.dealId &&
        parseInt(router.currentRoute.params.dealId) != dealId) ||
      (router.currentRoute.params.houseId &&
        parseInt(router.currentRoute.params.houseId) != houseId) ||
      !router.currentRoute.params.houseId
    )
      router.push({
        path: `/deals/${dealId}/search/result/house/${houseId}/description`,
      })
  }
}




































































import { Vue, Component } from "vue-property-decorator"
import { toQueryUrl, fromQueryUrl } from "~/utils/queryUrl"

import HouseSearch, { PropertyType } from "~/models/HouseSearch"
import { HousingType } from "@/components/search/SearchHouseType.vue"
import { BoundedItems } from "@/components/search/BoundedInputs.vue"
import { SelectTagsModel } from "@/components/search/SelectTags.vue"
import { PreciseSearchModel } from "@/components/search/PreciseSearch.vue"
import { state } from "@/store"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    Toolbar: () => import("@/layouts/default/Toolbar.vue"),
    SelectLocation: () => import("@/components/search/SelectLocation.vue"),
    SelectAllLocation: () =>
      import("@/components/search/SelectAllLocation.vue"),
    SelectTags: () => import("@/components/search/SelectTags.vue"),
    SearchTagsHelp: () => import("@/components/search/SearchTagsHelp.vue"),
    SearchOtherTags: () => import("@/components/search/SearchOtherTags.vue"),
    BoundedInputs: () => import("@/components/search/BoundedInputs.vue"),
    PreciseSearch: () => import("@/components/search/PreciseSearch.vue"),
  },
})
export default class Search extends Vue {
  houseSearch = new HouseSearch().assign(fromQueryUrl(this.$route.query))
  locations: string[] = []
  mainTags: SelectTagsModel = {}
  secondaryTags: SelectTagsModel = {}
  otherTags: SelectTagsModel = {}
  preciseSearch: PreciseSearchModel = {
    adUrl: "",
    ocularId: "",
    adId: "",
    date: "",
    text: "",
    scrapNames: [],
    showDeactivatedHouses: false,
    showOnlyDeactivatedHouses: false,
    photoCount: 0,
    searchInDeals: false,
  }
  valid = false
  housingType: HousingType = "all"

  mounted() {
    this.initHouseSearch()
  }

  onChangeHousingType(value: HousingType) {
    this.housingType = value
  }

  readonly filterBoundedItems: BoundedItems = {
    price: {
      title: "Prix",
      min: this.houseSearch.price_min?.toString() || "",
      max: this.houseSearch.price_max?.toString() || "",
      null: false,
      options: { filter: "numberToK", unit: "€" },
    },
    living_area: {
      title: "Surface Habitable",
      min: this.houseSearch.living_area_min?.toString() || "",
      max: this.houseSearch.living_area_max?.toString() || "",
      null: false,
      options: { unit: "m²" },
    },
    room_count: {
      title: "Pièces",
      min: this.houseSearch.room_count_min?.toString() || "",
      max: this.houseSearch.room_count_max?.toString() || "",
      null: false,
      options: { unit: " pièces" },
    },
    bedroom_count: {
      title: "Chambres",
      min: this.houseSearch.bedroom_count_min?.toString() || "",
      max: this.houseSearch.bedroom_count_max?.toString() || "",
      null: false,
      options: { unit: " chambres" },
    },
    estate_area: {
      title: "Surface terrain",
      min: this.houseSearch.estate_area_min?.toString() || "",
      max: this.houseSearch.estate_area_max?.toString() || "",
      null: false,
      options: { unit: "m²" },
    },
    pricem2: {
      title: "Prix m²",
      min: this.houseSearch.pricem2_min?.toString() || "",
      max: this.houseSearch.pricem2_max?.toString() || "",
      null: false,
      options: { filter: "numberToK", unit: "€/m²" },
    },
  }

  search() {
    this.updateHouseSearch()
    this.houseSearch.offset = 0
    const filteredEntries = Object.entries(this.houseSearch).filter(
      ([key, value]) => !key.startsWith("_") && value !== null
    )
    const filteredHouseSearch = Object.fromEntries(filteredEntries)
    this.$router.push(`search/result?${toQueryUrl(filteredHouseSearch)}`)
  }

  initHouseSearch() {
    // locations DEPARTMENTS && DEPARTMENTS && districts && scrap names not displayed
    // Init locations
    this.locations = this.houseSearch?.quartier_ids
      ?.toString()
      .split(",")
      .map((tag) => `Tag:${tag}`)
    this.locations = this.locations.concat(
      this.houseSearch?.city_ids
        ?.toString()
        .split(",")
        .map((tag) => `City:${tag}`)
    )
    this.locations = this.locations.concat(
      this.houseSearch?.department_ids
        ?.toString()
        .split(",")
        .map((tag) => `Department:${tag}`)
    )
    // Init tags
    state.tags.onceLoaded(() => {
      this.houseSearch?.algorithm_tag_ids?.forEach((tag) =>
        this.setTags(tag, 1)
      )
      this.houseSearch?.selected_tag_ids?.forEach((tag) => this.setTags(tag, 2))
      this.houseSearch?.rejected_tag_ids?.forEach((tag) => this.setTags(tag, 3))
    })
    // Init null value for filterBoundedItems
    for (let [key] of Object.entries(this.filterBoundedItems)) {
      // @ts-ignore
      this.filterBoundedItems[key]["null"] =
        // @ts-ignore
        this.houseSearch[`allow_${key}_null`]
    }
    // Init PreciseSearch
    this.initPreciseSearch()
    // Init property type
    if (this.houseSearch.property_type)
      this.housingType = ["all", "house", "flat"][
        this.houseSearch.property_type
      ] as HousingType
  }

  initPreciseSearch() {
    this.preciseSearch.adUrl = this.houseSearch.classified_ad_url || ""
    this.preciseSearch.ocularId = this.houseSearch.house_id?.toString() || ""
    this.preciseSearch.adId =
      this.houseSearch.classified_ad_id?.toString() || ""
    if (this.houseSearch?.house_created_at) {
      this.preciseSearch.date = new Date(this.houseSearch?.house_created_at)
        .toISOString()
        .substr(0, 10)
    }
    this.preciseSearch.text = this.houseSearch.exact_term || ""
    this.preciseSearch.scrapNames = this.houseSearch.scrap_names || null
    this.preciseSearch.showDeactivatedHouses =
      this.houseSearch.show_unactive_houses || false
    this.preciseSearch.showOnlyDeactivatedHouses =
      this.houseSearch.show_only_unactive_houses || false
    this.preciseSearch.photoCount = this.houseSearch.photos_count || 0
    this.preciseSearch.searchInDeals = this.houseSearch.search_in_deals || false
  }

  setTags(tag: number, weight: number) {
    if (state.tags.main.map((tag) => tag.id).includes(tag))
      this.mainTags[tag] = weight
    else if (state.tags.secondary.map((tag) => tag.id).includes(tag))
      this.secondaryTags[tag] = weight
    else Vue.set(this.otherTags, tag, weight)
  }

  updateHouseSearch() {
    this.houseSearch.deal_id = state.currentDealId()
    this.houseSearch.algorithm_tag_ids = []
    this.houseSearch.selected_tag_ids = []
    this.houseSearch.rejected_tag_ids = []
    const algoTypes = [
      this.houseSearch.algorithm_tag_ids,
      this.houseSearch.selected_tag_ids,
      this.houseSearch.rejected_tag_ids,
    ]
    this.houseSearch.quartier_ids = []
    this.houseSearch.department_ids = []
    this.houseSearch.city_ids = []
    const locationProps = {
      Tag: this.houseSearch.quartier_ids,
      Department: this.houseSearch.department_ids,
      City: this.houseSearch.city_ids,
    }
    for (const location of this.locations) {
      const [category, id] = location.split(":")
      // @ts-ignore
      locationProps[category].push(+id)
    }

    for (let [key, data] of Object.entries(this.filterBoundedItems)) {
      // @ts-ignore
      this.houseSearch[`${key}_max`] = +data.max || null
      // @ts-ignore
      this.houseSearch[`${key}_min`] = +data.min || null
      // @ts-ignore
      this.houseSearch[`allow_${key}_null`] = data.null
    }
    for (let [id, type] of Object.entries({
      ...this.mainTags,
      ...this.secondaryTags,
      ...this.otherTags,
    })) {
      if (type > 0) algoTypes[type - 1].push(+id)
    }

    this.houseSearch.property_type = {
      all: null,
      house: PropertyType.house,
      flat: PropertyType.flat,
    }[this.housingType]
    this.houseSearch.classified_ad_url = this.preciseSearch.adUrl || null
    this.houseSearch.house_id = this.preciseSearch.ocularId
      ? +this.preciseSearch.ocularId
      : null
    this.houseSearch.classified_ad_id = this.preciseSearch.adId
      ? +this.preciseSearch.adId
      : null
    this.houseSearch.house_created_at = this.preciseSearch.date
    this.houseSearch.exact_term = this.preciseSearch.text
    this.houseSearch.scrap_names = this.preciseSearch.scrapNames
    this.houseSearch.show_unactive_houses =
      this.preciseSearch.showDeactivatedHouses
    this.houseSearch.show_only_unactive_houses =
      this.preciseSearch.showOnlyDeactivatedHouses
    this.houseSearch.photos_count = this.preciseSearch.photoCount
    this.houseSearch.search_in_deals = this.preciseSearch.searchInDeals
  }
  async updateHouseSearchAlert() {
    this.updateHouseSearch()
    await this.houseSearch.save()
    snackbar({
      color: "success",
      content: `L'alerte '${this.houseSearch.title}' a été mise à jour !`,
    })
    await state.houseSearches.fetch()
    this.$router.push(`alerts/${this.houseSearch.id}`)
  }
}

import axios from "axios"

import valueFromStorage from "../utils/valueFromStorage"
import snackbar from "~/plugins/snackbar"
import authenticate from "~/store/authenticate"
import { state } from "@/store"
import router from "../../ocular/src/router"
import Profile from "~/models/Profile"

export const getUserToken = () => valueFromStorage("user_token")

export const setUserToken = (value: any) => {
  localStorage.setItem("user_token", value)
  axios.defaults.headers.common["X-User-Token"] = value
}

export const getBearerToken = () => valueFromStorage("bearer_token")

export const setBearerToken = (value: any) => {
  localStorage.setItem("bearer_token", value)
  axios.defaults.headers.common["Authorization"] = `Bearer ${value}`
}

axios.interceptors.request.use(
  (config) => config,
  (error) => {
    snackbar({
      content:
        "Une erreur s'est produite dans la requête au serveur : " + error,
    })
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status !== 401) {
      snackbar({
        content:
          "Une erreur s'est produite dans la réponse au serveur : " + error,
      })
      return Promise.reject(error)
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url.endsWith("/authenticate")) {
      state.profile = new Profile()
      if (router.currentRoute.path !== "/authentication-failed") {
        router.push("/authentication-failed")
      }
      return Promise.reject(error)
    }

    // Try request again with new token
    return authenticate
      .authenticate()
      .then((authenticated) => {
        if (!authenticated) {
          if (router.currentRoute.path !== "/authentication-failed") {
            router.push("/authentication-failed")
          }
          return Promise.reject(error)
        }

        // New request with new token
        const config = error.config
        const newToken = getBearerToken()
        config.headers["Authorization"] = `Bearer ${newToken}`

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      })
      .catch((error) => {
        Promise.reject(error)
      })
  }
)

const tokenUrl = new URLSearchParams(window.location.search).get("token")
setBearerToken(
  !tokenUrl || tokenUrl === getUserToken() ? getBearerToken() : null
)
setUserToken(tokenUrl || getUserToken() || null)

export default axios

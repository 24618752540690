import Collection from "../jrdm-mc/Collection"
import Visit from "../models/Visit"

export default class Visits extends Collection<Visit> {
  static responseField = "visits"
  static route = "deals/{deal_id}/visits"

  deal_id = 0

  model() {
    return Visit
  }
}

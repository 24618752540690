import Collection from "../jrdm-mc/Collection"
import House from "../models/House"
import { Method } from "axios"

export default class Houses extends Collection<House> {
  static route = "search"
  static responseField = "houses"
  model() {
    return House
  }
  getFetchMethod() {
    return "POST" as Method
  }

  offset = 0
  limit = 50
  estimated_count = 0

  get pageCount() {
    return ~~(this.estimated_count / this.limit) + 1
  }
}

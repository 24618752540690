import Model from "../jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"
import House, { PropertyType } from "./House"
import { translate } from "@/store"

export default class Verification extends Model {
  static route = "deals/{deal_id}/houses/{house_id}/verifications/{id}"
  static requestField = "verification"

  id: number | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  done: boolean | null = null
  house_id: number | null = null
  user_id: number | null = null
  agent_id: number | null = null
  agency_id: number | null = null
  rating: number | null = null
  deal_id: number | null = null
  full: boolean | null = null

  static mutations = {
    created_at: toDate,
    updated_at: toDate,
  }

  getSaveRoute() {
    return this.hasId()
      ? Verification.route
      : "deals/{deal_id}/houses/{house_id}/verifications"
  }

  static generateVerificationBase(house: House) {
    let currencyFormatter = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    })
    let msg = "== INFORMATIONS DE BASE ==\n"
    if (house.address) msg += `- Adresse : ${house.address}\n`
    if (house.price)
      msg += `- Prix : ${currencyFormatter.format(house.price)}\n`
    if (house.living_area) msg += `- Surface totale : ${house.living_area}m²\n`
    if (house.room_count)
      msg += `- Nb. de pièces : ${house.room_count} pièce${
        house.room_count ? "s" : ""
      }\n`

    // Chambres
    if (house?.bedroom_count) {
      let house_areas = ""
      if (house.bedroom_areas?.length) {
        house_areas = house.bedroom_areas
          ?.map((area) => `${area} m²`)
          ?.join(" | ")
        house_areas = `(${house_areas})`
      }
      msg += `- Nombre et surfaces des chambres : ${house.bedroom_count} chambres ${house_areas}\n`
    }

    // Etage
    if (house.floor && house.floor_count) {
      msg += `- Etage : ${house.floor} / ${house.floor_count}\n`
    } else if (house.floor) {
      msg += `- Etage : ${house.floor}\n`
    } else if (house.floor_count) {
      msg += `- Nombre d'étage : ${house.floor_count}\n`
    }

    // Ascenseur
    if (house.property_type === PropertyType.flat)
      msg += `- Ascenseur : ${house.elevator ? "Oui" : "Non"}\n`
    return msg
  }

  static generateVerificationAdditionnal(house: House) {
    let msg = `\n== INFORMATIONS COMPLÉMENTAIRES ==\n`
    if (house.dpe_class) msg += `- DPE : ${house.dpe_class}\n`
    if (house.construction_year)
      msg += `- Année de construction : ${house.construction_year}\n`
    if (house.living_room_area)
      msg += `- Surface du salon : ${house.living_room_area}\n`
    if (house.kitchen_type || house.kitchen_area) {
      msg += `- Cuisine `
      if (house.kitchen_type)
        msg += `(${translate("kitchen_type", house.kitchen_type)})`
      if (house.kitchen_area) msg += ` : ${house.kitchen_area}m²`
      msg += `\n`
    }
    if (house.bathroom_count)
      msg += `- Nb de salles de bain : ${house.bathroom_count}\n`
    if (house.shower_room_count)
      msg += `- Nb de salles d'eau : ${house.shower_room_count}\n`

    if (house.separate_wc || house.wc_count) {
      msg += "- Nombre de WC "
      if (house.separate_wc === true) {
        if (house.wc_count && house.wc_count > 1)
          msg += "(dont au moins un separé)"
        else msg += "(séparé)"
      } else if (house.separate_wc === false) msg += "(non-séparé)"
      if (house.wc_count) msg += ` : ${house.wc_count}`
      msg += `\n`
    }
    if (house.ceiling_height)
      msg += `- Hauteur sous plafond : ${house.ceiling_height}m\n`
    msg += `- Jardin : ${house.garden ? "Oui" : "Non"} ${
      house.garden_area ? `(${house.garden_area} m²)` : ""
    }\n`
    msg += `- Cave : ${house.cellar ? "Oui" : "Non"}\n`
    if (house.condition)
      msg += `- Etat du bien : ${translate("condition", house.condition)}\n`
    if (house.orientation)
      msg += `- Orientation : ${translate("orientation", house.orientation)}\n`
    if (house.heating_type || house.heating_sharing) {
      msg += `- Chauffage`
      if (house.heating_sharing)
        msg += ` (${translate("heating_sharing", house.heating_sharing)})`
      if (house.heating_type)
        msg += ` : ${translate("heating_type", house.heating_type)}`
      msg += "\n"
    }
    return msg
  }

  static generateVerificationCopro(house: House) {
    let currencyFormatter = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    })
    let msg = "\n== INFORMATIONS SUR LA COPROPRIETE ==\n"

    // - Gardien : Non ==== AFFICHER MEME SI "NON" ====
    if (house.copro_condition)
      msg += `- Etat de la copropriété : ${translate(
        "copro_condition",
        house.copro_condition
      )}\n`
    if (house.copro_work_price)
      msg += `- Quote part travaux : ${currencyFormatter.format(
        house.copro_work_price
      )}\n`
    return msg
  }

  static generateVerificationCharges(house: House) {
    let currencyFormatter = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    })

    let msg = "\n== CHARGES ==\n"
    if (house.monthly_charges)
      msg += `- Charges mensuelles : ${currencyFormatter.format(
        house.monthly_charges
      )}\n`
    if (house.property_tax)
      msg += `- Taxe foncière : ${currencyFormatter.format(
        house.property_tax
      )}\n`
    return msg
  }

  static generateVerificationMessage(house: House) {
    let msg = "Ci-dessous, un récapitulatif du bien proposé.\n\n"

    msg += this.generateVerificationBase(house)
    msg += this.generateVerificationAdditionnal(house)
    msg += this.generateVerificationCopro(house)
    msg += this.generateVerificationCharges(house)
    return msg
  }
}

import Model from "../jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"
import axios, { AxiosRequestConfig } from "axios"
import snackbar from "~/plugins/snackbar"

export default class Profile extends Model {
  static route = "profile"
  static requestField = "user"
  static responseField = "profile"

  id = 0
  name: null | string = null
  email: null | string = null
  discarded_at: null | string = null
  level = "guest"
  root = false
  first_name: null | string = null
  last_name: null | string = null
  phone_number: null | string = null
  formatted_phone_number: null | string = null
  uri: null | string = null
  authentication_uri: null | string = null
  slack_name: null | string = null
  avatar = {
    url: null as string | null,
    small: {
      url: null as string | null,
    },
    thumb: {
      url: null as string | null,
    },
  }
  current_sign_in_at: null | string = null
  last_sign_in_at: null | string = null
  created_at: null | string = null
  updated_at: null | string = null
  current_deal_id: null | string | number = null
  hide_header_list: null | boolean = null
  account: null | { id: number; api_key: string } = null
  auth_google_token_url: string | null = null
  sectors: Array<number> = []
  country_code: string | null = null
  sign_in_count: number | null = null

  static mutations = {
    discarded_at: toDate,
  }

  saveAttributes() {
    return ["phone_number", "hide_header_list", "avatar"]
  }

  get fullname() {
    return [this.first_name, this.last_name?.toUpperCase()]
      .filter((i) => i)
      .join(" ")
  }

  get isGoogleConnected() {
    return this.auth_google_token_url === null
  }

  async sendGoogleAuthCode(code: string) {
    const options: AxiosRequestConfig = {
      method: "PUT",
      url: this.renderRoute("profile/authorize-google"),
      data: { code },
    }
    try {
      const response = await axios(options)
      return response.status === 200
    } catch (error) {
      snackbar({
        content:
          "Une erreur est survenue pendant la connexion à votre compte google. " +
          "Si le problème survient à nouveau, contactez les devs.",
      })
      throw new Error(
        "Erreur : Ocular n'a pas pu valider l'autorisation fournie par Google"
      )
    }
  }
}

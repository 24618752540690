




















































































































































































































































import { state } from "@/store"
import { Component } from "vue-property-decorator"
import Vue from "vue"
import router from "@/router"

@Component({})
export default class PanelActions extends Vue {
  get actions() {
    return [
      ...this.verifications,
      ...this.visitDoneReminders,
      this.callAgentAfterVisitCancelations,
    ]
  }
  get verifications() {
    return state.notifications.filterByActivity("inprogress_verification")
  }
  get visitDoneReminders() {
    return state.notifications.filterByActivity("reminder_visit_done")
  }
  get callAgentAfterVisitCancelations() {
    return state.notifications.filterByActivity(
      "call_agent_after_visit_cancelation"
    )
  }
  get getAcceptSigned() {
    return state.notifications.filterByActivity("get_accept_signed")
  }
  get verificationsSortByDate() {
    return this.verifications.sort((a, b) => +b.updated_at - +a.updated_at)
  }
  get visitDoneRemindersSortByDate() {
    return this.visitDoneReminders.sort((a, b) => +b.updated_at - +a.updated_at)
  }
  get callAgentAfterVisitCancelationsSortByDate() {
    return this.callAgentAfterVisitCancelations.sort(
      (a, b) => +b.updated_at - +a.updated_at
    )
  }
  get getAcceptSignedSortByDate() {
    return this.getAcceptSigned.sort((a, b) => +b.updated_at - +a.updated_at)
  }

  get hasNoNotifications() {
    return this.actions.length === 0
  }

  goToVerification(dealId: number, houseId: number) {
    this.$emit("close")
    if (
      (router.currentRoute.params.dealId &&
        parseInt(router.currentRoute.params.dealId) != dealId) ||
      (router.currentRoute.params.houseId &&
        parseInt(router.currentRoute.params.houseId) != houseId) ||
      !router.currentRoute.params.houseId
    )
      router.push({
        path: `/deals/${dealId}/selected/house/${houseId}/verification`,
      })
  }

  goToVisit(dealId: number, houseId: number, visitId: number) {
    this.$emit("close")
    router
      .push({
        path: `/deals/${dealId}/visited/house/${houseId}/visits/${visitId}`,
      })
      .catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error
        }
      })
  }

  goToOffer(dealId: number, houseId: number, offerId: number) {
    this.$emit("close")
    router.push({
      path: `/deals/${dealId}/visited/house/${houseId}/offers/${offerId}`,
    })
  }
}

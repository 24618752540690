export default [
  ["Agency", "agency.svg"],
  ["AlertCircle", "alert-circle.svg"],
  ["AlertOctagon", "alert-octagon.svg"],
  ["AlertTriangle", "alert-triangle.svg"],
  ["AlignCenter", "align-center.svg"],
  ["AlignJustify", "align-justify.svg"],
  ["AlignLeft", "align-left.svg"],
  ["AlignRight", "align-right.svg"],
  ["ArrowDownLeft", "arrow-down-left.svg"],
  ["ArrowDownRight", "arrow-down-right.svg"],
  ["ArrowDown", "arrow-down.svg"],
  ["ArrowLeft", "arrow-left.svg"],
  ["ArrowRight", "arrow-right.svg"],
  ["ArrowUpLeft", "arrow-up-left.svg"],
  ["ArrowUpRight", "arrow-up-right.svg"],
  ["ArrowUp", "arrow-up.svg"],
  ["Balcony", "balcony.svg"],
  ["Bedroom", "bedroom.svg"],
  ["BellOff", "bell-off.svg"],
  ["Bell", "bell.svg"],
  ["Book", "book.svg"],
  ["Bookmark", "bookmark.svg"],
  ["Burger", "burger.svg"],
  ["Bust", "bust.svg"],
  ["Calm", "calm.svg"],
  ["CheckCircle", "check-circle.svg"],
  ["CheckSquare", "check-square.svg"],
  ["Check", "check.svg"],
  ["ChevronDown", "chevron-down.svg"],
  ["ChevronLeft", "chevron-left.svg"],
  ["ChevronRight", "chevron-right.svg"],
  ["ChevronUp", "chevron-up.svg"],
  ["ChevronsDown", "chevrons-down.svg"],
  ["ChevronsLeft", "chevrons-left.svg"],
  ["ChevronsRight", "chevrons-right.svg"],
  ["ChevronsUp", "chevrons-up.svg"],
  ["Chrome", "chrome.svg"],
  ["CloudDrizzle", "cloud-drizzle.svg"],
  ["CloudLightning", "cloud-lightning.svg"],
  ["CloudOff", "cloud-off.svg"],
  ["CloudRain", "cloud-rain.svg"],
  ["CloudSnow", "cloud-snow.svg"],
  ["Cloud", "cloud.svg"],
  ["Copy", "copy.svg"],
  ["Crosshair", "crosshair.svg"],
  ["Delete", "delete.svg"],
  ["Diamond", "diamond.svg"],
  ["DownloadCloud", "download-cloud.svg"],
  ["Download", "download.svg"],
  ["Duplicate", "duplicate.svg"],
  ["Edit2", "edit-2.svg"],
  ["Edit3", "edit-3.svg"],
  ["Edit", "edit.svg"],
  ["Elevator", "elevator.svg"],
  ["ExternalLink", "external-link.svg"],
  ["EyeOff", "eye-off.svg"],
  ["Eye", "eye.svg"],
  ["Facebook", "facebook.svg"],
  ["FileChecked", "file-checked.svg"],
  ["FileMinus", "file-minus.svg"],
  ["FilePlus", "file-plus.svg"],
  ["FileText", "file-text.svg"],
  ["File", "file.svg"],
  ["Film", "film.svg"],
  ["Flag", "flag.svg"],
  ["Floor", "floor.svg"],
  ["Folder", "folder.svg"],
  ["Google", "google.svg"],
  ["Hash", "hash.svg"],
  ["Hat", "hat.svg"],
  ["Heart", "heart.svg"],
  ["Home", "home.svg"],
  ["Inbox", "inbox.svg"],
  ["Info", "info.svg"],
  ["Instagram", "instagram.svg"],
  ["Layout", "layout.svg"],
  ["Link2", "link-2.svg"],
  ["Link", "link.svg"],
  ["Lock", "lock.svg"],
  ["LogIn", "log-in.svg"],
  ["LogOut", "log-out.svg"],
  ["Luminous", "luminous.svg"],
  ["Mail", "mail.svg"],
  ["MapPin", "map-pin.svg"],
  ["Map", "map.svg"],
  ["Menu", "menu.svg"],
  ["MessageCircle", "message-circle.svg"],
  ["MessageSquare", "message-square.svg"],
  ["MicOff", "mic-off.svg"],
  ["Mic", "mic.svg"],
  ["MinusCircle", "minus-circle.svg"],
  ["MinusSquare", "minus-square.svg"],
  ["Minus", "minus.svg"],
  ["Money", "money.svg"],
  ["MoreHorizontal", "more-horizontal.svg"],
  ["MoreVertical", "more-vertical.svg"],
  ["Music", "music.svg"],
  ["Navigation2", "navigation-2.svg"],
  ["Navigation", "navigation.svg"],
  ["Offer", "offer.svg"],
  ["Percent", "percent.svg"],
  ["Picture", "picture.svg"],
  ["PlusCircle", "plus-circle.svg"],
  ["PlusSquare", "plus-square.svg"],
  ["Plus", "plus.svg"],
  ["RefreshCcw", "refresh-ccw.svg"],
  ["RefreshCw", "refresh-cw.svg"],
  ["Refreshment", "refreshment.svg"],
  ["Repeat", "repeat.svg"],
  ["Room", "room.svg"],
  ["RotateCcw", "rotate-ccw.svg"],
  ["RotateCw", "rotate-cw.svg"],
  ["Scissors", "scissors.svg"],
  ["Search", "search.svg"],
  ["Send", "send.svg"],
  ["Settings", "settings.svg"],
  ["Share2", "share-2.svg"],
  ["Share", "share.svg"],
  ["Sidebar", "sidebar.svg"],
  ["Square", "square.svg"],
  ["StarFilled", "star-filled.svg"],
  ["Star", "star.svg"],
  ["Sun", "sun.svg"],
  ["Sunrise", "sunrise.svg"],
  ["Sunset", "sunset.svg"],
  ["Surface", "surface.svg"],
  ["Tag", "tag.svg"],
  ["Team", "team.svg"],
  ["Terrace", "terrace.svg"],
  ["Thermometer", "thermometer.svg"],
  ["Trash2", "trash-2.svg"],
  ["Trash", "trash.svg"],
  ["Twitter", "twitter.svg"],
  ["Umbrella", "umbrella.svg"],
  ["Unlock", "unlock.svg"],
  ["UploadCloud", "upload-cloud.svg"],
  ["Upload", "upload.svg"],
  ["UserCheck", "user-check.svg"],
  ["UserMinus", "user-minus.svg"],
  ["UserPlus", "user-plus.svg"],
  ["UserX", "user-x.svg"],
  ["User", "user.svg"],
  ["Users", "users.svg"],
  ["Verifie", "verifie.svg"],
  ["Vue", "vue.svg"],
  ["Visavis", "visavis.svg"],
  ["Visit", "visit.svg"],
  ["Wind", "wind.svg"],
  ["XCircle", "x-circle.svg"],
  ["XSquare", "x-square.svg"],
  ["X", "x.svg"],
  ["ZoomIn", "zoom-in.svg"],
  ["ZoomOut", "zoom-out.svg"],
  ["Maximize", "maximize.svg"],
  ["Minimize", "minimize.svg"],
  ["NoPicture", "no-picture.svg"],
  ["Save", "save.svg"],
  ["Camera", "camera.svg"],
  ["ActionClient", "action-client.svg"],
  ["CheckGreen", "check-green.svg"],
  ["Add", "add.svg"],
  ["DuplicatePlus", "duplicate-plus.svg"],
  ["Republished", "republished.svg"],
  ["VirtualVisit", "virtual-visit.svg"],
  ["Verification", "verification.svg"],
  ["ArrowLeftBottom", "arrow-left-bottom.svg"],
]

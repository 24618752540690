import Model from "../jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"

export default class Alert extends Model {
  static route = "deals/{dealId}/house-searches/{houseSearchId}/alert"
  static responseField = "alert"
  static requestField = "house_search_alert"

  id: null | number = null
  dealId: null | number = null
  houseSearchId: null | number = null
  creator_id: null | number = null
  frequency = "instantly"
  score_min = 25
  receivable_type: null | string = null
  receivable_id: null | number = null
  transmittable_type: null | string = null
  transmittable_id: null | number = null
  enable_notifications = true
  enable_push = true
  last_sent_at = undefined
  created_at: null | Date = null
  updated_at: null | string = null

  saveAttributes() {
    return ["frequency", "score_min", "enable_notifications", "enable_push"]
  }

  mutations() {
    return {
      id: parseInt,
      dealId: parseInt,
      houseSearchId: parseInt,
      creator_id: parseInt,
      score_min: parseInt,
      receivable_id: parseInt,
      transmittable_id: parseInt,
      enable_notifications: Boolean,
      enable_push: Boolean,
      created_at: toDate,
      frequency: (value: string) => value.trim(),
      receivable_type: (value: string) => value.trim(),
      transmittable_type: (value: string) => value.trim(),
    }
  }
}

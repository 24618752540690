



































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Offer from "~/models/Offer"

@Component({
  components: {
    OcularDialog,
  },
})
export default class OfferAccept extends Vue {
  @Prop({ required: true }) offer!: Offer | null
  fileToImport: File | null = null

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  open() {
    this.dialog.open = true
  }
  close() {
    this.dialog.open = false
  }
  async acceptOffer() {
    if (this.offer) {
      if (this.fileToImport) {
        const updatedOffer = await this.offer.importOfferFile(
          this.fileToImport,
          "countersigned_offer"
        )
        this.offer.assignReceivedData(updatedOffer)
      } else {
        this.offer.status = "accepted"
        const updatedOffer = await this.offer.save()
        this.offer.assignReceivedData(updatedOffer)
      }
      this.$emit("updated", this.offer)
      this.close()
    }
  }
  cancel() {
    this.close()
  }
}

import algoliasearch from "algoliasearch"
import { env } from "~/utils/environment"

export default algoliasearch

import { createNullCache } from "@algolia/cache-common"

export const client = algoliasearch(
  `BDVROSIWKU`,
  `b0a5d354a7ea8600ed6da27d1252cc4a`
)
export const client_agency = algoliasearch(
  `UJJ19XF3VY`,
  `e3e45ba4bb452b68609ee3d4fb318b78`,
  { responsesCache: createNullCache() }
)

export const tags = client.initIndex("Tag")
export const cities = client.initIndex("City")
export const departments = client.initIndex("Department")
export const scraps = client.initIndex("Scrap")
export const agencies = client_agency.initIndex(`Agency_${env}`)
export const agents = client_agency.initIndex(`Agent_${env}`)
export const brands = client_agency.initIndex(`Brand_${env}`)

export const uniqueId = (indexName: string, id: number | string = "") =>
  `${indexName}:${id}`

export type Tag = {
  belongs_grouped_tag: boolean
  created_at: string
  from_city: boolean
  id: number
  is_grouped_tag: boolean
  is_visible: boolean
  name: string
  objectID: string
  order: number
  priority: number
  property_type_id: number
  score: number
  score_pos: number
  score_sup: number
  sub_category_id: number
  updated_at: string
  used_in_algo: boolean
}

export type Scrap = {
  active: boolean
  active_count: number
  boosted_department: null
  correct: boolean
  crawl_interval: number
  created_at: string
  expected_total: number
  human_name: string
  last_crawl_at: string
  name: string
  off_market: boolean
  scrap_type: string
  should_rescrap: boolean
  updated_at: string
}

export type Agency = {
  id: number
  name: string
  avatar_image_url: string
  category: string
  assigned_areas: string[]
  city_name: string
  zipcode: string
  email: string
  street_address: string
  agents_count: number
  relation_quality: string
  landline_number: string
  hubspot_id: number
  hubspot_url: string
}

export type Agent = {
  id: number
  rating: number
  full_name: string
  avatar_image_url: string
  hubspot_id: number
  hubspot_url: string
  current_agency_id: number
  current_agency_name: string
  mobile_number: string
  email: string
  relation_quality: string
}

export type Brand = {
  id: number
  category: string
  category_text: string
  hubspot_url: string
  name: string
  hubspot_id: number
  agencies_count: number
}

export type Hit<Subtype> = Subtype & {
  readonly objectID: string
  readonly _highlightResult?: HighlightResult<Subtype>
  readonly _snippetResult?: SnippetResult<Subtype>
  readonly _rankingInfo?: RankingInfo
  readonly _distinctSeqID?: number
}

export type TagItem = {
  id: number | string
  uniqueId: string
  name: string
  type: string
}

export type TagHit = Hit<{
  id: number | string
  code: number | string
  name: string
  real_name: string
  zipcode: string
  department_code: string
  sub_category_id: string
}>

type HighlightMatch = {
  readonly value: string
  readonly matchLevel: "none" | "partial" | "full"
  readonly matchedWords: readonly string[]
  readonly fullyHighlighted?: boolean
}

type SnippetMatch = {
  readonly value: string
  readonly matchLevel: "none" | "partial" | "full"
}

type HighlightResult<Subtype> = Subtype extends string | number
  ? HighlightMatch
  : {
      [KAttribute in keyof Subtype]?: HighlightResult<Subtype[KAttribute]>
    }

type SnippetResult<Subtype> = Subtype extends string | number
  ? SnippetMatch
  : {
      [KAttribute in keyof Subtype]: SnippetResult<Subtype[KAttribute]>
    }

type RankingInfo = {
  readonly promoted: boolean
  readonly nbTypos: number
  readonly firstMatchedWord: number
  readonly proximityDistance?: number
  readonly geoDistance: number
  readonly geoPrecision?: number
  readonly nbExactWords: number
  readonly words: number
  readonly filters: number
  readonly userScore: number
  readonly matchedGeoLocation?: {
    readonly lat: number
    readonly lng: number
    readonly distance: number
  }
  readonly personalization?: {
    readonly filtersScore: number
    readonly rankingScore: number
    readonly score: number
  }
}

import axios, { AxiosRequestConfig } from "axios"
import Model from "../jrdm-mc/Model"

export type DealDocumentNature =
  | "unknown"
  | "cover_letter"
  | "loan_simulation"
  | "signed_offer"
  | "countersigned_offer"
  | "identity"
  | "other"

export default class DealDocument extends Model {
  static route = "deals/{deal_id}/documents/{id}"
  static saveRoute = "deals/{deal_id}/documents"
  static responseField = "deal_document"
  static requestField = "deal_document"

  id: number | null = null
  name: string | null = null
  size: number | null = null
  nature: DealDocumentNature = "unknown"
  deal_id: number | null = null

  async import(file: File, nature: DealDocumentNature) {
    const options: AxiosRequestConfig = {}
    options.method ||= "POST"
    ;(options.headers ||= { "Content-Type": "multipart/form-data" }),
      (options.url ||= this.renderRoute(this.getSaveRoute()))
    options.data ||= new FormData()
    options.data.append("file", file)
    options.data.append("nature", nature)
    const response = await axios(options)
    return this.assign(response.data)
  }

  getSaveRoute() {
    return this.hasId() ? DealDocument.route : DealDocument.saveRoute
  }
}

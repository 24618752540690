



























import { state } from "@/store"
import { Vue, Component } from "vue-property-decorator"

@Component({
  components: {},
})
export default class GoogleAuth extends Vue {
  get googleAuthUrl() {
    return state.profile?.auth_google_token_url
  }

  get currentUser() {
    return state.profile
  }

  redirectToGoogleAuth() {
    if (this.googleAuthUrl) {
      window.location.replace(this.googleAuthUrl)
    }
  }
}

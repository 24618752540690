


























































import { getEnumValues } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Offer from "~/models/Offer"
import AgentInterationRating from "@/components/agents/AgentInteractionRating.vue"

@Component({
  components: {
    OcularDialog,
    AgentInterationRating,
  },
})
export default class OfferRefuse extends Vue {
  @Prop({ required: true }) offer!: Offer
  refuseReason = null
  interactionRating: number | null = null

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  get refuseReasons() {
    return Object.entries(getEnumValues("refused_reason")).map(
      ([value, text]) => ({
        value,
        text,
      })
    )
  }

  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }

  cancel() {
    this.close()
  }

  rateChanged(value: number | null) {
    this.interactionRating = value
  }

  async refuse() {
    if (this.offer) {
      this.offer.status = "refused"
      this.offer.rating = this.interactionRating
      this.offer.refused_reason = this.refuseReason
      const offerUpdated = await this.offer.save()
      this.offer.assignReceivedData(offerUpdated)
      this.$emit("updated", this.offer)
      this.close()
    }
  }

  // -- rules
  notEmpty = (value: string | number | Array<any> | null | undefined) =>
    typeof value == "number"
      ? true
      : !!(value && value.length) || "Champ requis"
}

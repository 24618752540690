export type SectorConfigType = {
  sector: number
  human_name: string
  department_codes: string[]
  sub_category_id?: number
  tag_other?: number
  display_by_arr: boolean
}

export const SectorsConfig: SectorConfigType[] = [
  {
    sector: 0,
    human_name: "Paris",
    department_codes: ["75", "77", "78", "91", "92", "93", "94", "95"],
    sub_category_id: 20,
    tag_other: 1331,
    display_by_arr: true,
  },
  {
    sector: 1,
    human_name: "Bordeaux",
    department_codes: ["33"],
    sub_category_id: 36,
    display_by_arr: false,
  },
  {
    sector: 2,
    human_name: "Nantes",
    department_codes: ["44"],
    display_by_arr: false,
    sub_category_id: 39,
  },
  {
    sector: 3,
    human_name: "Rennes",
    department_codes: ["35"],
    display_by_arr: false,
    sub_category_id: 3200,
  },
  {
    sector: 4,
    human_name: "Montpellier",
    sub_category_id: 38,
    department_codes: ["34"],
    display_by_arr: false,
  },
  {
    sector: 5,
    human_name: "Lyon",
    department_codes: ["69"],
    sub_category_id: 37,
    tag_other: 1587,
    display_by_arr: true,
  },
  {
    sector: 6,
    human_name: "Not applicable",
    department_codes: ["51"],
    display_by_arr: false,
  },
  {
    sector: 7,
    human_name: "Haute Savoie",
    department_codes: ["74"],
    display_by_arr: false,
    sub_category_id: 2000,
  },
  {
    sector: 8,
    human_name: "Calvados",
    department_codes: ["14"],
    display_by_arr: false,
    sub_category_id: 2100,
  },
  {
    sector: 9,
    human_name: "Charente-Maritime",
    department_codes: ["17"],
    display_by_arr: false,
    sub_category_id: 2200,
  },
  {
    sector: 10,
    human_name: "Alpes-Maritimes",
    department_codes: ["06"],
    display_by_arr: false,
    sub_category_id: 2300,
  },
  {
    sector: 11,
    human_name: "Bouches-du-Rhône",
    department_codes: ["13"],
    display_by_arr: false,
    sub_category_id: 40,
  },
  {
    sector: 12,
    human_name: "Côtes-d'Armor",
    department_codes: ["22"],
    display_by_arr: false,
    sub_category_id: 22500,
  },
  {
    sector: 13,
    human_name: "Eure",
    department_codes: ["27"],
    display_by_arr: false,
    sub_category_id: 2600,
  },
  {
    sector: 14,
    human_name: "Morbihan",
    department_codes: ["56"],
    display_by_arr: false,
    sub_category_id: 2700,
  },
  {
    sector: 15,
    human_name: "Oise",
    department_codes: ["60"],
    display_by_arr: false,
    sub_category_id: 2800,
  },
  {
    sector: 16,
    human_name: "Seine-Maritime",
    department_codes: ["76"],
    display_by_arr: false,
    sub_category_id: 2900,
  },
  {
    sector: 17,
    human_name: "Var",
    department_codes: ["83"],
    display_by_arr: false,
    sub_category_id: 3000,
  },
  {
    sector: 18,
    human_name: "Vaucluse",
    department_codes: ["84"],
    display_by_arr: false,
    sub_category_id: 3100,
  },
  {
    sector: 19,
    human_name: "Gard",
    department_codes: ["30"],
    display_by_arr: false,
    sub_category_id: 3300,
  },
  {
    sector: 20,
    human_name: "Savoie",
    department_codes: ["73"],
    display_by_arr: false,
    sub_category_id: 3400,
  },
  {
    sector: 21,
    human_name: "Isère",
    department_codes: ["38"],
    display_by_arr: false,
    sub_category_id: 3500,
  },
  {
    sector: 22,
    human_name: "Aisne",
    department_codes: ["02"],
    display_by_arr: false,
    sub_category_id: 3600,
  },
  {
    sector: 23,
    human_name: "Marne",
    department_codes: ["51"],
    display_by_arr: false,
    sub_category_id: 3700,
  },
  {
    sector: 24,
    human_name: "Aude",
    department_codes: ["11"],
    display_by_arr: false,
    sub_category_id: 3800,
  },
  {
    sector: 25,
    human_name: "Vendée",
    department_codes: ["85"],
    display_by_arr: false,
    sub_category_id: 3900,
  },
  {
    sector: 26,
    human_name: "Deux-Sèvres",
    department_codes: ["79"],
    display_by_arr: false,
    sub_category_id: 4000,
  },
  {
    sector: 27,
    human_name: "Mainte-et-Loire",
    department_codes: ["49"],
    display_by_arr: false,
    sub_category_id: 4100,
  },
  {
    sector: 28,
    human_name: "Nord",
    department_codes: ["59"],
    display_by_arr: false,
    sub_category_id: 4200,
  },
  {
    sector: 29,
    human_name: "Haute-Garonne",
    department_codes: ["31"],
    display_by_arr: false,
    sub_category_id: 41,
  },
  {
    sector: 30,
    human_name: "Côte d'Or",
    department_codes: ["21"],
    display_by_arr: false,
    sub_category_id: 4400,
  },
  {
    sector: 31,
    human_name: "Loire-et-Cher",
    department_codes: ["41"],
    display_by_arr: false,
    sub_category_id: 4500,
  },
  {
    sector: 32,
    human_name: "Loiret",
    department_codes: ["45"],
    display_by_arr: false,
    sub_category_id: 4600,
  },
  {
    sector: 33,
    human_name: "Indre-et-Loire",
    department_codes: ["37"],
    display_by_arr: false,
    sub_category_id: 4700,
  },
]

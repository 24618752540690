












import { Component } from "vue-property-decorator"
import Vue from "vue"

@Component({
  components: {},
})
export default class GoogleAuthLayout extends Vue {}

import Vue from "vue"
import { format } from "date-fns"
import fr from "date-fns/locale/fr"
// registerLocale('fr', fr);

const priceFormatter = (price: number): string => {
  const formatter = new Intl.NumberFormat("FR-fr", { style: "decimal" })
  return formatter.format(price).replace(/ /g, "  ") + " €"
}

Vue.filter("toCurrency", function (value: number) {
  let formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
})

Vue.filter("ago", function (from: Date): string {
  let to: Date = new Date()
  let minute = 60_000
  let hour = minute * 60
  let day = hour * 24
  let year = day * 365
  const diff = +to - +from

  const between = (min: number, max = 0): boolean => {
    if (!max) return diff < min
    return diff > min && diff <= max
  }

  if (between(minute)) return "< 1 minute"
  if (between(minute, hour)) return Math.floor(diff / 60_000) + " minutes"
  if (between(hour, day)) {
    return Math.floor(diff / hour) > 1
      ? Math.floor(diff / hour) + " heures"
      : Math.floor(diff / hour) + " heure"
  }
  if (between(day, year)) {
    return Math.floor(diff / day) > 1
      ? Math.floor(diff / day) + " jours"
      : Math.floor(diff / day) + " jour"
  }
  return "plus d'un an"
})

Vue.filter("date", function (date: string | Date, full = false): string {
  if (typeof date === "string") date = new Date(date)
  return new Intl.DateTimeFormat(
    "fr-FR",
    // @ts-ignore
    full ? { dateStyle: "full" } : undefined
  ).format(date)
})

Vue.filter("dateFormat", function (date: string | Date, f: string): string {
  if (typeof date === "string") date = new Date(date)
  return format(date, f, { locale: fr })
})

const timeFormat = {
  hour: "numeric",
  minute: "numeric",
}
Vue.filter("time", (date: any) =>
  new Intl.DateTimeFormat("fr-FR", timeFormat).format(new Date(date))
)

Vue.filter("initials", function (name: string): string {
  if (!name) return "..."
  let result = name
    .match(/(\b\S)?/g)
    ?.join("")
    ?.match(/(^\S|\S$)?/g)
    ?.join("")
    .toUpperCase()
  return result || name
})

Vue.filter("numberToK", function (value: number): string {
  if (!value) return "..."
  if (value >= 1_000_000) {
    return (value / 1_000_000).toString() + "M"
  } else if (value >= 10_000) {
    return (value / 1000).toString() + "K"
  }
  return value.toString()
})

Vue.filter("price", (value: number) => {
  return priceFormatter(value)
})

Vue.filter("capitalizedFirstLetter", (value: string) => {
  return value[0].toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter("formatPhoneNumber", (value: string) => {
  if (!value) return ""
  const nonInt = /\D/g
  const indicator = /^330|^33/
  const allNumbers = /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/
  const formatStyle = "$1 $2 $3 $4 $5"
  return value
    .replace(nonInt, "")
    .replace(indicator, "0")
    .replace(allNumbers, formatStyle)
})

Vue.filter(
  "priceRange",
  (range: { min: number; max: number; allow_null: boolean }) => {
    const readableRange = {
      min: priceFormatter(range.min),
      max: priceFormatter(range.max),
    }
    let result = ""
    if (range.min && !range.max) result = `A partir de ${readableRange.min}`
    else if (range.max && !range.min) result = `Jusqu'à ${readableRange.max}`
    else if (range.min && range.max)
      result = `Entre ${readableRange.min} et ${readableRange.max}`
    return range.allow_null ? `${result} + null` : result
  }
)

Vue.filter(
  "areaRange",
  (range: { min: number; max: number; allow_null: boolean }) => {
    let result = ""
    if (range.min && !range.max) result = `A partir de ${range.min} m²`
    else if (range.max && !range.min) result = `Jusqu'à ${range.max} m²`
    else if (range.min && range.max)
      result = `Entre ${range.min} m² et ${range.max} m²`
    return range.allow_null ? `${result} + null` : result
  }
)

Vue.filter(
  "roomRange",
  (range: { min: number; max: number; allow_null: boolean }) => {
    let result = ""
    if (range.min && !range.max)
      result = `${range.min} ${range.min > 1 ? "pièces" : "pièce"} min`
    else if (range.max && !range.min)
      result = `${range.max} ${range.max > 1 ? "pièces" : "pièce"} max`
    else if (range.min && range.max)
      result = `${range.min} à ${range.max} pièces`
    return range.allow_null ? `${result} + null` : result
  }
)

Vue.filter(
  "bedroomRange",
  (range: { min: number; max: number; allow_null: boolean }) => {
    let result = ""
    if (range.min && !range.max)
      result = `${range.min} ${range.min > 1 ? "chambres" : "chambre"} min`
    else if (range.max && !range.min)
      result = `${range.max} ${range.max > 1 ? "chambres" : "chambre"} max`
    else if (range.min && range.max)
      result = `${range.min} à ${range.max} chambres`
    return range.allow_null ? `${result} + null` : result
  }
)

































































import { Vue, Component, Prop } from "vue-property-decorator"

@Component({})
export default class OcularDialog extends Vue {
  @Prop({ default: "Valider" }) textOk!: string
  @Prop({ default: "Annuler" }) textCancel!: string
  @Prop({ default: true }) closable!: boolean
  @Prop({ default: 500 }) width!: number
  @Prop({ default: true }) actions!: boolean // display or hide the v-card-actions
  @Prop({ default: true }) closeAfterValidation!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false, required: false }) loadingIndication!: boolean

  open = false
  valid = true

  cancel() {
    if (this.closeAfterValidation) this.open = false
    this.$emit("cancel")
  }
  close() {
    this.open = false
    this.$emit("close")
  }
  ok() {
    if (this.closeAfterValidation) this.open = false
    if (!this.formElement || this.formElement.validate()) {
      this.$emit("ok")
    }
  }
  get formElement(): undefined | HTMLFormElement {
    return this.$refs.form as HTMLFormElement
  }
}

import Model from "../jrdm-mc/Model"
import House from "./House"
import { toDate } from "~/utils/functionsFilter"

export default class HouseSearchResult extends Model {
  id: null | number = null
  seen: null | boolean = null
  house_search_id: null | number = null
  house_id: null | number = null
  created_at: null | string = null
  updated_at: null | string = null
  house: null | House = new House()

  mutations() {
    return {
      id: parseInt,
      seen: Boolean,
      house_search_id: parseInt,
      house_id: parseInt,
      house: (value: House) => new House().assign(value),
      created_at: toDate,
      updated_at: toDate,
    }
  }
}

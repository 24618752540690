























import { themeColor } from "~/styles/colors"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import icons from "~/assets/icons"

// this way we lazily load all the icons in the assets/icons folder
const iconLoader = icons.map(([component, filename]) => [
  component,
  () => import(`~/assets/icons/${filename}`),
])

@Component({
  components: Object.fromEntries(iconLoader),
})
export default class OcularIcon extends Vue {
  @Prop({ required: true }) name!: string
  @Prop({ default: "currentColor" }) color!: string
  @Prop() badgeContent?: string
  @Prop({ type: Number, default: 24 }) size!: number
  @Prop({ type: Number, default: -2 }) offsetX!: number
  @Prop({ type: Number, default: 12 }) offsetY!: number
  @Prop({ type: Boolean, default: false }) pointer!: boolean
  @Prop({ type: Number, default: 0 }) offsetXIcon!: number
  @Prop({ type: Number, default: 0 }) offsetYIcon!: number
  @Prop({ type: Boolean, default: false }) badgeLeft!: boolean

  get style() {
    return {
      width: !this.name.startsWith("mdi") ? `${this.size}px` : undefined,
      height: !this.name.startsWith("mdi") ? `${this.size}px` : undefined,
      color: themeColor(this.color),
      top: `${this.offsetYIcon}px`,
      left: `${this.offsetXIcon}px`,
    }
  }

  @Watch("color")
  onColorChanged(color: string) {
    this.style.color = themeColor(color)
  }

  @Watch("size")
  onSizeChanged() {
    this.style.width = `${this.size}px`
    this.style.height = `${this.size}px`
  }
}

import Collection from "../jrdm-mc/Collection"
import HouseSearch from "../models/HouseSearch"

export default class HouseSearches extends Collection<HouseSearch> {
  static route = "deals/{deal_id}/house-searches"
  static responseField = "house_searches"
  static requestField = "house_searches"

  deal_id: null | number = null

  model() {
    return HouseSearch
  }

  mutations() {
    return {
      deal_id: parseInt,
    }
  }
}

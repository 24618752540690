import Model from "~/jrdm-mc/Model"
import { toDate } from "~/utils/functionsFilter"

export default class PriceHistory extends Model {
  price: number | null = null
  date: Date | null = null
  trend: "neutral" | "up" | "down" = "neutral"

  static mutations = {
    date: toDate,
  }
}

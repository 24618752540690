
























import { Component, Prop, Vue } from "vue-property-decorator"
import HouseSearch from "~/models/HouseSearch"
import House from "~/models/House"
import { Tag, Hit, tags } from "~/config/algolia"
import { state } from "@/store"

type TagPriority = null | "selected" | "algorithm" | "rejected"
type TagMatch = {
  name: string
  id: number
  priority: TagPriority
}

@Component({})
export default class SearchMatchHouseTags extends Vue {
  @Prop({ default: null }) houseSearch!: HouseSearch
  @Prop({ required: true }) house!: House
  tags: TagMatch[] = []
  matchTags: TagMatch[] = []
  notMatchTags: TagMatch[] = []

  mounted() {
    this.getTags()
  }

  async fetchTagsFromIds(list: string[]) {
    if (list.length == 0) return []
    const in_state_main = state.tags.main.filter((tag) =>
      list.includes(tag.id.toString())
    )
    const in_state_secondary = state.tags.secondary.filter((tag) =>
      list.includes(tag.id.toString())
    )
    const in_state_other = state.tags.other.filter((tag) =>
      list.includes(tag.id.toString())
    )
    const in_state = [
      ...in_state_other,
      ...in_state_main,
      ...in_state_secondary,
    ]
    const in_state_tag_match = in_state.map((tag) => {
      return {
        name: tag.name,
        id: tag.id,
        priority: "algorithm",
      } as TagMatch
    })
    const in_state_ids = in_state.map((tag) => tag.id.toString())
    const to_load = list.filter((tag_id) => !in_state_ids.includes(tag_id))
    let new_tag_loaded: TagMatch[] = []
    if (to_load.length > 0) {
      const response = await tags.getObjects(to_load)
      const hits = response.results as Hit<Tag>[]
      new_tag_loaded = hits.map((hit) => {
        if (!state.tags.other.map((tag) => tag.id).includes(hit.id)) {
          state.tags.other.push(hit)
        }
        return {
          name: hit.name,
          id: hit.id,
          priority: "algorithm",
        } as TagMatch
      })
    }
    return [...in_state_tag_match, ...new_tag_loaded]
  }
  async getTags() {
    this.tags = await this.fetchTagsFromIds(
      this.houseSearch.algorithm_tag_ids.map((id) => id.toString())
    )
    this.computeMatchTags()
    this.computeNotMatchTags()
  }

  async computeMatchTags() {
    this.matchTags = []
    this.matchTags = this.tags.filter((searchTag) => {
      const matchWithHouse = !!this.house.tags.find(
        (houseTag) => houseTag.id === searchTag.id
      )
      return matchWithHouse && searchTag.priority !== "selected"
    })
  }

  async computeNotMatchTags() {
    this.notMatchTags = []
    this.notMatchTags = this.tags.filter((searchTag) => {
      return (
        this.house.tags.findIndex(
          (houseTag) => houseTag.id === searchTag.id
        ) === -1
      )
    })
  }

  tagColor(priority: TagPriority) {
    if (!priority) return "default"
    const colors = {
      selected: "info",
      algorithm: "orange",
      rejected: "error",
    }
    return colors[priority]
  }

  tagTextColor(priority: TagPriority) {
    if (!priority) return "default"
    const textColors = {
      selected: "default",
      algorithm: "white",
      rejected: "default",
    }
    return textColors[priority]
  }
}

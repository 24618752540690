

































import { Component, Vue } from "vue-property-decorator"
import House from "~/models/House"
import { state } from "@/store"
import HouseCard from "@/components/house/HouseCard/HouseCard.vue"
import OcularDialog from "~/components/OcularDialog.vue"

@Component({
  components: {
    OcularDialog,
    HouseCard,
  },
})
export default class PromptHouseMerge extends Vue {
  masterHouse: House | null = null
  childrenHouse: House | null = null
  merging = false

  get dialog(): OcularDialog {
    return this.$refs.dialog as OcularDialog
  }

  setMasterHouse(house: House | null) {
    this.masterHouse = house
  }

  setChildrenHouse(house: House | null) {
    this.childrenHouse = house
  }

  confirmHousesMerge() {
    if (this.masterHouse && this.childrenHouse && this.childrenHouse.id) {
      this.merging = true
      this.masterHouse
        .merge([this.childrenHouse.id], state.currentDealId())
        .then((mergeSucceed) => {
          this.merging = false
          if (mergeSucceed) {
            this.dialog.close()
            this.$emit("houses-merged", this.masterHouse, this.childrenHouse)
          }
        })
    }
  }

  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }
}

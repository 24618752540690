var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"home",staticClass:"home"},[_c('header',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"col-md-3",attrs:{"items":_vm.salespeopleFiltered,"label":"Homecatcher","item-value":"id","item-text":"name","single-line":"","return-object":"","hide-details":""},on:{"change":_vm.changeSalesperson},model:{value:(_vm.salesperson),callback:function ($$v) {_vm.salesperson=$$v},expression:"salesperson"}}),_c('v-text-field',{staticClass:"col-md-9",attrs:{"label":"Filtrer les deals"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.dealsItems,"item-key":"id","fixed-header":"","items-per-page":25,"locale":"fr-FR","no-data-text":"Pas de resultats","no-results-text":"Pas de resultats","calculate-widths":"","loading":!_vm.loaded,"loading-text":"En cours de chargement...","footer-props":_vm.footerProps,"search":_vm.search,"mobile-breakpoint":"0","must-sort":"","custom-filter":_vm.customSearch,"sort-by":"hubspot_stage_id","sort-desc":true},scopedSlots:_vm._u([{key:"item.hubspot_stage_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hubspot_stage)+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("deals/" + (item.id) + "/selected")}},[_vm._v(_vm._s(item.title))])]}},{key:"item.hubspot_owner_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hubspot_owner_name)+" ")]}},{key:"item.customer_success_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_success_name)+" ")]}},{key:"item.budget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.budget))+" ")]}},{key:"item.geography",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("replaceSemicolonByColon")(item.geography))+" ")]}},{key:"item.notifications_new_message",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("deals/" + (item.id) + "/conversations")}},[_c('OcularIcon',{attrs:{"name":"mail","color":item.notifications_new_message.length ? 'primary' : 'text',"badge-content":item.notifications_new_message.length,"offsetX":3,"offsetY":8}})],1)]}},{key:"item.notifications_new_alert",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("deals/" + (item.id) + "/alerts")}},[(_vm.numberOfAlertOfDeal(item.notifications_new_alert))?_c('v-chip',{staticClass:"text-center pointer",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.numberOfAlertOfDeal(item.notifications_new_alert))+" ")]):_vm._e()],1)]}},{key:"item.hubspotDealId",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"d-flex justify-center",attrs:{"href":item.deal.hubspotUrl(),"target":"_blank"}},[_c('OcularIcon',{attrs:{"name":"arrowUpRight"}})],1)]}},{key:"item.mandate_signature_at_sorter",fn:function(ref){
var item = ref.item;
return [(item.mandate_signature_at)?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item.mandate_signature_at))+" ")]):_vm._e()]}},{key:"item.first_visit_at_sorter",fn:function(ref){
var item = ref.item;
return [(item.first_visit_at_sorter)?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item.first_visit_at_sorter))+" ")]):_vm._e()]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('ModalCustomer',{attrs:{"deal":item.deal,"title":"Informations client"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"users"}})],1)]}}],null,true)},[_c('span',[_vm._v("Informations client")])])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" ")]}}])}),_c('v-switch',{staticClass:"cash-in d-none d-sm-flex",attrs:{"dark":"","dense":"","label":"cash-in"},model:{value:(_vm.cashIn),callback:function ($$v) {_vm.cashIn=$$v},expression:"cashIn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Base from "./Base"
import Model from "./Model"

export default abstract class Collection<T extends Model> extends Base {
  items: T[] = []
  abstract model(): new (...args: any[]) => T

  assignReceivedData(data: any) {
    const itemsKey = this.getResponseField()
    const Model = this.model()

    if (!itemsKey) {
      // eslint-disable-next-line
      console.warn(
        `Missing 'responseField' property for collection ${this.constructor.name}.`
      )
      return this
    }

    if (itemsKey in data) {
      if (Array.isArray(data[itemsKey])) {
        // @ts-ignore
        this.items = data[itemsKey].map((element) =>
          new Model().assign(element)
        )
      } else {
        // eslint-disable-next-line
        console.warn(
          `Received non-array data for collection ${this.constructor.name} :`,
          data
        )
      }
      // @ts-ignore
      delete data[itemsKey]
    } else {
      // eslint-disable-next-line
      console.warn(
        `Missing property ${itemsKey} after fetching in collection ${this.constructor.name}`
      )
    }
    this.assign(data)
    return this
  }

  get length() {
    return this.items.length
  }

  remove(element: T) {
    const index = this.items.indexOf(element)
    if (~index) {
      this.items.splice(index, 1)
      return true
    }
    return false
  }

  at(index: number) {
    return this.items[index]
  }

  push(...args: T[]) {
    return this.items.push(...args)
  }
  filter(predicate: (value: T, index: number, array: T[]) => value is T) {
    return this.items.filter(predicate)
  }
  find(
    predicate: (this: void, value: T, index: number, obj: T[]) => value is T
  ) {
    return this.items.find(predicate)
  }
  indexOf(searchElement: T) {
    return this.items.indexOf(searchElement)
  }
}































































































import { state } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import { Media } from "~/components/OcularLightbox.vue"
import House from "~/models/House"
import EventBus from "~/utils/EventBus"

@Component({
  components: {},
})
export default class HouseCardImageCarousel extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: 220 }) height!: number
  isHover = false
  currentImageIdx = 0

  get photoBadges() {
    return this.house.photoBadges(state.dealCustomersNumber)
  }
  get statusBadgeText() {
    return this.house.statusBadgeText()
  }

  openLightbox(items: Array<Media>, index: number) {
    EventBus.emit("openLightbox", { items, index })
  }

  openVirtualVisit() {
    if (this.house.virtual_visit_url)
      window.open(this.house.virtual_visit_url, "_blank")
  }
}

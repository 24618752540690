import Collection from "../jrdm-mc/Collection"
import Offer from "../models/Offer"

export default class Offers extends Collection<Offer> {
  static responseField = "offers"
  static route = "deals/{deal_id}/offers"

  deal_id = 0

  model() {
    return Offer
  }
}

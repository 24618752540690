import Collection from "../jrdm-mc/Collection"
import Deal from "../models/Deal"

export default class Deals extends Collection<Deal> {
  static route = "profile/deals"
  static responseField = "deals"

  model() {
    return Deal
  }
}

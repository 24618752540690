import Vue from "vue"
/* @ts-ignore */
import Vuetify from "vuetify/lib/framework"
import fr from "vuetify/src/locale/fr"
import en from "vuetify/src/locale/en"
import colors from "~/styles/colors"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: colors,
    },
  },
  lang: {
    locales: { fr, en },
    current: "fr",
  },
})






































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularIcon from "~/components/OcularIcon.vue"

const defaultRatingTooltips = ["1/5", "2/5", "3/5", "4/5", "5/5"]

@Component({
  components: {
    OcularIcon,
  },
})
export default class OcularRating extends Vue {
  @Prop({ required: true }) value!: number
  @Prop({ default: false }) readonly!: boolean | ""
  @Prop({
    required: false,
    default: () => defaultRatingTooltips,
  })
  ratingTooltips!: string[]

  updateValue(value: number) {
    this.$emit("input", value)
  }
  onClick({ index }: any) {
    this.$emit("input", index + 1)
  }
}

const colors = {
  default: "#DDDEE4",
  primary: "#EB6483",
  secondary: "#0DCBBC",
  tertiary: "#00497B",
  accent: "#E34564",
  orange: "#FF7452",
  error: "#FF0000",
  info: "#3A84EC",
  success: "#57D9A3",
  warning: "#F8C400",
  rating: "#FFDC57",
  text: "#3A3C46",
  "secondary-light": "#5ED8CE",
  "text-light": "#ABA2B4",
  "text-light-2": "#5F6274",
  "text-light-3": "#7E718A",
  gray: "#BDC1DA",
  "gray-dark": "#ABA2B4",
  "gray-dark-2": "#E2E4EE",
  "gray-light": "#F4F5F9",
  "gray-light-2": "#DDD",
  "gray-light-3": "#979797",
  "background-light": "#F5F6FB",
  "background-light-2": "#F8F8FA",
  "background-light-3": "#EBEBEF",
  "background-medium": "#1C2B3B",
  "background-dark": "#141E29",
  black: "#1B1D28",
  white: "#FFFFFF",
  blue: "#589DFE",
}

export default colors
export const isThemeColor = (color: string) => color in colors
export const themeColor = (color: string) =>
  isThemeColor(color) ? `var(--${color})` : color












































import { state } from "@/store"
import { Component } from "vue-property-decorator"
import groupBy from "lodash/groupBy"
import Vue from "vue"
import router from "@/router"
import Notification from "~/models/Notification"

@Component({})
export default class PanelAlerts extends Vue {
  get models() {
    return state.notifications.filterByActivity("new_search_alert_created")
  }
  get emptyNotifications() {
    return this.filterEnabledNotifications.length === 0
  }
  get notificationsByDeal() {
    return groupBy(this.filterEnabledNotifications, "deal_id") as {
      [key: number]: Notification[]
    }
  }
  get filterEnabledNotifications() {
    return this.models.filter(
      (notification) => notification.alert_enabled && notification.push_enabled
    )
  }

  resultsCountFor(notificationsByDeal: Notification[]) {
    return notificationsByDeal?.reduce(
      (a: number, b) => a + parseInt(b?.activity_value || "0"),
      0
    )
  }
  goToAlert(dealId: number, houseSearchId: number) {
    this.$emit("close")
    if (
      (router.currentRoute.params.houseSearchId &&
        parseInt(router.currentRoute.params.houseSearchId) != houseSearchId) ||
      !router.currentRoute.params.houseSearchId
    )
      router.push({ path: `/deals/${dealId}/alerts/${houseSearchId}` })
  }
}

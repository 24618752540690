var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"topbar",attrs:{"id":"topbar"}},[_c('prompt-ignore-pending-message',{ref:"promptIgnorePendingMessage",on:{"cancel":_vm.cancelChangeDeal}}),_c('div',{staticClass:"fill-topbar"}),_c('div',{staticClass:"fixed-topbar position-fixed w-100 top-0"},[_c('v-toolbar',{staticClass:"p-pt-0 p-pb-0 elevation-2",attrs:{"absolute":""}},[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo hidden-sm-and-down",attrs:{"src":require("@/../../common/assets/jrdm-logo-blue@2x.png"),"alt":"logo"}})]),_c('div',{staticClass:"burger-button-filler",attrs:{"hidden":!_vm.isDefaultLayout()}})],1),_c('div',{staticClass:"filler-left hidden-sm-and-down"}),_c('v-item-group',{staticClass:"flex-grow-1"},[_c('span',{staticClass:"bar-vertical hidden-sm-and-down"}),_c('v-autocomplete',{key:_vm.randomUpdateCurrentDealKey,attrs:{"items":_vm.models,"value":_vm.$state.currentDeal,"item-text":"title","item-value":"id","label":"Rechercher un client","single-line":"","return-object":"","hide-details":""},on:{"change":_vm.changeAutocompleteValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_vm._v(_vm._s(item.title))]),_c('v-list-item-action',[_vm._v(_vm._s(item.hubspot_stage))])]}}])}),(_vm.$state.currentDeal.id)?_c('ModalCustomer',{attrs:{"deal":_vm.$state.currentDeal,"title":"Informations client"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item ml-5 hidden-sm-and-down",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"alertCircle","color":"gray-dark"}})],1)]}}],null,false,1502099756)},[_c('span',[_vm._v("Informations client")])])],1):_vm._e()],1),(_vm.$state.currentDeal.id)?_c('v-item-group',{staticClass:"hidden-xs-only"},[_c('span',{staticClass:"bar-vertical"}),_c('router-link',{attrs:{"to":("/deals/" + (_vm.$state.currentDealId()) + "/house/new/verification")}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"plusCircle","color":"primary"}})],1)]}}],null,false,2355759921)},[_c('span',[_vm._v("Ajouter un bien")])])],1)],1):_vm._e(),_c('v-item-group',{staticClass:"responsive-menu"},[_c('span',{staticClass:"bar-vertical"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item",attrs:{"icon":""},on:{"click":function($event){return _vm.setExpand({
                  title: 'Alertes nouveaux biens',
                  content: 'alerts',
                })}}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"bell","color":_vm.panel.content === 'alerts'
                    ? 'primary'
                    : _vm.notificationCount.bell
                    ? 'text'
                    : 'gray-dark',"badge-content":_vm.notificationCount.bell}})],1)]}}])},[_c('span',[_vm._v("Alertes des nouveaux biens")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item",attrs:{"icon":""},on:{"click":function($event){return _vm.setExpand({ title: 'Messages', content: 'messages' })}}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"mail","color":_vm.panel.content === 'messages'
                    ? 'primary'
                    : _vm.notificationCount.mail
                    ? 'text'
                    : 'gray-dark',"badge-content":_vm.notificationCount.mail}})],1)]}}])},[_c('span',[_vm._v("Messages")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item",attrs:{"icon":""},on:{"click":function($event){return _vm.setExpand({ title: 'Actions team', content: 'team' })}}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"users","color":_vm.panel.content === 'team'
                    ? 'primary'
                    : _vm.notificationCount.team
                    ? 'text'
                    : 'gray-dark',"badge-content":_vm.notificationCount.team}})],1)]}}])},[_c('span',[_vm._v("Actions team")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item",attrs:{"icon":""},on:{"click":function($event){return _vm.setExpand({ title: 'Actions client', content: 'customers' })}}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"name":"ActionClient","color":_vm.panel.content === 'customers'
                    ? 'primary'
                    : _vm.notificationCount.customers
                    ? 'text'
                    : 'gray-dark',"badge-content":_vm.notificationCount.customers}})],1)]}}])},[_c('span',[_vm._v("Actions client")])])],1),_c('v-item-group',[_c('span',{staticClass:"bar-vertical hide-xs"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"topbar-item responsive-menu",attrs:{"icon":""},on:{"click":function($event){return _vm.setExpand({
                  title: 'Actions en attente',
                  content: 'actions',
                })}}},'v-btn',attrs,false),on),[_c('OcularIcon',{attrs:{"color":_vm.panel.content === 'actions'
                    ? 'primary'
                    : _vm.notificationCount.actions
                    ? 'text'
                    : 'gray-dark',"name":"mdi-format-list-checks","badge-content":_vm.notificationCount.actions}})],1)]}}])},[_c('span',[_vm._v("Mes actions en cours")])]),_c('div',{staticClass:"topbar-item"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-bottom":13},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"indigo","width":"40","height":"40","min-width":"40"}},'v-avatar',attrs,false),on),[(
                    _vm.profile.avatar &&
                    _vm.profile.avatar.thumb &&
                    _vm.profile.avatar.thumb.url
                  )?_c('img',{attrs:{"src":_vm.profile.avatar.thumb.url,"alt":_vm.profile.name}}):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm._f("initials")(_vm.profile.name)))])])]}}])},[_c('v-list',{staticClass:"profile-icon-menu",attrs:{"nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('OcularIcon',{attrs:{"name":"mdi-shield-bug-outline","color":"primary"}})],1),_c('v-list-item-title',[_c('a',{on:{"click":_vm.reportBug}},[_vm._v("Besoin d'aide ?")])])],1),_c('v-list-item',{attrs:{"color":"error"}},[_c('v-list-item-icon',[_c('OcularIcon',{attrs:{"name":"mdi-logout","color":"primary"}})],1),_c('v-list-item-title',[_c('a',{on:{"click":_vm.logout}},[_vm._v("Déconnexion")])])],1)],1)],1)],1),_c('CircularMenu',{attrs:{"notification-count":_vm.notificationCount},on:{"setSidebar":_vm.setExpand}})],1)],1)],1),_c('Panel',{attrs:{"title":_vm.panel.title,"content":_vm.panel.content,"expanded":_vm.expanded},on:{"clearPanel":function($event){return _vm.clearPanel()},"clickOutside":function($event){return _vm.clickOutside()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }





































































































































import { Vue, Component } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import OcularRating from "~/components/OcularRating/OcularRating.vue"
import Visit from "~/models/Visit"

@Component({
  components: { OcularRating, OcularDialog },
})
export default class VisitSurveyModal extends Vue {
  visit: Visit | null = null
  currentStep = 1
  nbStep = 2
  displayCommentInput = false
  displayAgentReportInput = false

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }
  get textOk() {
    return this.currentStep >= this.nbStep
      ? "Oui, compléter la vérification"
      : "Suivant"
  }

  get textCancel() {
    return this.currentStep >= this.nbStep ? "Non, j'ai terminé" : "Annuler"
  }

  toggleCommentInputVisibility() {
    this.displayCommentInput = !this.displayCommentInput
    if (this.visit && !this.displayCommentInput) this.visit.report = null
  }

  toggleAgentReportVisibility() {
    this.displayAgentReportInput = !this.displayAgentReportInput
    if (this.visit && !this.displayAgentReportInput)
      this.visit.agent_opinion = null
  }

  close() {
    // setTimeout to prevent "reset-blink" on modal close
    if (this.currentStep == 2) {
      this.goToUrl("visits")
    }
    setTimeout(() => {
      this.currentStep = 1
      this.visit = null
    }, 500)
  }

  cancel() {
    this.dialog.open = false
    if (this.currentStep == 2) {
      this.goToUrl("visits")
    }
  }

  open(visit: Visit) {
    this.visit = visit
    this.currentStep = 1
    this.dialog.open = true
  }

  nextStep() {
    if (this.currentStep == this.nbStep - 1) this.sendSurvey()
    if (this.currentStep >= this.nbStep) {
      this.dialog.close()
      this.goToUrl("verification")
    }
    this.currentStep += 1
  }

  goToUrl(path: string) {
    if (
      this.$route.name &&
      this.visit &&
      this.visit.house &&
      this.visit.house.id
    ) {
      const urlComponents = this.$route.name.split("/")
      this.$router.push({
        name: `${urlComponents[0]}/house/${path}`,
        params: {
          houseId: this.visit.house.id.toString(),
        },
      })
    }
  }

  sendSurvey() {
    this.$emit("confirm", this.visit, "done")
  }

  notEmpty = (value: string | number | null | undefined) => {
    return typeof value == "number" ? value > 0 : !!(value && value.length)
  }
}








































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import OcularPrompt from "~/components/OcularPrompt.vue"
import Visit, { VisitCancelReason } from "~/models/Visit"
import { getEnumValues } from "@/store"

@Component({
  components: {
    OcularPrompt,
  },
})
export default class PromptCancelVisit extends Vue {
  @Prop({ required: false, default: null }) visit!: Visit
  newVisit: Visit | null = null

  mounted() {
    this.initNewVisit()
    this.onCancelReasonChange()
  }

  @Watch("visit")
  initNewVisit() {
    this.newVisit = this.visit ? new Visit(this.visit) : new Visit()
  }

  get cancelReasonItems() {
    return Object.entries(getEnumValues("cancel_reason")).map(
      ([value, text]) => ({
        value,
        text,
      })
    )
  }

  get inputCancelReason() {
    return this.newVisit?.cancel_reason
  }

  get dialog() {
    return this.$refs.dialog as OcularPrompt
  }

  get suggestNotification() {
    const suggestIf = [
      "client_impeachment",
      "personal_impeachment",
    ] as VisitCancelReason[]
    return (
      this.newVisit?.cancel_reason &&
      suggestIf.find((reason) => this.newVisit?.cancel_reason === reason)
    )
  }

  @Watch("inputCancelReason")
  onCancelReasonChange() {
    if (this.newVisit) {
      this.newVisit.cancel_notification = !!this.suggestNotification
    }
  }

  open(visit: Visit | undefined = undefined) {
    this.dialog.open = true
    if (visit) {
      this.newVisit = new Visit(visit)
    }
  }

  confirm() {
    if (this.newVisit) {
      this.newVisit.status = "canceled"
      this.$emit("cancel", this.newVisit)
    }
  }
}














































































import { Vue, Component } from "vue-property-decorator"
import { fromQueryUrl, toQueryUrl } from "~/utils/queryUrl"
import Search from "~/collections/Search"
import HouseSearch from "~/models/HouseSearch"
import HouseCardContainer from "@/components/house/HouseCardContainer.vue"
import OcularIcon from "~/components/OcularIcon.vue"
import SearchActiveTags from "@/components/search/SearchActiveTags.vue"
import House from "~/models/House"

type SortItem = { value: string; defaultDirection: string; text: string }

@Component({
  components: {
    SearchActiveTags,
    HouseCardContainer,
    OcularIcon,
    NewAlert: () => import("@/components/modals/NewAlert.vue"),
  },
})
export default class SearchResult extends Vue {
  loading = true
  houses = new Search()
  houseSearch = new HouseSearch().assign(fromQueryUrl(this.$route.query))
  currentPage = 1
  sortItems: SortItem[] = [
    { value: "_score", defaultDirection: "desc", text: "Score" },
    { value: "price", defaultDirection: "asc", text: "Prix" },
    { value: "pricem2", defaultDirection: "asc", text: "Prix au m²" },
    { value: "created_at", defaultDirection: "desc", text: "Date de création" },
    { value: "living_area", defaultDirection: "desc", text: "Surface" },
    { value: "estate_area", defaultDirection: "desc", text: "Surface terrain" },
    { value: "city_name", defaultDirection: "asc", text: "Ville" },
  ]
  currentSorting = this.sortItems[3]
  menuProps = {
    maxHeight: 540,
    bottom: true,
    offsetY: true,
  }

  mounted() {
    if (this.houseSearch.order_column) {
      const sortBy = this.sortItems.find(
        (item) => item.value == this.houseSearch.order_column
      ) as SortItem
      this.currentSorting = this.sortItems[this.sortItems.indexOf(sortBy)]
    }
    if (!this.houseSearch.order_direction)
      this.houseSearch.order_direction = this.currentSorting.defaultDirection
    this.search()
  }

  get sortDirection() {
    return this.houseSearch.order_direction === "desc"
      ? "descending"
      : "ascending"
  }

  changeSearchOrder() {
    const sortItem = this.currentSorting
    this.houseSearch.order_column = sortItem.value
    this.houseSearch.order_direction = sortItem.defaultDirection
    this.launchSearch()
  }
  changeSearchDirection() {
    if (!this.houseSearch.order_column)
      this.houseSearch.order_column = "created_at"
    this.houseSearch.order_direction =
      this.houseSearch.order_direction === "desc" ? "asc" : "desc"
    this.launchSearch()
  }
  launchSearch() {
    const filteredEntries = Object.entries(this.houseSearch).filter(
      ([key, value]) => !key.startsWith("_") && value !== null
    )
    const filteredHouseSearch = Object.fromEntries(filteredEntries)
    this.$router.push(`result?${toQueryUrl(filteredHouseSearch)}`)
    this.search()
  }
  onPageChange(page: number) {
    this.houseSearch.offset = (page - 1) * this.houseSearch.limit
    this.currentPage = page
    this.launchSearch()
  }
  updateSearch() {
    const filteredEntries = Object.entries(this.houseSearch).filter(
      ([key, value]) => !key.startsWith("_") && value !== null
    )
    const filteredHouseSearch = Object.fromEntries(filteredEntries)
    this.$router.push(`../search?${toQueryUrl(filteredHouseSearch)}`)
  }
  houseRemoved(house: House) {
    this.houses.items = this.houses.items.filter((item) => item.id !== house.id)
  }

  async search() {
    this.loading = true
    await this.houses.fetch({ data: this.houseSearch })
    this.loading = false
  }
}

import Model from "~/jrdm-mc/Model"
import House from "~/models/House"
import Profile from "~/models/Profile"
import Partner from "~/models/Partner"
import { AxiosRequestConfig } from "axios"
import Agency from "~/models/Agency"
import Agent from "~/models/Agent"
import { arrayOf, model } from "~/utils/functionsFilter"
import { translate } from "../../ocular/src/store"

type VisitType = "initial" | "bis" | "work" | "inventory"
export type VisitStatus = "planned" | "done" | "canceled"
export type VisitCancelReason =
  | "agent_impeachment"
  | "client_impeachment"
  | "personal_impeachment"
  | "sold"

export type OtherVisit = {
  start_at: string
  end_at: string
  owner_name: string
  owner_photo: string | null
}

export default class Visit extends Model {
  static fetchRoute = "deals/{deal_id}/visits"
  static route = "deals/{deal_id}/houses/{house_id}/visits/{id}"
  static responseField = "visit"
  static requestField = "visit"

  id: number | null = null
  title: string | null = null
  owner: Profile = new Profile()
  house: House = new House()
  start_at: string | null = null
  end_at: string | null = null
  location: string | null = null
  agency_id: number | null = null
  agency: Agency | null = null
  agent_id: number | null = null
  agent: Agent | null = null
  share_agent = false
  visit_type: VisitType | null = null
  note: string | null = null
  report: string | null = null
  client_report: string | null = null
  status: VisitStatus = "planned"
  home_catcher_guests: Profile[] = []
  home_catchers: number[] | null = []
  partners: Partner[] = []
  partner_ids: number[] = []
  guests: string[] = []
  rating: number | null = null
  agent_opinion: string | null = null
  cancel_reason: VisitCancelReason | null = null
  cancel_notification: boolean | null = null
  deal_id = 0
  google_event_id: number | null = null
  other_visits: OtherVisit[] | null = null

  saveAttributes() {
    return [
      "title",
      "agency_id",
      "agent_id",
      "status",
      "rating",
      "start_at",
      "end_at",
      "visit_type",
      "note",
      "location",
      "share_agent",
      "cancel_reason",
      "cancel_notification",
      "report",
      "client_report",
      "home_catchers",
      "partner_ids",
      "agent_opinion",
      "guests",
    ]
  }

  static mutations = {
    agency: model(Agency),
    agent: model(Agent),
    partners: arrayOf(Partner),
  }

  constructor(object?: Record<string, any>) {
    super()
    object && this.assign(object)
  }

  async save(options: AxiosRequestConfig = {}) {
    return await super.save({
      ...options,
      url: this.hasId()
        ? `deals/${this.deal_id}/houses/${this.house.id}/visits/${this.id}`
        : `deals/${this.deal_id}/houses/${this.house.id}/visits`,
    })
  }

  cancelReasonText() {
    if (this.cancel_reason)
      return translate("cancel_reason", this.cancel_reason)
    return null
  }
}



































import { Component, Vue, Prop } from "vue-property-decorator"
import Agent from "~/models/Agent"
import Agency from "~/models/Agency"
// import OcularAvatar from "~/components/OcularAvatar.vue"

@Component({
  components: {
    // OcularAvatar
  },
})
export default class AgentItem extends Vue {
  @Prop({ required: true }) agency!: Agency
  @Prop({ required: false }) agent!: Agent
  @Prop({ required: false, default: false }) small!: boolean

  get isIndividual() {
    return this.agency?.name && this.agency.name.match(/Particulier/g)
  }
}

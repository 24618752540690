






















import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import HouseCardDealStatusEmoji from "./HouseCardDealStatusEmoji.vue"
import HouseCardEditableTitle from "./HouseCardEditableTitle.vue"
import HouseCardImageCarousel from "./HouseCardImageCarousel.vue"
import HouseCardLocation from "./HouseCardLocation.vue"
import HouseCharacteristics from "./HouseCharacteristics.vue"
import HousePrices from "./HousePrices.vue"

@Component({
  components: {
    HouseCardImageCarousel,
    HouseCardEditableTitle,
    HouseCardLocation,
    HousePrices,
    HouseCharacteristics,
    HouseCardDealStatusEmoji,
  },
})
export default class HouseCardInfos extends Vue {
  @Prop({ required: true }) house!: House

  openHouseFullDescription() {
    this.openHouseFull("description")
  }
  openHouseFull(tab = "description") {
    this.$emit("open-house-full", tab)
  }
}

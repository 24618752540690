import Model from "~/jrdm-mc/Model"
import Profile from "~/models/Profile"
import { toDate, model } from "~/utils/functionsFilter"

export default class Message extends Model {
  static requestField = "message"
  static route = "deals/{deal_id}/houses/{house_id}/messages"
  static deleteRoute = "deals/{deal_id}/houses/{house_id}/messages/{id}"

  getSaveRoute() {
    let route = this.house_id ? Message.route : "deals/{deal_id}/messages"
    route = this.hasId() ? "messages/{id}" : route
    return route
  }

  id: number | null = null
  deal_id: number | null = null
  house_id: number | null = null
  body: string | null = null
  body_html: string | null = null
  conversation_id: number | null = null
  created_at: Date | null = null
  creator: Profile | null = null
  creator_id: number | null = null
  seen_by_sentence: string | null = null
  updated_at: Date | null = null

  static mutations = {
    created_at: toDate,
    updated_at: toDate,
    creator: model(Profile),
  }

  constructor(object?: Record<string, any>) {
    super()
    object && this.assign(object)
  }
}

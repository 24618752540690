import Model from "../jrdm-mc/Model"
import House from "./House"
import { toDate } from "~/utils/functionsFilter"

export default class Notification extends Model {
  static route = "notifications/{id}"
  static responseField = "notification"

  id: null | number = null
  deal_id: null | number = null
  house_id: null | number = null
  recipient_id: null | number = null
  transmisor_id: null | number = null
  notifiable_id: null | number = null

  seen = false
  alert_enabled = true
  push_enabled = true

  activity = ""
  activity_value = ""
  notifiable_type: null | string = null
  notifiable: null | string = null
  search_title: null | string = null
  deal_title = ""
  transmisor_name: null | string = null
  recipient: null | string = null
  created_at = new Date()
  updated_at = new Date()
  message: null | string = null

  house: null | House = new House()

  mutations() {
    return {
      id: parseInt,
      deal_id: parseInt,
      house_id: parseInt,
      recipient_id: parseInt,
      transmisor_id: parseInt,
      notifiable_id: parseInt,
      seen: Boolean,
      alert_enabled: Boolean,
      push_enabled: Boolean,
      created_at: toDate,
      updated_at: toDate,
      house: (value: House) => new House().assign(value),
      notifiable_type: (value: string) => value.trim(),
      activity: (value: string) => value.trim(),
      activity_value: (value: string) => value.trim(),
      transmisor_name: (value: string) => value.trim(),
      deal_title: (value: string) => value.trim(),
      search_title: (value: string) => value.trim(),
      message: (value: string) => value.trim(),
    }
  }
}

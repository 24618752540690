import Collection from "../jrdm-mc/Collection"
import Profile from "~/models/Profile"
import axios, { AxiosRequestConfig } from "axios"
import snackbar from "~/plugins/snackbar"

export default class Salespeople extends Collection<Profile> {
  static route = "salespeople"
  static responseField = "users"

  model() {
    return Profile
  }

  async fetchGaSalespeople() {
    this._loaded = false
    const options: AxiosRequestConfig = {}
    options.method ||= this.getFetchMethod()
    options.url ||= this.renderRoute(this.getFetchRoute())
    options.params = {
      only_get_accept: true,
    }
    try {
      const response = await axios(options)
      this.assignReceivedData(response.data)
      this._loaded = true
      this.dispatchEvent("loaded")
      return response.data
    } catch (error) {
      snackbar({
        content:
          "Une erreur pendant la récupération des CCMs avec un compte GetAccept",
      })
    }
  }
}

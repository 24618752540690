






import { Component } from "vue-property-decorator"
import Topbar from "@/layouts/default/Topbar.vue"
import Vue from "vue"

@Component({
  components: {
    Topbar,
  },
})
export default class NoWrapLayout extends Vue {}

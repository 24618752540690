














import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HouseCardPersonnalNote extends Vue {
  @Prop({ required: true }) house!: House

  async updatePersonalNote() {
    await this.house.save()
  }
}

import Model from "../jrdm-mc/Model"

type AnyMap = { [key: string]: { [key: string]: string } }

export default class Translation extends Model {
  static route = "enums"

  enums: AnyMap = {}

  translate(key: string, value: string) {
    if (!this.loaded) return ""
    if (!this.enums[key]) {
      // eslint-disable-next-line
      console.warn(`'${key}' is missing in translation`)
      return value
    } else if (!this.enums[key][value]) {
      // eslint-disable-next-line
      console.warn(`'${value}' is missing in translation for '${key}'`)
      return value
    } else return this.enums[key][value]
  }

  getEnumValues(key: string) {
    if (!this.loaded) return ""
    if (!this.enums[key]) {
      // eslint-disable-next-line
      console.warn(`'${key}' is missing in translation`)
      return key
    } else {
      return this.enums[key]
    }
  }
}








































































import { state } from "@/store"
import { Component } from "vue-property-decorator"
import Vue from "vue"
import groupBy from "lodash/groupBy"
import Notification from "~/models/Notification"
import { DealStatus } from "~/models/House"
import router from "@/router"

@Component({})
export default class PanelCustomers extends Vue {
  get models() {
    return state.notifications.filterByActivity("house_state_changed")
  }
  get notificationsSortByDate() {
    return this.models.sort((a, b) => +b.created_at - +a.created_at)
  }
  get emptyNotifications() {
    return this.models.length === 0
  }
  get notificationsByDeal() {
    return groupBy(this.notificationsSortByDate, "deal_id") as {
      [key: number]: Notification[]
    }
  }

  houseStateChangedEmoji(value: DealStatus) {
    const emojiByStatus: Record<DealStatus, string> = {
      waiting: "",
      processing: "⏳",
      heartstroked: "💖",
      selected: "🤔",
      excluded: "👎",
      aside: "",
      visit_canceled: "",
      visit_done: "",
      visit_planned: "",
      offer_sent: "",
      offer_accepted: "",
      offer_refused: "",
    }
    return value ? emojiByStatus[value] : ""
  }
  resultsCountFor(notificationsByDeal: Notification[]) {
    return notificationsByDeal.length
  }
  async goToHouse(notification: Notification) {
    const dealId = notification.deal_id
    const houseId = notification.house_id
    await this.$emit("deleteNotification", notification)
    this.$emit("close")
    if (
      (router.currentRoute.params.houseId &&
        parseInt(router.currentRoute.params.houseId) != houseId) ||
      !router.currentRoute.params.houseId
    )
      router.push({
        path: `/deals/${dealId}/sent/house/${houseId}/description`,
      })
  }
}












import { Vue, Component, Prop, Model } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HouseCardExpandableDescription extends Vue {
  @Prop({ required: true }) house!: House
  @Model("change", { type: Boolean }) readonly descriptionOpened!: boolean

  toggleExpanded() {
    this.$emit("change", !this.descriptionOpened)
  }
}

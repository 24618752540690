























import { Component, Vue } from "vue-property-decorator"
import EventBus from "~/utils/EventBus"
import { SnackbarOptions, DefaultSnackbarOptions } from "~/plugins/snackbar"

@Component({})
export default class OcularSnackbar extends Vue {
  show = false
  options = new DefaultSnackbarOptions()
  hideTimeout = 0

  created() {
    EventBus.on("showSnackbar", this.showSnack)
  }
  destroyed() {
    EventBus.off("showSnackbar", this.showSnack)
  }

  showSnack(options: SnackbarOptions) {
    this.options = Object.assign(new DefaultSnackbarOptions(), options)
    this.show = true
    if (this.hideTimeout) clearTimeout(this.hideTimeout)
    if (this.options.timeout != -1) {
      this.hideTimeout = setTimeout(
        () => (this.show = false),
        this.options.timeout
      )
    }
  }
}
